import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { handleError } from 'reducers/ErrorReducer';
import DatePicker2 from 'components/DatePicker2';
import TimePicker2 from 'components/TimePicker2';
import Tooltip from 'components/Tooltip';
import TextField2 from 'components/TextField2';
import Checkbox2 from 'components/Checkbox2';
import formStyles, { gridStyle, Row, ColCard, Fills, RowInner } from 'utils/formStyles';
import { notify } from 'reducers/NotifierReducer';
import { getService } from 'reducers/service';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { displayDateTime } from 'reducers/TimeReducer';
import {
  getPrePlanDetails, getPrePlanInspections, getAddressDetails, savePrePlanInspection,
  delPrePlanInspection
} from 'reducers/AddressReducer';
import { getAddressFromLocation } from 'utils/mapFunctions';
import { XGrid } from '@material-ui/x-grid';
import PrintsSearch from 'Search/components/PrintsSearch';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Dialog from 'components/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { getServerDateTime } from 'reducers/TimeReducer';
import PersonLookup from 'components/PersonLookup';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dictionary from 'components/Dictionary';


const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  field: gridStyle(210, 400),
  longField: gridStyle(220, '100%'),
  btn: {
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  filters: {
    marginBottom: 30,
    marginLeft: theme.spacing(0.5),
    '& > div': {
      marginRight: theme.spacing(2),
    },
    '& > button': {
      marginTop: 7,
    }
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 350,
    marginTop: 32,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  gridActions: {
    '& button': {
      marginRight: theme.spacing(1),
    },
  },
}));

const columns = [
  {
    field: 'InspectionDate',
    headerName: 'Inspection Date',
    width: 180,
    valueFormatter: (params) => displayDateTime(params.value),
    format: "date"
  },
  { field: 'PersonID', headerName: 'Person ID', width: 160 },
  { field: 'FullName', headerName: 'Full Name', width: 160 },
  { field: 'ShiftDesignation', headerName: 'Shift Designation', width: 160 },
  {
    field: 'Created',
    headerName: 'Created',
    width: 180,
    valueFormatter: (params) => displayDateTime(params.value),
    format: "date"
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 160 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 180,
    valueFormatter: (params) => displayDateTime(params.value),
    format: "date"
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 160 },
];

const AddressPrePlan = (props) => {
  const classes = useStyles();
  const { dictionary, ptsAddressID } = props;
  const { PrePlanConstructionTypes, PrePlanOccupancyTypes, PrePlanRoofTypes } = dictionary;

  const [origDate, setOrigDate] = useState(null);
  const [buildingCapacity, setBuildingCapacity] = useState(0);
  const [numberOfFloors, setNumberOfFloors] = useState(0);
  const [numberOfExits, setNumberOfExits] = useState(0);
  const [firewalls, setFirewalls] = useState(false);
  const [gasService, setGasService] = useState(false);
  const [gasCutoffLocation, setGasCutoffLocation] = useState('');
  const [electricalService, setElectricalService] = useState(false);
  const [electricalCutoffService, setElectricalCutoffService] = useState('');
  const [requiredFlow, setRequiredFlow] = useState(0);
  const [availableFlow, setAvailableFlow] = useState(0);
  const [sprinkler, setSprinkler] = useState(false);
  const [sprinklerShutoffLocation, setSprinklerShutoffLocation] = useState('');
  const [sprinklerFdConnectionLocation, setSprinklerFdConnectionLocation] = useState('');
  const [standpipe, setStandpipe] = useState(false);
  const [standpipeShutoffLocation, setStandpipeShutoffLocation] = useState('');
  const [standpipeFdConnectionLocation, setStandpipeFdConnectionLocation] = useState('');
  const [occupancyType, setOccupancyType] = useState(null);
  const [roofType, setRoofType] = useState(null);
  const [constructionType, setConstructionType] = useState(null);
  const [data, setData] = useState(null);
  const [fullAddress, setFullAddress] = useState('');
  const [AddressID, setAddressID] = useState('');
  const [Inspections, setInspections] = useState([]);
  const [inspectionsLoaded, setInspectionsLoaded] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [selection, setSelection] = useState(null);
  const [editInspectionData, setEditInspectionData] = useState(null);
  const mountedRef = useRef(true);

  useEffect(() => {
    mountedRef.current = true;
    getPrePlanData();
    getAddress();
    getPrePlanInspectionsData();
    return () => {
      mountedRef.current = false;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!data) return;
    setOrigDate(data.OrigPrePlanDate || null);
    setBuildingCapacity(data.BuildingCapacity || 0);
    setNumberOfFloors(data.NumberFloors || 0);
    setNumberOfExits(data.NumberOfExits || 0);
    setFirewalls(data.Firewalls || false);
    setGasService(data.GasService || false);
    setGasCutoffLocation(data.GasCutoffLocation || '');
    setElectricalService(data.ElectricalService || false);
    setElectricalCutoffService(data.ElectricalCutoffService || '');
    setRequiredFlow(data.RequiredFlow || 0);
    setAvailableFlow(data.AvailableFlow || 0);
    setSprinkler(data.Sprinkler || false);
    setSprinklerShutoffLocation(data.SprinklerShutoffLocation || '');
    setSprinklerFdConnectionLocation(data.SprinklerFDConnectionLocation || '');
    setStandpipe(data.Standpipe || false);
    setStandpipeShutoffLocation(data.StandpipeShutoffLocation || '');
    setStandpipeFdConnectionLocation(data.StandpipeFDConnectionLocation || '');
    setOccupancyType(PrePlanOccupancyTypes.find(p => p.Code === data.OccupancyType) || null);
    setRoofType(PrePlanRoofTypes.find(p => p.Code === data.RoofType) || null);
    setConstructionType(PrePlanConstructionTypes.find(p => p.Code === data.ConstructionType) || null);
  }, [data]);

  const getAddress = async () => {
    try {
      const addressData = await getAddressDetails(ptsAddressID);
      if (!mountedRef.current || !addressData.data || !addressData.data.length) return;
      const data = addressData.data[0];
      setFullAddress(getAddressFromLocation(data, dictionary));
      setAddressID(data.AddressID);
    } catch (err) {
      props.handleError(err, "Error receiving event location data.");
    }
  }

  const getPrePlanData = async () => {
    try {
      const data = await getPrePlanDetails(ptsAddressID);
      if (!mountedRef.current || !data) return;
      setData(data);
    }
    catch (err) {
      props.handleError(err, "Error receiving address pre plan data.");
    }
  }

  const getPrePlanInspectionsData = async () => {
    try {
      const data = await getPrePlanInspections(ptsAddressID);
      if (!mountedRef.current || !data) return;
      setInspections(data);
      setInspectionsLoaded(true);
    }
    catch (err) {
      props.handleError(err, "Error receiving address inspections data.");
    }
  }

  const handleSave = async () => {
    const prePlan = getPrePlanObj();
    const service = getService('cad-address-pre-plan');
    try {
      await service.create(prePlan);
      await getPrePlanData();
      props.notify('Pre plan saved', 'success')
    } catch (err) {
      props.handleError(err, 'Error saving pre plan')
    }

  }

  const getPrePlanObj = () => {
    return {
      OrigPrePlanDate: origDate || null,
      BuildingCapacity: buildingCapacity || null,
      NumberFloors: numberOfFloors || null,
      NumberOfExits: numberOfExits || null,
      Firewalls: firewalls === true ? 1 : 0,
      GasService: gasService === true ? 1 : 0,
      GasCutoffLocation: gasCutoffLocation || null,
      ElectricalService: electricalService === true ? 1 : 0,
      ElectricalCutoffService: electricalCutoffService || null,
      RequiredFlow: requiredFlow || null,
      AvailableFlow: availableFlow || null,
      Sprinkler: sprinkler === true ? 1 : 0,
      SprinklerShutoffLocation: sprinklerShutoffLocation || null,
      SprinklerFDConnectionLocation: sprinklerFdConnectionLocation || null,
      Standpipe: standpipe === true ? 1 : 0,
      StandpipeShutoffLocation: standpipeShutoffLocation || null,
      StandpipeFDConnectionLocation: standpipeFdConnectionLocation || null,
      OccupancyType: occupancyType?.Code || null,
      RoofType: roofType?.Code || null,
      ConstructionType: constructionType?.Code || null,
      ptsAddressID
    }
  }

  const renderOrigPrePlanDate = () => {
    return (
      <DatePicker2
        onChange={(date) => setOrigDate(date)}
        label="Original Pre Plan Date"
        value={origDate}
        className={classes.field}
      />
    )
  }

  const renderOccupancyType = () => {
    const onChange = (ev, val) => setOccupancyType(val);
    return (
      <Dictionary
        options="PrePlanOccupancyTypes"
        className={classes.field}
        onChange={onChange}
        value={occupancyType}
        label="Occupancy Type"
      />
    );
  }

  const renderConstructionType = () => {
    const onChange = (ev, val) => setConstructionType(val);
    return (
      <Dictionary
        options="PrePlanConstructionTypes"
        className={classes.field}
        onChange={onChange}
        value={constructionType}
        label="Construction Type"
      />
    );
  }

  const renderRoofType = () => {
    const onChange = (ev, val) => setRoofType(val);
    return (
      <Dictionary
        options="PrePlanRoofTypes"
        className={classes.field}
        onChange={onChange}
        value={roofType}
        label="Roof Type"
      />
    );
  }

  const renderBuildingCapacity = () => {
    const onChange = (ev, val) => setBuildingCapacity(val);
    return (
      <TextField2
        className={classes.field}
        label="Building Capacity"
        value={buildingCapacity}
        onChange={onChange}
        type="number"
      />
    )
  }

  const renderNumberOfFloors = () => {
    const onChange = (ev, val) => setNumberOfFloors(val);
    return (
      <TextField2
        className={classes.field}
        label="Number Of Floors"
        value={numberOfFloors}
        onChange={onChange}
        type="number"
      />
    )
  }

  const renderNumberOfExits = () => {
    const onChange = (ev, val) => setNumberOfExits(val);
    return (
      <TextField2
        className={classes.field}
        label="Number Of Exits"
        value={numberOfExits}
        onChange={onChange}
        type="number"
      />
    )
  }

  const renderRequiredFlow = () => {
    const onChange = (ev, val) => setRequiredFlow(val);
    return (
      <TextField2
        className={classes.field}
        label="Required Flow"
        value={requiredFlow}
        onChange={onChange}
        type="number"
      />
    )
  }

  const renderAvailableFlow = () => {
    const onChange = (ev, val) => setAvailableFlow(val);
    return (
      <TextField2
        className={classes.field}
        label="Available Flow"
        value={availableFlow}
        onChange={onChange}
        type="number"
      />
    )
  }

  const renderFirewalls = () => {
    const onChange = () => setFirewalls(!firewalls);
    return (
      <Checkbox2
        checked={firewalls}
        onChange={onChange}
        label="Firewalls"
      />
    )
  }

  const renderGasService = () => {
    const onChange = () => setGasService(!gasService);
    return (
      <Checkbox2
        checked={gasService}
        onChange={onChange}
        label="Gas Service"
      />
    )
  }

  const renderGasCutoffLocation = () => {
    const onChange = (ev, val) => setGasCutoffLocation(val);
    return (
      <TextField2
        className={classes.longField}
        label="Gas Cutoff Location"
        value={gasCutoffLocation}
        onChange={onChange}
        max={100}
      />
    )
  }

  const renderElectricalService = () => {
    const onChange = () => setElectricalService(!electricalService);
    return (
      <Checkbox2
        checked={electricalService}
        onChange={onChange}
        label="Electrical Service"
      />
    )
  }

  const renderElectricalCutoffService = () => {
    const onChange = (ev, val) => setElectricalCutoffService(val);
    return (
      <TextField2
        className={classes.longField}
        label="Electrical Cutoff Service"
        value={electricalCutoffService}
        onChange={onChange}
        max={100}
      />
    )
  }

  const renderSprinkler = () => {
    const onChange = () => setSprinkler(!sprinkler);
    return (
      <Checkbox2
        checked={sprinkler}
        onChange={onChange}
        label="Sprinkler"
      />
    )
  }

  const renderSprinklerShutoffLocation = () => {
    const onChange = (ev, val) => setSprinklerShutoffLocation(val);
    return (
      <TextField2
        className={classes.longField}
        label="Sprinkler Shutoff Location"
        value={sprinklerShutoffLocation}
        onChange={onChange}
        max={100}
      />
    )
  }

  const renderSprinklerFdConnectionLocation = () => {
    const onChange = (ev, val) => setSprinklerFdConnectionLocation(val);
    return (
      <TextField2
        className={classes.longField}
        label="Sprinkler Fire Department Connection Location"
        value={sprinklerFdConnectionLocation}
        onChange={onChange}
        max={100}
      />
    )
  }

  const renderStandpipe = () => {
    const onChange = () => setStandpipe(!standpipe);
    return (
      <Checkbox2
        checked={standpipe}
        onChange={onChange}
        label="Standpipe"
      />
    )
  }

  const renderStandpipeShutoffLocation = () => {
    const onChange = (ev, val) => setStandpipeShutoffLocation(val);
    return (
      <TextField2
        className={classes.longField}
        label="Standpipe Shutoff Location"
        value={standpipeShutoffLocation}
        onChange={onChange}
        val={100}
      />
    )
  }

  const renderStandpipeFdConnectionLocation = () => {
    const onChange = (ev, val) => setStandpipeFdConnectionLocation(val);
    return (
      <TextField2
        className={classes.longField}
        label="Standpipe Fire Department Connection Location"
        value={standpipeFdConnectionLocation}
        onChange={onChange}
        val={100}
      />
    )
  }

  const renderFormActions = () => {
    return (
      <div>
        <Button className={classes.btn} color="primary" variant="contained" autoFocus onClick={handleSave}>
          <SaveIcon /> Save
        </Button>
      </div>
    );
  }

  const addInspection = () => {
    setEditInspectionData({
      InspectionDateTime: getServerDateTime(),
      ptsPersonID: null,
      ShiftDesignation: null,
      PersonID: null,
      ptsAdddressPrePlanID: data.ptsAddressPrePlanID,
    });
  }

  const deleteInspection = async () => {
    if (!confirm("Are you sure you want to delete this inspection?")) return;
    try {
      await delPrePlanInspection(selection);
      if (!mountedRef.current) return;
      getPrePlanInspectionsData();
    } catch (err) {
      props.handleError(err, "Error deleting PP inspection.");
    }
  }

  const editInspection = async () => {
    const inspection = Inspections.find(i => i.id === selection);
    if (!inspection) return;
    const { InspectionDate, ptsPersonID, ShiftDesignation, id, ptsAdddressPrePlanID } = inspection;
    setEditInspectionData({
      InspectionDateTime: InspectionDate,
      ptsPersonID,
      ShiftDesignation,
      ptsAdddressPrePlanID,
      ptsAddressPrePlanInspectionID: id,
    });
  }

  const renderInspectionActions = () => {
    if (!data) return <div></div>
    return (
      <div className={classes.gridActions}>
        <Tooltip title="Add inspection">
          <Fab onClick={addInspection} size="small" color="secondary">
            <AddIcon />
          </Fab>
        </Tooltip>
        <PrintsSearch
          title={`${fullAddress} - Inspections`}
          data={Inspections}
          cols={columns}
          hiddenCols={hiddenColumns}
        />
        {!!selection && (
          <>
            <Tooltip title="Edit Inspection">
              <Fab onClick={editInspection} size="small" color="secondary">
                <EditIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Delete Inspection">
              <Fab onClick={deleteInspection} size="small" color="secondary">
                <DeleteIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </div>
    );
  }

  const closeEditInspection = () => {
    if (!mountedRef.current) return;
    setEditInspectionData(null);
  }

  const saveInspection = async () => {
    try {
      await savePrePlanInspection(editInspectionData);
      if (!mountedRef.current) return;
      await getPrePlanInspectionsData();
      closeEditInspection();
    } catch (err) {
      props.handleError(err, "Error saving PP inspection.");
    }
  }

  const renderEditInspectionDialog = () => {
    const { InspectionDateTime, ptsPersonID, ShiftDesignation } = editInspectionData;
    const setDate = (date) => setEditInspectionData({ ...editInspectionData, InspectionDateTime: date });
    const setPerson = (val) => setEditInspectionData({ ...editInspectionData, ptsPersonID: val.ptsPersonID, PersonID: val.PersonID });
    const onDesignationChange = (ev, val) => {
      setEditInspectionData({ ...editInspectionData, ShiftDesignation: val.Code });
    }
    const { Designation } = dictionary;
    const DesignationType = ShiftDesignation ? Designation.find(d => d.Code === ShiftDesignation) : null;
    const valid = ShiftDesignation && ptsPersonID;
    const renderEditInspectionsActions = () => {
      return (
        <>
          <Button color="primary" variant="contained" autoFocus onClick={saveInspection} disabled={!valid}>
            <SaveIcon /> Save
          </Button>
          <Button color="primary" onClick={closeEditInspection}>
            <CloseIcon /> Close
          </Button>
        </>
      );
    }

    return (
      <Dialog
        onClose={closeEditInspection}
        title={'Add Inspection'}
        actions={renderEditInspectionsActions()}
        className={classes.dialog}
        draggable={false}
      >
        <Row>
          <ColCard minWidth={500}>
            <RowInner>
              <DatePicker2
                onChange={(date) => setDate(date)}
                label="Inspection Date"
                value={InspectionDateTime}
                className={classes.field}
              />
              <TimePicker2
                onChange={(date) => setDate(date)}
                label="Inspection Time"
                value={InspectionDateTime}
                className={classes.field}
              />
            </RowInner>
            <RowInner>
              <PersonLookup
                className={classes.w100pr}
                onPersonChange={val => setPerson(val)}
                ptsPersonID={editInspectionData.ptsPersonID}
              />
            </RowInner>
            <RowInner>
              <Dictionary
                options="Designation"
                className={classes.w100pr}
                onChange={onDesignationChange}
                value={DesignationType}
                label="Shift Designation"
              />
            </RowInner>
          </ColCard>
        </Row>
      </Dialog >
    );
  }

  return (
    <div className={classes.wrap}>
      <div className={classes.rowSpaces}>
        <h4 className={classes.w50pr}>{fullAddress}</h4>
        <p>{AddressID}</p>
      </div>
      <Row>
        <ColCard minWidth={500}>
          <RowInner>
            {renderOrigPrePlanDate()}
            {renderOccupancyType()}
            {renderConstructionType()}
            {renderRoofType()}
            {renderBuildingCapacity()}
            {renderNumberOfFloors()}
            {renderNumberOfExits()}
            {renderRequiredFlow()}
            {renderAvailableFlow()}
            <Fills className={classes.field} />
          </RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <RowInner>
            {renderFirewalls()}
            {renderGasService()}
            {renderGasCutoffLocation()}
          </RowInner>
          <RowInner>
            {renderElectricalService()}
          </RowInner>
          <RowInner>
            {renderElectricalCutoffService()}
          </RowInner>
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          <RowInner>
            {renderSprinkler()}
          </RowInner>
          <RowInner>
            {renderSprinklerShutoffLocation()}
          </RowInner>
          <RowInner>
            {renderSprinklerFdConnectionLocation()}
          </RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <RowInner>
            {renderStandpipe()}
          </RowInner>
          <RowInner>
            {renderStandpipeShutoffLocation()}
          </RowInner>
          <RowInner>
            {renderStandpipeFdConnectionLocation()}
          </RowInner>
        </ColCard>
      </Row>
      <div className={classes.actions}>
        {renderInspectionActions()}
        {renderFormActions()}
      </div>
      {Boolean(data) && (
        < div className={classes.gridWrap}>
          <XGrid
            columns={columns}
            rows={Inspections}
            loading={!inspectionsLoaded}
            rowHeight={38}
            disableMultipleSelection={true}
            showToolbar
            disableColumnFilter
            onSelectionModelChange={(newSelection) => {
              setSelection(newSelection.selectionModel[0]);
            }}
            onColumnVisibilityChange={(col) => setHiddenColumns([...hiddenColumns, col.field])}
          />
        </div>
      )}
      {editInspectionData !== null && renderEditInspectionDialog()}
    </div >
  )
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(mapStateToProps, {
  handleError, notify
})(AddressPrePlan);