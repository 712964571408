import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField2 from 'components/TextField2';
import formStyles, { formatSaveData, gridStyle, Row, RowInner, ColCard, Fills } from 'utils/formStyles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { dateTimePicker, formatSaveDate, getDateTimeFormat, formatSaveDateFrom, formatSaveDateTo, displayDateTime } from 'reducers/TimeReducer';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { findEvents, getEventsSelectItems, getEventDetails } from 'reducers/VfcReducer';
import { handleError } from 'reducers/ErrorReducer';
import { XGrid } from '@material-ui/x-grid';//'components/XGrid3';//
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Report from './EventReport';
import PageviewIcon from '@material-ui/icons/Pageview';
import PrintSearch from '../../components/PrintsSearch';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import Autocomplete2 from "../../components/Autocomplete2";
import AlertDialog from "../../Dialogs/AlertDialog";

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  addressCategory: gridStyle(220, '100%'),
  item: gridStyle(170, '100%'),
  timePicker: gridStyle(200, '100%'),
  btn: {
    marginLeft: 8,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  actions: {
    marginTop: 8,
    textAlign: 'right',
    marginBottom: 16,
  },
  content: {
    padding: theme.spacing(2),
  },
  xgrid: {
    width: '100%',
    height: 500
  },
  label: {
    //variant: "h3",
    fontSize: 14
  },
  autocomplete: {
    margin: '0 0px 0px',
  },
}));

const defaultColumns = [
  { field: 'Number', headerName: 'PSR #', width: 120 },
  {
    field: 'Received',
    headerName: 'Received Date/Time',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  {
    field: 'Dispatched',
    headerName: 'Dispatched',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  {
    field: 'Enroute',
    headerName: 'Enroute',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  {
    field: 'Arrived',
    headerName: 'Arrived',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  {
    field: 'Departed',
    headerName: 'Departed',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  {
    field: 'Completed',
    headerName: 'Completed',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'Type', headerName: 'Event Type', width: 150 },
  { field: 'Description', headerName: 'Event Description', width: 150 },
  { field: 'Zone', headerName: 'Zone', width: 120 },
  { field: 'UnitVehicle', headerName: 'Unit Vehicle', width: 120 },
  { field: 'Officer', headerName: 'Officer Name', width: 150 },
  { field: 'Status', headerName: 'Status', width: 150 },
  { field: 'Location', headerName: 'Location', width: 150 },
  { field: 'CallerName', headerName: 'Caller Name', width: 150 },
  { field: 'CallerAddress', headerName: 'Caller Address', width: 150 },
  { field: 'CallerCity', headerName: 'Caller City', width: 150 },
  { field: 'CallerZip', headerName: 'Caller Zip', width: 150 },
  { field: 'CallerPhone', headerName: 'Caller Phone', width: 150 },


  { field: 'SceneAddress', headerName: 'Scene Address', width: 150 },
  { field: 'SceneCity', headerName: 'Scene City', width: 150 },
  { field: 'SceneZip', headerName: 'Scene Zip', width: 150 },
  
];

function Events(props) {
  const classes = useStyles();
  //console.log("props:", props);
  const eventsSelectItems = props.eventsSelectItems;
  const eventDetails = props.eventDetails;
  const webSocket = props.webSocket;

  const [ReceivedFrom, setReceivedFrom] = useState(null);
  const [ReceivedTo, setReceivedTo] = useState(null);
  const [DispatchedFrom, setDispatchedFrom] = useState(null);
  const [DispatchedTo, setDispatchedTo] = useState(null);
  const [EnrouteFrom, setEnrouteFrom] = useState(null);
  const [EnrouteTo, setEnrouteTo] = useState(null);
  const [ArrivedFrom, setArrivedFrom] = useState(null);
  const [ArrivedTo, setArrivedTo] = useState(null);
  const [DepartedFrom, setDepartedFrom] = useState(null);
  const [DepartedTo, setDepartedTo] = useState(null);
  const [CompletedFrom, setCompletedFrom] = useState(null);
  const [CompletedTo, setCompletedTo] = useState(null);
  const [NumberVal, setNumberVal] = useState(null);
  const [EventType, setEventType] = useState(null);
  const [EventDescription, setEventDescription] = useState(null);
  const [Zone, setZone] = useState(null);
  const [UnitVehicle, setUnitVehicle] = useState(null);
  const [Officer, setOfficer] = useState(null);
  const [CallerName, setCallerName] = useState(null);
  const [CallerAddress, setCallerAddress] = useState(null);
  const [CallerCity, setCallerCity] = useState(null);
  const [CallerZip, setCallerZip] = useState(null);
  const [CallerPhone, setCallerPhone] = useState(null);
  const [CallerInfo, setCallerInfo] = useState(null);
  const [SceneAddress, setSceneAddress] = useState(null);
  const [SceneCity, setSceneCity] = useState(null);
  const [SceneZip, setSceneZip] = useState(null);

  const [selection, setSelection] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState(defaultColumns);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [showDetails, setShowDetails] = useState(false);
  //const [details, setDetails] = useState(null);

  useEffect(() => {
    if (eventDetails && selection) setShowDetails(true);
  }, [eventDetails]);

  const closeDetails = () => {
    setShowDetails(false);
  };

  useEffect(async () => {
    if (webSocket) {
      console.log("webSocket != null");

      props.getEventsSelectItems();
    }
  }, [webSocket]);

  const handleClear = () => {
    setReceivedFrom(null);
    setReceivedTo(null);
    setDispatchedFrom(null);
    setDispatchedTo(null);
    setEnrouteFrom(null);
    setEnrouteTo(null);
    setArrivedFrom(null);
    setArrivedTo(null);
    setDepartedFrom(null);
    setDepartedTo(null);
    setCompletedFrom(null);
    setCompletedTo(null);
    setNumberVal(null);
    setEventType(null);
    setEventDescription(null);
    setZone(null);
    setUnitVehicle(null);
    setOfficer(null);
    setCallerName(null);
    setCallerAddress(null);
    setCallerCity(null);
    setCallerZip(null);
    setCallerPhone(null);
    setCallerInfo(null);
    setSceneAddress(null);
    setSceneCity(null);
    setSceneZip(null);

    setRows([]);
  }

  const handleSearch = async () => {
    const _EventType = EventType ? EventType.Description : null;
    const _EventDescription = EventDescription ? EventDescription.Description : null;
    const _Zone = Zone ? Zone.Description : null;
    const _UnitVehicle = UnitVehicle ? /*UnitVehicle.Description*/ UnitVehicle.code : null;
    const _Officer = Officer ? Officer.Description : null;
    const _CallerCity = CallerCity ? CallerCity.Description : null;
    const _CallerZip = CallerZip ? CallerZip.Description : null;
    const _SceneCity = SceneCity ? SceneCity.Description : null;
    const _SceneZip = SceneZip ? SceneZip.Description : null;

    if (eventsSelectItems == null || Object.keys(eventsSelectItems).length === 0 || eventsSelectItems.Officers == null || eventsSelectItems.Officers.length === 0) {
      if (dialogOpen) {
        handleClose();
        return;
      }
      setLoadingOpen(true);
      return;
    }

    if (ReceivedFrom == null && ReceivedTo == null && DispatchedFrom == null && DispatchedTo == null && EnrouteFrom == null && EnrouteTo == null &&
      ArrivedFrom == null && ArrivedTo == null && DepartedFrom == null && DepartedTo == null && CompletedFrom == null && CompletedTo == null &&
      NumberVal == null && _EventType == null && _EventDescription == null && _Zone == null && _UnitVehicle == null &&
      _Officer == null && CallerName == null && CallerAddress == null && _CallerCity == null && _CallerZip == null && CallerPhone == null &&
      CallerInfo == null && SceneAddress == null && _SceneCity == null && _SceneZip == null) {
      if (loadingOpen) {
        loadingClose();
        return;
      }
      setDialogOpen(true);
      return;
    }

    const data = formatSaveData({
      ReceivedFrom: formatSaveDateFrom(ReceivedFrom),
      ReceivedTo: formatSaveDateTo(ReceivedTo),
      DispatchedFrom: formatSaveDateFrom(DispatchedFrom),
      DispatchedTo: formatSaveDateTo(DispatchedTo),
      EnrouteFrom: formatSaveDateFrom(EnrouteFrom),
      EnrouteTo: formatSaveDateTo(EnrouteTo),
      ArrivedFrom: formatSaveDateFrom(ArrivedFrom),
      ArrivedTo: formatSaveDateTo(ArrivedTo),
      DepartedFrom: formatSaveDateFrom(DepartedFrom),
      DepartedTo: formatSaveDateTo(DepartedTo),
      CompletedFrom: formatSaveDateFrom(CompletedFrom),
      CompletedTo: formatSaveDateTo(CompletedTo),
      NumberVal,
      EventType: _EventType,
      EventDescription: _EventDescription,
      Zone: _Zone,
      UnitVehicle: _UnitVehicle,
      Officer: _Officer,
      CallerName,
      CallerAddress,
      CallerCity: _CallerCity,
      CallerZip: _CallerZip,
      CallerPhone,
      CallerInfo,
      SceneAddress,
      SceneCity: _SceneCity,
      SceneZip: _SceneZip
    });
    //console.log("============Events data=============:", data);

    setLoaded(false);
    findEvents(data)
      .then(setRows)
      .catch(props.handleError)
      .finally(() => setLoaded(true))
  }

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel[0]);
  };

  const handleViewDetails = () => {
    const item = rows.find(r => r.id === selection);
    console.log(item.vfcMainId);
    //setDetails(item);
    props.getEventDetails(item.vfcMainId);
  }

  const handleClose = () => {
    setDialogOpen(false);
  };

  const loadingClose = () => {
    setLoadingOpen(false);    
  };

  const renderReceivedFrom = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Received:</Typography>
          <KeyboardDatePicker
            autoOk            
            value={ReceivedFrom}
            onChange={setReceivedFrom}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid >
    );
  }

  const renderReceivedTo = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <KeyboardDatePicker
            autoOk            
            value={ReceivedTo}
            onChange={setReceivedTo}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderDispatchedFrom = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Dispatched:</Typography>
          <KeyboardDatePicker
            autoOk            
            value={DispatchedFrom}
            onChange={setDispatchedFrom}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderDispatchedTo = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <KeyboardDatePicker
            autoOk            
            value={DispatchedTo}
            onChange={setDispatchedTo}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderEnrouteFrom = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Enroute:</Typography>
          <KeyboardDatePicker
            autoOk            
            value={EnrouteFrom}
            onChange={setEnrouteFrom}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderEnrouteTo = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <KeyboardDatePicker
            autoOk            
            value={EnrouteTo}
            onChange={setEnrouteTo}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderArrivedFrom = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Arrived:</Typography>
          <KeyboardDatePicker
            autoOk            
            value={ArrivedFrom}
            onChange={setArrivedFrom}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderArrivedTo = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <KeyboardDatePicker
            autoOk            
            value={ArrivedTo}
            onChange={setArrivedTo}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderDepartedFrom = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Departed:</Typography>
          <KeyboardDatePicker
            autoOk            
            value={DepartedFrom}
            onChange={setDepartedFrom}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderDepartedTo = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <KeyboardDatePicker
            autoOk            
            value={DepartedTo}
            onChange={setDepartedTo}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderCompletedFrom = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Completed:</Typography>
          <KeyboardDatePicker
            autoOk            
            value={CompletedFrom}
            onChange={setCompletedFrom}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderCompletedTo = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <KeyboardDatePicker
            autoOk            
            value={CompletedTo}
            onChange={setCompletedTo}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderNumberVal = () => {
    const onChange = (ev, val) => setNumberVal(val);
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>PSR Number:</Typography>
          <TextField2            
            value={NumberVal}
            onChange={onChange}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderEventType = () => {
    const onChange = (ev, val) => setEventType(val);
    
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Event Type:</Typography>

          <Autocomplete2
            options={(eventsSelectItems && eventsSelectItems.EventTypes) ? eventsSelectItems.EventTypes : []}    
            onChange={onChange}
            value={EventType}            
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : "")}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />

        </div>
      </Grid>
    )
  };

  const renderEventDescription = () => {
    const onChange = (ev, val) => setEventDescription(val);
    return (
      <Grid item xs={3}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Event Description:</Typography>          

          <Autocomplete2
            options={(eventsSelectItems && eventsSelectItems.EventDescription) ? eventsSelectItems.EventDescription : []}            
            onChange={onChange}
            value={EventDescription}            
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : "")}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderZone = () => {
    const onChange = (ev, val) => setZone(val);
    return (
      <Grid item xs={1}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Zone:</Typography>         

          <Autocomplete2
            options={(eventsSelectItems && eventsSelectItems.Zones) ? eventsSelectItems.Zones : []}            
            onChange={onChange}
            value={Zone}            
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : "")}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderUnitVehicle = () => {
    const onChange = (ev, val) => setUnitVehicle(val);
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Unit Vehicle:</Typography>
          
          <Autocomplete2
            options={(eventsSelectItems && eventsSelectItems.UnitVehicle) ? eventsSelectItems.UnitVehicle : []}            
            onChange={onChange}
            value={UnitVehicle}            
            className={classes.autocomplete}
            getOptionSelected={(option, value) => { console.log(option.code, "===== ", value.code, "=======", value); return option.code === value.code }}
            getOptionLabel={(option) => (option.code ? option.Description : "")}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderOfficer = () => {
    const onChange = (ev, val) => setOfficer(val);
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Officer Name:</Typography>
          
          <Autocomplete2
            options={(eventsSelectItems && eventsSelectItems.Officers) ? eventsSelectItems.Officers : []}            
            onChange={onChange}
            value={Officer}            
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : "")}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderCallerName = () => {
    const onChange = (ev, val) => setCallerName(val);
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Name:</Typography>
          <TextField2
            value={CallerName}
            onChange={onChange}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderCallerAddress = () => {
    const onChange = (ev, val) => setCallerAddress(val);
    return (
      <Grid item xs={3}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Address:</Typography>
          <TextField2            
            value={CallerAddress}
            onChange={onChange}
            compact
          />
        </div>
      </Grid >
    );
  };

  const renderCallerCity = () => {
    const onChange = (ev, val) => setCallerCity(val);
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>City:</Typography>
          
          <Autocomplete2
            options={(eventsSelectItems && eventsSelectItems.CallerCity) ? eventsSelectItems.CallerCity : []}            
            onChange={onChange}
            value={CallerCity}            
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : "")}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderCallerZip = () => {
    const onChange = (ev, val) => setCallerZip(val);
    return (
      <Grid item xs={1}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Zip:</Typography>
          
          <Autocomplete2
            options={(eventsSelectItems && eventsSelectItems.CallerZip) ? eventsSelectItems.CallerZip : []}            
            onChange={onChange}
            value={CallerZip}            
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : "")}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderCallerPhone = () => {
    const onChange = (ev, val) => setCallerPhone(val);
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Phone:</Typography>
          <TextField2          
            value={CallerPhone}
            onChange={onChange}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderCallerInfo = () => {
    const onChange = (ev, val) => setCallerInfo(val);
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Info:</Typography>
          <TextField2            
            value={CallerInfo}
            onChange={onChange}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderSceneAddress = () => {
    const onChange = (ev, val) => setSceneAddress(val);
    return (
      <Grid item xs={3}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <TextField2            
            value={SceneAddress}
            onChange={onChange}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderSceneCity = () => {
    const onChange = (ev, val) => setSceneCity(val);
    return (
      <Grid item xs={2}>        

        <Autocomplete2
          options={(eventsSelectItems && eventsSelectItems.SceneCity) ? eventsSelectItems.SceneCity : []}          
          onChange={onChange}
          value={SceneCity}          
          className={classes.autocomplete}
          getOptionSelected={(option, value) => option.code === value.code}
          getOptionLabel={(option) => (option.code ? option.Description : "")}
          renderOption={(option) => <span>{option.Description}</span>}
          compact
        />
      </Grid>
    );
  };

  const renderSceneZip = () => {
    const onChange = (ev, val) => setSceneZip(val);
    return (
      <Grid item xs={1}>        

        <Autocomplete2
          options={(eventsSelectItems && eventsSelectItems.SceneZip) ? eventsSelectItems.SceneZip : []}          
          onChange={onChange}
          value={SceneZip}          
          className={classes.autocomplete}
          getOptionSelected={(option, value) => option.code === value.code}
          getOptionLabel={(option) => (option.code ? option.Description : "")}
          renderOption={(option) => <span>{option.Description}</span>}
          compact
        />
      </Grid>
    );
  };

  return (
    <div className={classes.content}>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div style={{ display: "flex", flexDirection: 'row', alignItems: "flex-end" }}>            
            <Typography className={classes.label} style={{ width: 100 }}>From Date:</Typography>
            <Grid container spacing={2}>              
              {renderReceivedFrom()}
              {renderDispatchedFrom()}
              {renderEnrouteFrom()}
              {renderArrivedFrom()}
              {renderDepartedFrom()}
              {renderCompletedFrom()}              
            </Grid>            
          </div>
          <div style={{ display: "flex", flexDirection: 'row', alignItems: "flex-end" }}>            
            <Typography className={classes.label} style={{ width: 100 }}>To Date:</Typography>
            <Grid container spacing={2}>
              {renderReceivedTo()}
              {renderDispatchedTo()}
              {renderEnrouteTo()}
              {renderArrivedTo()}
              {renderDepartedTo()}
              {renderCompletedTo()}              
            </Grid>            
          </div>

          <div style={{ display: "flex", flexDirection: 'row', alignItems: "flex-end" }}>            
            <Grid container spacing={2}>
              {renderNumberVal()}
              {renderEventType()}
              {renderEventDescription()}
              {renderZone()}
              {renderUnitVehicle()}
              {renderOfficer()}
            </Grid>            
          </div>
          <div style={{ display: "flex", flexDirection: 'row', alignItems: "flex-end" }}>
            <Typography className={classes.label} style={{ width: 80 }}>Caller:</Typography>
            <Grid container spacing={2}>
              {renderCallerName()}
              {renderCallerAddress()}
              {renderCallerCity()}
              {renderCallerZip()}
              {renderCallerPhone()}
              {renderCallerInfo()}
            </Grid>
          </div>

          <div style={{ display: "flex", flexDirection: 'row', alignItems: "flex-end" }}>
            <Typography className={classes.label} style={{ width: 80 }}>Scene:</Typography>
            <Grid container spacing={2}>
              <Grid item xs={2}></Grid>
              {renderSceneAddress()}
              {renderSceneCity()}
              {renderSceneZip()}
            </Grid>
          </div>
        </MuiPickersUtilsProvider>

        <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-end" }}>
          <div className={classes.filterWrap} style={{ marginBottom: 8 }}>
            <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
          </div>
          <div className={classes.actions}>
            <PrintSearch
              title="Events"
              data={rows}
              cols={columns}
            />
            <Button
              className={classes.btn}
              color="primary"
              variant="contained"
              autoFocus
              onClick={handleViewDetails}
              disabled={!selection}
            >
              <PageviewIcon />View Details
            </Button>
            <Button
              className={classes.btn}
              variant="contained"
              autoFocus
              onClick={handleClear}
            >
              <ClearIcon /> Clear
            </Button>
            <Button
              className={classes.btn}
              color="primary"
              variant="contained"
              autoFocus
              onClick={handleSearch}
            >
              <SearchIcon /> Search
            </Button>
          </div>
        </div>
      </div>
      <XGrid
        //name="VfcEvent"
        className={classes.xgrid}
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
        rowHeight={38}
        onRowDoubleClick={handleViewDetails}
        onSelectionModelChange={handleSelectChange}
        disableMultipleSelection
        hideFooter
      />      
      {showDetails && <Report data={eventDetails} close={closeDetails} />}

      {<AlertDialog open={dialogOpen} onClose={handleClose} title="Search Criteria" content="Please input in at least one field before searching." />}
      {<AlertDialog open={loadingOpen} onClose={loadingClose} title="Loading" content="Sorry for the inconvenience. Still loading. Please wait." />}

    </div >
  );
}

const mapStateToProps = state => {
  return {
    webSocket: state.websocket.websocket,
    eventsSelectItems: state.vfc.eventsSelectItems,
    eventDetails: state.vfc.eventDetails
  };
};

export default connect(mapStateToProps, { handleError, getEventsSelectItems, getEventDetails, })(Events);