import store from '../config/configureStore';
import settings from 'config/settings';
import { getService } from './service';
import { handleError } from './ErrorReducer';

export const SET_UNITS = 'UNITS/SET_UNITS';

let unitsService = false;
let unitUpdatesCounter = 0;
let getUnitsTimeout = 0;
export const getUnits = () => {
  return async (dispatch) => {
    clearTimeout(getUnitsTimeout);
    getUnitsTimeout = setTimeout(() => {
      const service = getService('cmplx-units');
      service
        .find()
        .then((units) => dispatch({ type: SET_UNITS, units: processUnits(units) }))
        .catch((error) => dispatch(handleError(error)));
    }, settings.reqThrottlingTime);
  };
};

export const updateUnits = () => async (dispatch) => {
  if (settings.synchronizeData) {
    dispatch(getUnits());
  }
};

export const clearUnits = () => (dispatch) => {
  dispatch({ type: SET_UNITS, units: false });
};

export const getUnitData = (ptsUnitID) => {
  const service = getService();
  return service.get({ type: 'unit-data', data: ptsUnitID });
};

export const updateUnitData = (ptsUnitID, data) => {
  const service = getService();
  return service.patch(ptsUnitID, { type: 'update-unit-data', data });
};

export const setUnitZone = (ptsUnitID, Zone) => {
  const service = getService();
  return service.patch(ptsUnitID, { type: 'set-unit-zone', data: Zone });
};

export const subscribeUnits = () => {
  return async (dispatch) => {
    const service = getService('cmplx-units');
    dispatch(getUnits());
    service.on('newUnitsData', (units) => {
      const newUnits = processUnits(units);
      dispatch({ type: SET_UNITS, units: newUnits });
    });
    service.on('unitChange', (unit) => {
      //if data is not sysc
      if (unit.unitUpdatesCounter !== unitUpdatesCounter + 1) {
        unitUpdatesCounter = unit.unitUpdatesCounter;
        dispatch(getUnits());
        return;
      }
      // if data in sync
      unitUpdatesCounter = unit.unitUpdatesCounter;
      const action = unit.unitActionType;
      if (action !== 'modify' && action !== 'remove') return console.log('nothing to modify', unit);
      const state = store.store.getState();
      let newUnits;
      if (action === 'remove') {
        newUnits = state.units.filter((u) => u.ptsUnitID !== unit.ptsUnitID);
      }
      if (action === 'modify') {
        const processedUnit = processUnits([unit])[0];
        const { ptsUnitID } = processedUnit;
        const idx = state.units.findIndex(u => u.ptsUnitID === ptsUnitID);
        if (idx !== -1) {
          // swap unit data
          newUnits = [...state.units];
          newUnits[idx] = processedUnit;
        } else {
          // add data
          newUnits = [...state.units, processedUnit];
        }
      }
      dispatch({ type: SET_UNITS, units: newUnits });
    });
    service.on('unhandledRejection', (reason, p) => {
      console.log('Units Reducer Unhandled Rejection at: Promise ', p, ' reason: ', reason);
    });
  };
};

export const unsubscribeUnits = () => {
  if (unitsService) {
    unitsService.off('newUnitsData');
    unitsService.off('unitChange');
    unitsService = false;
  }
  return () => { };
};
// ===========  REDUCERS  ======================

export default function reducer(state = false, action) {
  switch (action.type) {
    case SET_UNITS:
      return action.units;
    default:
      break;
  }
  return state;
}

// ===============  HELPER fUNCTIONS  ==============

function processUnits(units) {
  return units.map((unit) => {
    const UnitStatuses = unit.UnitStatuses ? JSON.parse(unit.UnitStatuses) : '';
    return { ...unit, UnitStatuses };
  });
}

export const getUnitsAsync = () => {
  const service = getService('cmplx-units');
  return new Promise((resolve, reject) => {
    service
      .find()
      .then((units) => {
        resolve(processUnits(units));
      })
      .catch((error) => {
        reject(error);
      });
  });
};
