import React, { useState, useEffect, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Rating from '@material-ui/lab/Rating';
import { closeDispRecommendations } from '../reducers/DialogsReducer';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import Paper from '@material-ui/core/Paper';
import { XGrid } from '@material-ui/x-grid';
import DoneIcon from '@material-ui/icons/Done';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import Dialog from '../components/Dialog';
import {
  getEventESNData,
  getUnitsResources,
  getRequiredCapabilities,
} from '../reducers/EsnReducer';
import { calculateDistance } from '../utils/mapFunctions';
import { decBin } from '../utils/functions';
import { getZoneMatch } from '../reducers/ZonesReducer';
import { assignUnitToEvent } from '../reducers/UnitStatusReducer';
import Tooltip from '../components/Tooltip';
import { handleError } from '../reducers/ErrorReducer';
import { asyncForEach } from '../utils/functions';
import { addEventRouting, getEventRouting } from '../reducers/EventsReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from '../reducers/NotifierReducer';
import { sortObjArr } from '../utils/functions';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  grid: {
    width: 1200,
    height: 190,
    maxWidth: '100%',
    marginBottom: theme.spacing(1),
  },
  noMatch: {
    width: 1200,
    height: 250,
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    fontSize: '1.5em',
  },
  recommendations: {
    marginBottom: theme.spacing(2),
    '& > div': {
      marginLeft: theme.spacing(1),
    },
  },
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  exists: {
    '& svg': {
      color: theme.palette.success.main,
      '&:hover': {
        color: theme.palette.success.main,
      },
    },
  },
  notExists: {
    '& svg': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
  radios: {
    display: 'block',
    '& label': {
      marginBottom: 0,
    },
  },
  zones: {
    marginTop: theme.spacing(2),
  },
  gridWrap: {
    minHeight: 300,
  },
  ok: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  capabilities: {
    '& > div': {
      marginLeft: theme.spacing(1),
    },
  },
  label: {
    display: 'inline-block',
    minWidth: 180,
  },
  filters2: {
    display: 'flex',
  },
  spinner: {
    position: 'absolute',
    inset: '0 0 0 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }
}));

const renderZone = (params) => {
  const { zoneMatch } = params.row;
  const match = <><DoneIcon style={{ marginRight: 5 }} /> {params.value}</>
  const notMatch = <><CloseIcon style={{ marginRight: 5, opacity: 0.25 }} /> {params.value}</>
  return zoneMatch ? match : notMatch;
};

const RenderRec = (params) => {
  const value = params.row.recommendation;
  return <Rating name="read-only" value={value} precision={0.25} readOnly />;
};

const renderCapReq = (params) => {
  const { capFactor } = params.row;
  const reqCapabilities = params.row.capabilityMatch;
  const factor =
    reqCapabilities.langth > 0 ? (
      <span style={{ display: 'inline-block', minWidth: 40 }}>{Math.round(capFactor * 100)}%</span>
    ) : (
      ''
    );
  return (
    <Fragment>
      {capFactor > 0 && <>{factor}</>}
      {reqCapabilities.map((c, idx) => {
        return (
          <Fragment key={c.type}>
            {idx > 0 && <>,&nbsp;</>}
            <span>{c.type}</span> ({c.quantity})
          </Fragment>
        );
      })}
    </Fragment>
  );
};

const renderCapAll = (params) => {
  const reqCapabilities = params.row.capabilities;
  const factor = (
    <span style={{ display: 'inline-block', minWidth: 40 }}>
      {Math.round(params.row.capFactor * 100)}%
    </span>
  );
  return (
    <Fragment>
      {factor}
      {reqCapabilities.map((c, idx) => {
        return (
          <Fragment key={c.type}>
            {idx > 0 && <>,&nbsp;</>}
            <span>{c.type}</span> ({c.quantity})
          </Fragment>
        );
      })}
    </Fragment>
  );
};

const columnsAllCap = [
  { field: 'Unit', headerName: 'Unit', width: 100 },
  {
    field: 'Zone',
    headerName: 'Zone',
    width: 100,
    type: 'string',
    headerAlign: 'left',
    renderCell: renderZone,
  },
  { field: 'distance', headerName: 'Distance', width: 100, type: 'number', headerAlign: 'left' },
  { field: 'UnitStatus', headerName: 'Status', width: 100, type: 'string', headerAlign: 'left' },
  {
    field: 'recommendation',
    headerName: 'Recommended',
    width: 150,
    type: 'string',
    headerAlign: 'left',
    renderCell: RenderRec,
  },
  {
    field: 'capabilityMatch',
    headerName: 'Capabilities',
    width: 300,
    type: 'string',
    headerAlign: 'left',
    renderCell: renderCapAll,
  },
];

const columnsReqCap = [
  { field: 'Unit', headerName: 'Unit', width: 100 },
  {
    field: 'Zone',
    headerName: 'Zone',
    width: 100,
    type: 'string',
    headerAlign: 'left',
    renderCell: renderZone,
  },
  { field: 'distance', headerName: 'Distance', width: 100, type: 'number', headerAlign: 'left' },
  { field: 'UnitStatus', headerName: 'Status', width: 100, type: 'string', headerAlign: 'left' },
  {
    field: 'recommendation',
    headerName: 'Recommended',
    width: 150,
    type: 'string',
    headerAlign: 'left',
    renderCell: RenderRec,
  },
  {
    field: 'capabilityMatch',
    headerName: 'Capabilities',
    width: 300,
    type: 'string',
    headerAlign: 'left',
    renderCell: renderCapReq,
  },
];

const processAgencies = (Agencies) => {
  const factor = 10;
  let i = 1;
  const ems = [];
  const fire = [];
  const police = [];
  const other = [];
  Agencies.forEach((a) => {
    const { AgencyType } = a;
    if (AgencyType === 4) ems.push(a);
    if (AgencyType === 2) fire.push(a);
    if (AgencyType === 1) police.push(a);
    if (AgencyType === 0) other.push(a);
  });
  const agencies = [...other, ...police, ...fire, ...ems].map((a) => {
    a.weight = i * factor;
    i++;
    return a;
  });
  return sortObjArr(agencies, 'weight', 'DESC');
};

const getGridHeight = (units) => {
  const calcHeight = (units.length + 2) * 38;
  return Math.min(190, calcHeight);
};

const getMaxDistance = (units) =>
  units.reduce(
    (result, unit) => (unit.distance && result < unit.distance ? unit.distance : result),
    0
  );

function DispatchRecommendation(props) {
  const classes = useStyles();
  const { ptsEventID, dictionary, recConfig } = props;
  const { CallTypes } = dictionary;
  const [Agencies] = useState(processAgencies(dictionary.Agencies));
  const [currentRouting, setCurrentRouting] = useState([]);
  const [units, setUnits] = useState([]);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [RoutingRec, setRoutingRec] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filter, setFilter] = useState('recommended');
  const [onDutyFilter, setOnDutyFilter] = useState(true);
  const [zoneMatchFilter, setZoneMatchFilter] = useState(true);
  const [inserviceFilter, setInserviceFilter] = useState(true);
  const [capMatchFilter, setCapMatchFilter] = useState(true);
  const [zoneMatch, setZoneMatch] = useState([]);
  const [reqCapabilities, setReqCapabilities] = useState([]);
  const [selection, setSelection] = useState([]);
  const [emsSelection, setEmsSelection] = useState([]);
  const [lawSelection, setLawSelection] = useState([]);
  const [fireSelection, setFireSelection] = useState([]);
  const [eventUnits, setEventUnits] = useState([]);
  const [loadingInfo, setLoadingInfo] = useState('');
  const recConfigSum = useRef(1);
  const mountedRef = useRef(true);
  const closeRef = useRef(0);

  useEffect(() => {
    recConfigSum.current =
      Object.values(recConfig).reduce((r, v) => r + v, 0) -
      recConfig.notOffDuty -
      recConfig.esnMatch;
    return () => {
      mountedRef.current = false;
      clearTimeout(closeRef.current);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!ptsEventID) return;
    getData();
    // eslint-disable-next-line
  }, [ptsEventID]);

  useEffect(() => {
    filterUnits();
    // eslint-disable-next-line
  }, [filter, units, onDutyFilter, zoneMatchFilter, inserviceFilter, eventUnits]);

  const getData = async () => {
    try {
      setLoadingInfo('loading routing');
      await getRouting();
      if (!mountedRef.current) return;
      setLoadingInfo('checking ESN\'s');
      const eventEsnData = await getEventESNData(ptsEventID);
      if (!mountedRef.current) return;
      setLoadingInfo('loading required capabilities');
      const reqCapabilities = await getRequiredCapabilities(eventEsnData.event.CallType);
      if (!mountedRef.current) return;
      setLoadingInfo('checking zone match');
      const allZones = await getZoneMatch(eventEsnData.coords);
      if (!mountedRef.current) return;
      setLoadingInfo('loading unit resources');
      const resources = await getUnitsResources();
      const esn = getEsn(eventEsnData);
      const esnAgencies = getEsnAgencies(eventEsnData);
      const RoutingRec = getRoutingRecommendations(esn, eventEsnData);
      const ZoneMatch = allZones.filter((z) => esnAgencies.indexOf(z.AgencyID) !== -1);
      const unitData = getUnitData(eventEsnData, ZoneMatch, RoutingRec, esnAgencies);
      const unitsResources = addResourcesToUnits(unitData, resources, reqCapabilities);
      const recommendedUnits = getRecommendedUnits(unitsResources);
      if (!mountedRef.current) return;
      setZoneMatch(ZoneMatch);
      setRoutingRec(RoutingRec);
      setUnits(recommendedUnits);
      setReqCapabilities(reqCapabilities);
      setLoaded(true);
      setEventUnits(eventEsnData.eventUnits);
    } catch (err) {
      props.handleError(err, 'Error, Cannot get event information.');
    }
  };

  const getInserviceStatusName = () => dictionary.UnitActions.find(a => a.Category === 'Inservice').Code;

  const getOffDutyStatusName = () => dictionary.UnitActions.find(a => a.Category === 'OutService').Code;

  const filterUnits = () => {
    const Inservice = getInserviceStatusName();
    const OffDuty = getOffDutyStatusName();
    let filtered = [];
    if (filter === 'recommended') {
      filtered = units.filter((u) => u.routingMatch);
    } else {
      filtered = units.filter((u) => u.esnMatch);
    }
    filtered = filtered
      .filter((u) => eventUnits.indexOf(u.ptsUnitID) === -1)
      .filter((u) => (onDutyFilter ? u.UnitStatus !== OffDuty : true))
      .filter((u) => (inserviceFilter ? u.UnitStatus === Inservice : true))
      .filter((u) => (zoneMatchFilter ? u.zoneMatch : true));
    setFilteredUnits(filtered);
  };

  const getEsn = (eventEsnData) => {
    const CallType = CallTypes.find((c) => c.Code === eventEsnData.event.CallType);
    const binStr = decBin(CallType.AgencyType, 3);
    return {
      LawEnforcementAgencyID: parseInt(binStr[2]) ? true : false,
      FireResponseAgencyID: parseInt(binStr[1]) ? true : false,
      EMSResponseAgencyID: parseInt(binStr[0]) ? true : false,
    };
  };

  const getAgencyType = (AgencyID) => {
    const found = Agencies.find((a) => a.AgencyID === AgencyID);
    return found ? found.AgencyType : null;
  };

  const getEsnAgencies = (eventEsnData) => {
    let esnAgencies = [];
    eventEsnData.esnMatch.forEach((e) => {
      if (esnAgencies.indexOf(e.FireResponseAgencyID) === -1)
        esnAgencies.push(e.FireResponseAgencyID);
      if (esnAgencies.indexOf(e.EMSResponseAgencyID) === -1)
        esnAgencies.push(e.EMSResponseAgencyID);
      if (esnAgencies.indexOf(e.LawEnforcementAgencyID) === -1)
        esnAgencies.push(e.LawEnforcementAgencyID);
    });
    return esnAgencies;
  };

  const getRoutingRecommendations = (esn, eventEsnData) => {
    return Agencies.filter((Agency) => {
      const { AgencyID } = Agency;
      let match = false;
      eventEsnData.esnMatch.forEach((e) => {
        if (
          (esn.FireResponseAgencyID && e.FireResponseAgencyID === AgencyID) ||
          (esn.EMSResponseAgencyID && e.EMSResponseAgencyID === AgencyID) ||
          (esn.LawEnforcementAgencyID && e.LawEnforcementAgencyID === AgencyID)
        ) {
          match = true;
        }
      });
      return match;
    });
  };

  const getUnitData = (eventEsnData, ZoneMatch, RoutingRec, esnAgencies) => {
    const { coords } = eventEsnData;
    return eventEsnData.units.map((unit) => {
      const distance =
        coords && unit.lat && unit.lng
          ? Math.round(calculateDistance(eventEsnData.coords, unit) / 16.09) / 100
          : null;
      const { AgencyID } = unit;
      const routingMatch = RoutingRec.find((a) => a.AgencyID === AgencyID) ? true : false;
      const zoneMatch = ZoneMatch.find((z) => z.ZoneCode === unit.Zone) ? true : false;
      const esnMatch = esnAgencies.find((e) => e === AgencyID) ? true : false;

      return {
        ...unit,
        distance,
        id: unit.ptsUnitID,
        routingMatch,
        zoneMatch,
        esnMatch,
      };
    });
  };

  const getRecommendedUnits = (unitData) => {
    const maxDistance = getMaxDistance(unitData);
    const Inservice = getInserviceStatusName();
    const OffDuty = getOffDutyStatusName();
    return unitData.map((unit) => {
      const { routingMatch, zoneMatch, distance, UnitStatus, esnMatch, capFactor } = unit;
      let rec = 0;
      const proximity = distance !== null ? 1 - distance / maxDistance : 0;
      rec += UnitStatus === Inservice ? recConfig.inservice : 0;
      rec += UnitStatus !== Inservice && UnitStatus !== OffDuty ? recConfig.notOffDuty : 0;
      rec += esnMatch && !routingMatch ? recConfig.esnMatch : 0;
      rec += routingMatch ? recConfig.routingMatch : 0;
      rec += zoneMatch ? recConfig.zoneMatch : 0;
      rec += proximity * recConfig.proximity;
      rec += capFactor * recConfig.capability;
      const recommendation = (rec * 5) / recConfigSum.current + 0.35;
      return {
        ...unit,
        recommendation,
        AgencyType: getAgencyType(unit.AgencyID),
      };
    });
  };

  const addResourcesToUnits = (units, resources, reqCapabilities) => {
    const unitsCapabilities = getAllUnitCapabilities(resources);
    return units.map((unit) => {
      const res = unitsCapabilities.find((u) => u.ptsUnitID === unit.ptsUnitID).allCapabilities;
      const capabilities = [];
      res.forEach((resource) => {
        const { CapabilityType, CapabilityTypeQuantity } = resource;
        const type = resource.CapabilityType;
        const quantity = CapabilityTypeQuantity !== null ? CapabilityTypeQuantity : 1;
        const idx = capabilities.findIndex((c) => c.type === CapabilityType);
        if (idx === -1) {
          type !== null && capabilities.push({ type, quantity });
        } else {
          type !== null && (capabilities[idx].quantity += quantity);
        }
      });

      const capabilityMatch = [];
      capabilities.forEach((c) => {
        const find = reqCapabilities.find((r) => r.CapabilityType === c.type);
        if (find) capabilityMatch.push(c);
      });
      const reqCapCount = reqCapabilities.reduce((res, val) => res + val.CapabilityQuantity, 0);
      const capPresent = capabilityMatch.reduce((res, val) => res + val.quantity, 0);
      const capFactor = reqCapCount ? capPresent / reqCapCount : 1;
      return { ...unit, resources, capabilities, capabilityMatch, capPresent, capFactor };
    });
  };

  const getAllUnitCapabilities = (resources) => {
    const units = resources.map((unit) => {
      const { Capabilities, Resources, Vehicles } = unit;
      unit.allCapabilities = Capabilities;
      Resources.forEach((res) => {
        res.Capabilities.forEach((capability) => unit.allCapabilities.push(capability));
        res.Items.forEach((item) => {
          item.Capabilities.forEach((capability) => unit.allCapabilities.push(capability));
        });
      });
      Vehicles.forEach((res) => {
        res.Capabilities.forEach((capability) => unit.allCapabilities.push(capability));
        res.Items.forEach((item) => {
          item.Capabilities.forEach((capability) => unit.allCapabilities.push(capability));
        });
      });
      return unit;
    });
    return units;
  };

  const getRouting = async () => {
    try {
      const currentRouting = await getEventRouting(ptsEventID);
      setCurrentRouting(currentRouting);
    } catch (err) {
      props.handleError('Problems with acquiring routing information.');
    }
  };

  const close = () => {
    clearTimeout(closeRef.current);
    closeRef.current = setTimeout(props.closeDispRecommendations, 50);
  };

  const dispatch = async () => {
    await asyncForEach(selection, async (ptsUnitID) => {
      props.assignUnitToEvent(parseInt(ptsUnitID), ptsEventID);
    });
    close();
  };

  const addRouting = async () => {
    const agencies = [];
    selection.forEach((id) => {
      const ptsUnitID = parseInt(id);
      const unit = units.find((unit) => unit.ptsUnitID === ptsUnitID);
      if (
        unit &&
        agencies.indexOf(unit.AgencyID) === -1 &&
        currentRouting.indexOf(unit.AgencyID) === -1
      )
        agencies.push(unit.AgencyID);
    });
    props.showSpinner();
    try {
      await asyncForEach(agencies, async (AgencyID) => {
        await addEventRouting(AgencyID, ptsEventID);
      });
      getRouting();
      props.notify('Routing added', 'success');
    } catch (err) {
      props.handleError(err, 'Error, problems during routing agency');
    }
    props.hideSpinner();
  };

  const addAgencyRouting = async (AgencyID) => {
    props.showSpinner();
    try {
      await addEventRouting(AgencyID, ptsEventID);
      await getRouting();
    } catch (err) {
      props.handleError(err, 'Add routing error!');
    }
    props.hideSpinner();
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <div>
          <Button color="primary" onClick={addRouting} disabled={selection.length === 0}>
            <DirectionsCarIcon /> Route Agency
          </Button>
        </div>
        <div>
          <Button
            color="primary"
            onClick={dispatch}
            variant="contained"
            disabled={selection.length === 0}>
            <GroupAddIcon /> Dispatch
          </Button>
          <Button color="primary" onClick={close}>
            <CloseIcon /> Close
          </Button>
        </div>
      </div>
    );
  };

  const handleSelectChange = (AgencyType) => (selectionObj) => {
    const val = selectionObj.selectionModel;
    let selectionAll;
    switch (AgencyType) {
      case 1:
        setEmsSelection(val);
        selectionAll = [...val, ...fireSelection, ...lawSelection];
        break;
      case 2:
        setFireSelection(val);
        selectionAll = [...emsSelection, ...val, ...lawSelection];
        break;
      case 4:
        setLawSelection(val);
        selectionAll = [...emsSelection, ...fireSelection, ...val];
        break;
      default:
    }
    setSelection(selectionAll);
  };

  const renderAgencyList = (title, AgencyType) => {
    const units = filteredUnits.filter((a) => a.AgencyType === AgencyType);
    if (units.length === 0) return '';
    const AgencyID = units[0].AgencyID;
    const zoneObj = zoneMatch.find((z) => z.AgencyID === AgencyID);
    const zone = zoneObj ? `${zoneObj.ZoneCode}` : '';
    return (
      <div>
        <h6>
          {title}: {AgencyID} ({zone})
        </h6>
        <div className={classes.grid} style={{ height: getGridHeight(units) }}>
          <XGrid
            columns={capMatchFilter ? columnsReqCap : columnsAllCap}
            rows={units}
            loading={!loaded}
            rowHeight={38}
            headerHeight={38}
            checkboxSelection
            onSelectionModelChange={handleSelectChange(AgencyType)}
            hideFooter
            sortModel={[{ field: 'recommendation', sort: 'desc' }]}
          />
        </div>
      </div>
    );
  };

  const renderUnitList = () => {
    const noMatch = loaded && filteredUnits.length === 0;
    return (
      <div className={classes.gridWrap}>
        {renderAgencyList('EMS', 4)}
        {renderAgencyList('Fire Department', 2)}
        {renderAgencyList('Law Enforcement', 1)}
        {noMatch && (
          <Paper className={classes.noMatch} variant="outlined">
            <p>No results match your search criteria.</p>
          </Paper>
        )}
      </div>
    );
  };

  const renderRoutingRecommendations = () => {
    return (
      <div className={classes.recommendations}>
        <strong className={classes.label}>Routing Recommendations: </strong>
        {RoutingRec.map((Agency) => {
          const { AgencyID } = Agency;
          const exists = currentRouting.indexOf(AgencyID) !== -1;
          return (
            <React.Fragment key={AgencyID}>
              {exists && (
                <Chip
                  label={AgencyID}
                  onDelete={() => { }}
                  deleteIcon={<DoneIcon />}
                  className={classes.exists}
                  size="small"
                />
              )}
              {!exists && (
                <Chip
                  label={AgencyID}
                  onDelete={() => addAgencyRouting(AgencyID)}
                  deleteIcon={<AddIcon />}
                  className={classes.notExists}
                  size="small"
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div className={classes.filters}>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={inserviceFilter}
                onChange={(ev, val) => setInserviceFilter(val)}
                color="secondary"
              />
            }
            label="Show only INSERVICE units"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={zoneMatchFilter}
                onChange={(ev, val) => setZoneMatchFilter(val)}
                color="secondary"
              />
            }
            label="Show zone units"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={onDutyFilter}
                onChange={(ev, val) => setOnDutyFilter(val)}
                color="secondary"
              />
            }
            label="Show only on duty units"
          />
        </div>
      </div>
    );
  };

  const renderFilters2 = () => {
    return (
      <div className={classes.filters2}>
        <RadioGroup
          name="filters"
          value={filter}
          onChange={(ev) => setFilter(ev.target.value)}
          className={classes.radios}>
          <FormControlLabel value="recommended" control={<Radio />} label="Show only recommended" />
          <FormControlLabel value="esn match" control={<Radio />} label="Show ESN match" />
        </RadioGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={capMatchFilter}
              onChange={(ev, val) => setCapMatchFilter(val)}
              color="secondary"
            />
          }
          label="Show only required capabilities"
        />
      </div>
    );
  };

  const renderReqCapabilities = () => {
    const assignedUnits = units.filter((u) => eventUnits.indexOf(u.ptsUnitID) !== -1);
    const selectedCapabilities = filteredUnits
      .filter((u) => selection.indexOf(u.ptsUnitID.toString()) !== -1)
      .concat(assignedUnits)
      .reduce((res, val) => res.concat(val.capabilityMatch), [])
      .reduce(
        (res, cap) => {
          const idx = res.findIndex((c) => c.CapabilityType === cap.type);
          res[idx].fulfilled += cap.quantity;
          return res;
        },
        [
          ...reqCapabilities.map((c) => {
            return { ...c, fulfilled: 0 };
          }),
        ]
      );
    return (
      <div className={classes.capabilities}>
        <strong className={classes.label}>Required Capabilities: </strong>
        {selectedCapabilities.map((c) => {
          const { Description, CapabilityType, fulfilled, CapabilityQuantity } = c;
          const ok = fulfilled >= CapabilityQuantity;
          const icon = ok ? (
            <DoneIcon className={classes.ok} />
          ) : (
            <WarningIcon className={classes.warning} />
          );
          return (
            <Fragment key={CapabilityType}>
              <Tooltip title={Description}>
                <Chip
                  label={`${CapabilityType}(${fulfilled}/${CapabilityQuantity})`}
                  onDelete={() => { }}
                  deleteIcon={icon}
                  size="small"
                />
              </Tooltip>
            </Fragment>
          );
        })}
      </div>
    );
  };

  const renderSpinner = () => {
    return (
      <div className={classes.spinner}>
        <CircularProgress />
        <br />
        <div>{loadingInfo}</div>
      </div>
    );
  }

  return (
    <Dialog
      open={true}
      onClose={close}
      title={'Dispatch Recommendations'}
      actions={renderActions()}
      toolbar>
      {renderRoutingRecommendations()}
      {renderReqCapabilities()}
      {renderFilters()}
      {renderUnitList()}
      {renderFilters2()}
      {!loaded && renderSpinner()}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    addPage: state.dialogs.addPage,
    DispatchRecommendation: state.DispatchRecommendation,
    dictionary: state.dictionary,
    recConfig: state.config.options.Recommendations,
  };
};

export default connect(mapStateToProps, {
  handleError,
  closeDispRecommendations,
  assignUnitToEvent,
  showSpinner,
  hideSpinner,
  notify,
})(DispatchRecommendation);
