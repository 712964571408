import store from '../config/configureStore';
import { updateEvents } from './EventsReducer';
import { updateUnits } from './UnitsReducer';
import { getService } from './service';
import { addEvent } from './DialogsReducer';
import { handleError } from './ErrorReducer';
import { geocodeAddress } from '../utils/mapFunctions';
import { formatSaveDateFrom, formatSaveDateTo } from './TimeReducer';

const SET_NOTIFY = 'UNIT_STATUS/SET_NOTIFY';
const SET_NOTIFICATION = 'UNIT_STATUS/SET_NOTIFICATION';
const SET_NOTIFICATIONS = 'UNIT_STATUS/SET_NOTIFICATIONS';

export const setNotify = notify => {
  return dispatch => {
    dispatch({ type: SET_NOTIFY, payload: notify });
  };
};

export const setNotification = notification => {
  return dispatch => {
    dispatch({ type: SET_NOTIFICATION, payload: notification });
  };
};

export const setNotifications = notifications => {
  return dispatch => {
    dispatch({ type: SET_NOTIFICATIONS, payload: notifications });
  };
};

export const assignUnitToEvent = (ptsUnitID, ptsEventID) => (dispatch) => {
  const events = store.store.getState().events;
  const eventUnit = getEventUnit(events, ptsUnitID);
  let data;
  if (eventUnit) {
    data = eventUnit;
  } else {
    data = { ptsUnitID };
  }
  dispatch(unitStatusAllChange(data, ptsEventID));
};

export const setUnitStatus = (UnitStatus, ptsUnitID, ptsEventID = null) => (dispatch) => {
  const events = store.store.getState().events;
  const unit = getEventUnit(events, ptsUnitID, ptsEventID);
  const data = { ...unit, ptsUnitID, UnitStatus, Occurred: null };
  dispatch(unitStatusAllChange(data, ptsEventID));
};

export const getUnitStatus = (ptsUnitID, ptsEventID = null) => {
  const service = getService();
  return service.get({ type: 'unit-status', data: { ptsUnitID, ptsEventID } });
};

export const safetyCheck = (ptsUnitID, Unit) => {
  const service = getService();
  return service.create({ type: 'unit-safety-check', data: { ptsUnitID, Unit } });
};

export const unitStatusAllChange = (unit, ptsEventID = null, ptsActionID = null) => {
  return async (dispatch) => {
    try {
      await updateUnitStatusPromise(unit, ptsEventID, ptsActionID);
    } catch (error) {
      dispatch(handleError(error, 'Unit status change error.'));
    }
  };
};

export const updateUnitStatusPromise = async (unit, ptsEventID = null, ptsActionID = null) => {
  try {
    const { ptsUnitID, Location, Mileage, Notes, Plate, OLN, UnitStatus } = unit;
    const statuses = store.store.getState().dictionary.UnitActions;
    const OutServiceStatus = statuses.find(status => status.Category && status.Category.toLowerCase() === 'outservice')?.Code || 'OFFDUTY';
    const InServiceStatus = statuses.find(status => status.Category && status.Category.toLowerCase() === 'inservice')?.Code || 'INSERVICE';
    const service1 = getService('unit-status');
    const unitAction = await service1.get(ptsUnitID);
    const status = unitAction?.Action || 'unknown';
    const service = getService('unit-status-change');
    const Modifiers = (unit.Modifiers && unit.Modifiers.length) ? unit.Modifiers.map(m => m.Code).join() : null;
    const data = {
      ...unit,
      Location: nullIfEmpty(Location),
      Modifiers,
      Mileage: nullIfEmpty(Mileage),
      Notes: nullIfEmpty(Notes),
      Plate: nullIfEmpty(Plate),
      OLN: nullIfEmpty(OLN),
      ptsEventID,
      ptsActionID,
    };
    if (status === OutServiceStatus && UnitStatus !== InServiceStatus && UnitStatus !== undefined) {
      await service.update(ptsUnitID, data);
      const outServiceData = {
        Location: null,
        Mileage: null,
        Modifiers: null,
        Notes: null,
        OLN: null,
        Occurred: null,
        Plate: null,
        UnitStatus: OutServiceStatus,
        ptsActionID: null,
        ptsEventID: null,
        ptsUnitID
      };
      return service.update(ptsUnitID, outServiceData);
    } else {
      return service.update(ptsUnitID, data);
    }
  } catch (err) {
    console.log('Error updating unit status');
  }

};

export const getOutserviceUnits = () => {
  const service = getService();
  return service.get({ type: 'outservice-units' });
};

export const unitInitEvent = (ptsUnitID) => async (dispatch) => {
  try {
    const unitStatus = await getUnitStatus(ptsUnitID);
    const eventData = await formatUnitStatusData(unitStatus);
    dispatch(addEvent(eventData));
  } catch (err) {
    handleError(err, 'Error, Unit Initiated Event not created.')
  }
}

export default function reducer(
  state = {
    notify: false,
    notification: {},
    notifications: []
  },
  action
) {
  switch (action.type) {
    case SET_NOTIFY:
      return {
        ...state,
        notify: action.payload
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      };
    default:
      break;
  }
  return state;
}

// Helper functions

function nullIfEmpty(obj) {
  return obj === undefined || obj === '' ? null : obj;
}

function findUnitInEvent(event, ptsUnitID) {
  if (!event || event.assignedUnits) return;
  return event.assignedUnits.find((unit) => unit.ptsUnitID === parseInt(ptsUnitID));
}

export function getEventUnit(allEvents, ptsUnitID, ptsEventID) {
  let eventUnit = null;
  if (ptsEventID) {
    const event = allEvents.find((event) => event.ptsEventID === ptsEventID);
    if (event) eventUnit = findUnitInEvent(event, ptsUnitID);
  }
  if (eventUnit) return eventUnit;
  allEvents.forEach((event) => {
    const eu = findUnitInEvent(event, ptsUnitID);
    if (eu) eventUnit = eu;
  });
  return eventUnit;
}

export const getUnitStatusHistory = async (dateFrom, dateTo, filter) => {
  const service = getService();
  return service.get({
    type: 'unit-status-history',
    data: {
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

const formatUnitStatusData = async (unitData) => {
  const { Created, Location, Notes, ptsUnitID } = unitData;
  const CallType = store.store.getState().config.options.DefaultUnitInitEventType;
  const data = {
    Event: {
      CallType,
      CallMethod: null,
      RequestedAction: null,
      Description: Location,
      lat: null,
      lng: null,
      CallerPhone: null,
    },
    Notes: Notes ? [{ Comment: Notes }] : [],
    type: 'unit-initiated',
    Unit: {
      ptsUnitID,
      Created,
    }
  };

  const { Mileage, OLN, OLNState, Plate, PlateState, modifiers, Action } = unitData;
  let statusInfo = '';
  const Modifiers = modifiers ? modifiers.map(m => m.Modifier).join(', ') : '';
  if (Action) statusInfo += `Action: ${Action}, \n`;
  if (Mileage) statusInfo += `Mileage: ${Mileage}, \n`;
  if (OLN) statusInfo += `OLN: ${OLN}, \n`;
  if (OLNState) statusInfo += `OLN State: ${OLNState}, \n`;
  if (Plate) statusInfo += `Plate: ${Plate}, \n`;
  if (PlateState) statusInfo += `Plate State: ${PlateState}, \n`;
  if (modifiers) statusInfo += `Modifiers: ${Modifiers}`;
  if (statusInfo) data.Notes.push({ Comment: statusInfo });

  if (Location) {
    const coords = await geocodeAddress(Location);
    if (coords) {
      data.Event.lat = coords.lat;
      data.Event.lng = coords.lng;
    }
  }
  data.Notes.unshift({ Comment: '' });
  return data;
}
