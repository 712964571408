import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import Tooltip from 'components/Tooltip';
import { showReport } from 'reducers/DialogsReducer';
import Fab from '@material-ui/core/Fab';
import { printRef } from 'utils/functions';
import { displayDateTime } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  menu: {
    '& li': {
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.text.primary,
      '& a': {
        color: theme.palette.text.primary,
        '&:hover': {
          color: theme.palette.text.primary,
        },
      },
    },
  },
  menuIcon: {
    margin: '0 10px 0 -10px',
  },
  menuRight: {
    position: 'relative',
  },
  iframe: {
    border: 'none',
    overflow: 'hidden',
    width: 1,
    height: 1,
  },
  tableWrap: {
    display: "none",
  },
}));

function TableCreator(props) {
  const { data, cols } = props;

  const formatBool = (str) => {
    if (str === true) return 'YES';
    if (str === false) return 'NO';
    return str;
  }

  return (
    <table>
      <thead>
        <tr>
          {cols.map(c =>
            <th key={`header-${c.headerName}`}>
              {c.headerName}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map(item =>
          <tr key={`${item.id}-row`}>
            {cols.map(c =>
              <td key={`${item.id}-${c.field}`}>
                {(c.format === 'date') && displayDateTime(item[c.field])}
                {(c.format === 'bool') && formatBool(item[c.field])}
                {(c.format !== 'date' && c.format !== 'bool') && item[c.field]}
              </td>
            )}
          </tr>
        )}
      </tbody>
    </table>
  )
}

function PrintSearch3(props) {
  const classes = useStyles();
  const { title, data, cols, kbdKey, printData } = props;
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [printFlag, setPrintFlag] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (kbdKey === 'Ctrl+KeyL') print();
  }, [kbdKey]);

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const printList = async () => {
    closeMenu();
    await setPrintFlag(true);
    printRef(ref, title);
  }

  const renderPrint = () => {
    return (
      <Tooltip title="Print" >
        <Fab
          size="small"
          color="secondary"
          onClick={(ev) => setMenuAnchor(ev.currentTarget)}
        >
          <LocalPrintshopRoundedIcon />
        </Fab>
      </Tooltip >
    );
  }

  const renderTableContent = () => {
    return (
      <>
        <iframe id="print-iframe" className={classes.iframe}></iframe>
        {printFlag && <div className={classes.tableWrap} ref={ref}>
          <TableCreator data={data} cols={filterColumns(cols)} />
        </div>}
      </>
    );
  }

  const renderMenu = () => {
    return (
      <Menu
        className={classes.menu}
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={closeMenu}
      >
        <MenuItem onClick={printList}>Print List</MenuItem>
        {printData.map(item => <MenuItem onClick={props.showReport} key={item.name}>{item.name}</MenuItem>)}
      </Menu>
    );
  }

  const filterColumns = (columns) => columns.filter(col => !col.hide);

  return (
    <>
      {renderPrint()}
      {renderMenu()}
      {renderTableContent()}
    </>
  );
}

export default connect(null, {
  showReport
})(PrintSearch3);