import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField2 from 'components/TextField2';
import formStyles, { formatSaveData, gridStyle, Row, RowInner, ColCard, Fills } from 'utils/formStyles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { dateTimePicker, formatSaveDate, getDateTimeFormat, formatSaveDateFrom, formatSaveDateTo, displayDateTime, displayDate } from 'reducers/TimeReducer';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { findWarrants, getWarrantsSelectItems, getWarrantDetails } from 'reducers/VfcReducer';
import { handleError } from 'reducers/ErrorReducer';
import { XGrid } from '@material-ui/x-grid';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Report from './WarrantsReport';
import PageviewIcon from '@material-ui/icons/Pageview';
import PrintSearch from '../../components/PrintsSearch';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import Autocomplete2 from "../../components/Autocomplete2";
import AlertDialog from "../../Dialogs/AlertDialog";

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  addressCategory: gridStyle(220, '100%'),
  item: gridStyle(170, '100%'),
  timePicker: gridStyle(200, '100%'),
  btn: {
    marginLeft: 8,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  actions: {
    marginTop: 8,
    textAlign: 'right',
    marginBottom: 16,
  },
  content: {
    padding: theme.spacing(2),
  },
  xgrid: {
    width: '100%',
    height: 500
  },
  label: {
    //variant: "h3",
    fontSize: 14
  },
  autocomplete: {
    margin: '0 0px 0px',
  },
}));

const defaultColumns = [
  { field: 'Number', headerName: 'Number', width: 120 },
  {
    field: 'Issued',
    headerName: 'Date Issued',
    width: 150,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  {
    field: 'Execution',
    headerName: 'Execution',
    width: 150,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  {
    field: 'Return',
    headerName: 'Return Date',
    width: 150,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },    
  { field: 'SONumber', headerName: 'SO Number', width: 150 },
  { field: 'BookinNumber', headerName: 'Booking Number', width: 150 },
  { field: 'Name', headerName: 'Name', width: 150 },
  { field: 'Race', headerName: 'Race', width: 120 },
  { field: 'Sex', headerName: 'Sex', width: 80 },
  {
    field: 'DOB',
    headerName: 'DOB',
    width: 150,
    valueFormatter: (params) => displayDate(params.value),
    format: 'date',
  },
  { field: 'DLNumber', headerName: 'DL Number', width: 120 },
  { field: 'SSN', headerName: 'SSN', width: 120 },
  { field: 'ChargeDescription', headerName: 'Charge Description', width: 250 },
];

function Warrants(props) {
  const classes = useStyles();
  //console.log("props:", props);
  const warrantsSelectItems = props.warrantsSelectItems;
  const warrantDetails = props.warrantDetails;
  const webSocket = props.webSocket;

  const [IssuedFrom, setIssuedFrom] = useState(null);
  const [IssuedTo, setIssuedTo] = useState(null);
  const [ExecutionFrom, setExecutionFrom] = useState(null);
  const [ExecutionTo, setExecutionTo] = useState(null);
  const [ReturnFrom, setReturnFrom] = useState(null);
  const [ReturnTo, setReturnTo] = useState(null);  
  const [SONumber, setSONumber] = useState(null);
  const [BookinNumber, setBookinNumber] = useState(null);
  const [Name, setName] = useState(null);
  const [Race, setRace] = useState(null);
  const [Sex, setSex] = useState(null);
  const [DOB, setDOB] = useState(null);
  const [DLNumber, setDLNumber] = useState(null);
  const [SSN, setSSN] = useState(null);
  const [ChargeDescription, setChargeDescription] = useState(null);

  const [selection, setSelection] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState(defaultColumns);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [showDetails, setShowDetails] = useState(false);
  //const [details, setDetails] = useState(null);

  useEffect(() => {
    if (warrantDetails && selection) setShowDetails(true);
  }, [warrantDetails]);

  const closeDetails = () => {
    setShowDetails(false);
  };

  useEffect(async () => {
    if (webSocket) {
      console.log("webSocket != null");

      props.getWarrantsSelectItems();
    }
  }, [webSocket]);

  const handleClear = () => {
    setIssuedFrom(null);
    setIssuedTo(null);
    setExecutionFrom(null);
    setExecutionTo(null);
    setReturnFrom(null);
    setReturnTo(null);
    setSONumber(null);
    setBookinNumber(null);
    setName(null);
    setRace(null);
    setSex(null);
    setDOB(null);
    setDLNumber(null);
    setSSN(null);
    setChargeDescription(null);
    
    setRows([]);
  }

  const handleSearch = async () => {
    const _Race = Race ? Race.Description : null;
    const _Sex = Sex ? Sex.Description : null;
    const _ChargeDescription = ChargeDescription ? ChargeDescription.Description : null;
    
    if (warrantsSelectItems == null || Object.keys(warrantsSelectItems).length === 0 || warrantsSelectItems.Race == null || warrantsSelectItems.Race.length === 0) {
      if (dialogOpen) {
        handleClose();
        return;
      }
      setLoadingOpen(true);
      return;
    }

    if (IssuedFrom == null && IssuedTo == null && ExecutionFrom == null && ExecutionTo == null && ReturnFrom == null && ReturnTo == null &&
      SONumber == null && BookinNumber == null && Name == null && _Race == null && _Sex == null && DOB == null && DLNumber == null && SSN == null && _ChargeDescription == null) {
      if (loadingOpen) {
        loadingClose();
        return;
      }
      setDialogOpen(true);
      return;
    }

    const data = formatSaveData({
      IssuedFrom: formatSaveDateFrom(IssuedFrom),
      IssuedTo: formatSaveDateTo(IssuedTo),
      ExecutionFrom: formatSaveDateFrom(ExecutionFrom),
      ExecutionTo: formatSaveDateTo(ExecutionTo),
      ReturnFrom: formatSaveDateFrom(ReturnFrom),
      ReturnTo: formatSaveDateTo(ReturnTo),      
      SONumber,
      BookinNumber,
      Name,
      Race: _Race,
      Sex: _Sex,
      DOB: formatSaveDateFrom(DOB),
      DLNumber,
      SSN,
      ChargeDescription: _ChargeDescription
    });
    //console.log("============Warrants data=============:", data);

    setLoaded(false);
    findWarrants(data)
      .then(setRows)
      .catch(props.handleError)
      .finally(() => setLoaded(true))
  }

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel[0]);
  };

  const handleViewDetails = () => {
    const item = rows.find(r => r.id === selection);
    console.log(item.vfcMainId);
    //setDetails(item);
    props.getWarrantDetails(item.vfcMainId);
  }

  const handleClose = () => {
    setDialogOpen(false);
  };

  const loadingClose = () => {
    setLoadingOpen(false);    
  };

  const renderIssuedFrom = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Issued:</Typography>
          <KeyboardDatePicker
            autoOk            
            value={IssuedFrom}
            onChange={setIssuedFrom}
            inputVariant="outlined"
            size="small"
            format="MM/dd/yyyy"
          />
        </div>
      </Grid >
    );
  }

  const renderIssuedTo = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <KeyboardDatePicker
            autoOk            
            value={IssuedTo}
            onChange={setIssuedTo}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderExecutionFrom = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Execution:</Typography>
          <KeyboardDatePicker
            autoOk            
            value={ExecutionFrom}
            onChange={setExecutionFrom}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderExecutionTo = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <KeyboardDatePicker
            autoOk            
            value={ExecutionTo}
            onChange={setExecutionTo}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderReturnFrom = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Return:</Typography>
          <KeyboardDatePicker
            autoOk            
            value={ReturnFrom}
            onChange={setReturnFrom}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderReturnTo = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <KeyboardDatePicker
            autoOk            
            value={ReturnTo}
            onChange={setReturnTo}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderSONumber = () => {
    const onChange = (ev, val) => setSONumber(val);
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>SO Number:</Typography>
          <TextField2            
            value={SONumber}
            onChange={onChange}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderBookinNumber = () => {
    const onChange = (ev, val) => setBookinNumber(val);
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Booking Number:</Typography>
          <TextField2            
            value={BookinNumber}
            onChange={onChange}
            compact
          />
        </div>
      </Grid >
    );
  };

  const renderName = () => {
    const onChange = (ev, val) => setName(val);
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Name:</Typography>
          <TextField2            
            value={Name}
            onChange={onChange}
            compact
          />
        </div>
      </Grid >
    );
  };

  const renderRace = () => {
    const onChange = (ev, val) => setRace(val);
    return (      
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Race:</Typography>
          <Autocomplete2
            options={(warrantsSelectItems && warrantsSelectItems.Race) ? warrantsSelectItems.Race : []}            
            onChange={onChange}
            value={Race}            
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : "")}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderSex = () => {
    const onChange = (ev, val) => setSex(val);
    return (      
      <Grid item xs={1}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Sex:</Typography>
          <Autocomplete2
            options={(warrantsSelectItems && warrantsSelectItems.Sex) ? warrantsSelectItems.Sex : []}
            onChange={onChange}
            value={Sex}            
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : "")}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderDOB = () => {
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>DOB:</Typography>
          <KeyboardDatePicker
            autoOk            
            value={DOB}
            onChange={setDOB}
            inputVariant="outlined"
            size="small"            
            format="MM/dd/yyyy"
          />
        </div>
      </Grid>
    );
  }

  const renderDLNumber = () => {
    const onChange = (ev, val) => setDLNumber(val);
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>DL/ID Number:</Typography>
          <TextField2            
            value={DLNumber}
            onChange={onChange}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderSSN = () => {
    const onChange = (ev, val) => setSSN(val);
    return (
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>SSN:</Typography>
          <TextField2            
            value={SSN}
            onChange={onChange}
            compact
          />
        </div>
      </Grid>
    );
  };

  const renderChargeDescription = () => {
    const onChange = (ev, val) => setChargeDescription(val);
    return (      
      <Grid item xs={2}>
        <div style={{ display: "flex", alignItems: "stretch", flexDirection: 'column', width: "100%", gap: 10 }}>
          <Typography className={classes.label}>Charge Description:</Typography>
          <Autocomplete2
            options={(warrantsSelectItems && warrantsSelectItems.ChargeDescription) ? warrantsSelectItems.ChargeDescription : []}            
            onChange={onChange}
            value={ChargeDescription}
            className={classes.autocomplete}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (option.code ? option.Description : "")}
            renderOption={(option) => <span>{option.Description}</span>}
            compact
          />
        </div>
      </Grid>
    );
  };


  return (
    <div className={classes.content}>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div style={{ display: "flex", flexDirection: 'row', alignItems: "flex-end" }}>
            
            <Typography className={classes.label} style={{ width: 100 }}>From Date:</Typography>
            <Grid container spacing={2}>            
              {renderIssuedFrom()}
              {renderExecutionFrom()}
              {renderReturnFrom()}                            
            </Grid>            
          </div>
          <div style={{ display: "flex", flexDirection: 'row', alignItems: "flex-end" }}>            
            <Typography className={classes.label} style={{ width: 100 }}>To Date:</Typography>
            <Grid container spacing={2}>
              {renderIssuedTo()}
              {renderExecutionTo()}
              {renderReturnTo()}                            
            </Grid>            
          </div>

          <div style={{ display: "flex", flexDirection: 'row', alignItems: "flex-end" }}>
            <Grid container spacing={2}>
              {renderSONumber()}
              {renderBookinNumber()}
              {renderName()}
              {renderRace()}
              {renderSex()}  
              {renderDOB()}
            </Grid>            
          </div>
          <div style={{ display: "flex", flexDirection: 'row', alignItems: "flex-end" }}>            
            <Grid container spacing={2}>
              {renderDLNumber()}
              {renderSSN()}
              {renderChargeDescription()}              
            </Grid>
          </div>
        </MuiPickersUtilsProvider>

        <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "flex-end" }}>
          <div className={classes.filterWrap} style={{ marginBottom: 8 }}>
            <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
          </div>
          <div className={classes.actions}>
            <PrintSearch
              title="Warrants"
              data={rows}
              cols={columns}
            />
            <Button
              className={classes.btn}
              color="primary"
              variant="contained"
              autoFocus
              onClick={handleViewDetails}
              disabled={!selection}
            >
              <PageviewIcon />View Details
            </Button>
            <Button
              className={classes.btn}
              variant="contained"
              autoFocus
              onClick={handleClear}
            >
              <ClearIcon /> Clear
            </Button>
            <Button
              className={classes.btn}
              color="primary"
              variant="contained"
              autoFocus
              onClick={handleSearch}
            >
              <SearchIcon /> Search
            </Button>
          </div>
        </div>
      </div>
      <XGrid
        //name="VfcEvent"
        className={classes.xgrid}
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
        rowHeight={38}
        onRowDoubleClick={handleViewDetails}
        onSelectionModelChange={handleSelectChange}
        disableMultipleSelection
        hideFooter
      />      
      {showDetails && <Report data={warrantDetails} close={closeDetails} />}

      {<AlertDialog open={dialogOpen} onClose={handleClose} title="Search Criteria" content="Please input in at least one field before searching." />}
      {<AlertDialog open={loadingOpen} onClose={loadingClose} title="Loading" content="Sorry for the inconvenience. Still loading. Please wait." />}

    </div >
  );
}

const mapStateToProps = state => {
  return {
    webSocket: state.websocket.websocket,
    warrantsSelectItems: state.vfc.warrantsSelectItems,
    warrantDetails: state.vfc.warrantDetails
  };
};

export default connect(mapStateToProps, { handleError, getWarrantsSelectItems, getWarrantDetails, })(Warrants);