import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { showDisplayDataWindow } from 'reducers/DialogsReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchPhoneNumbers } from 'reducers/SearchReducer';
import PrintsSearch2 from '../components/PrintsSearch2';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import SearchHotKeys from 'Search/SearchHotKeys';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import { showEditPerson, showEditPlace } from 'reducers/DialogsReducer';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'PartyType', headerName: 'Party Type', width: 150, hide: false },
  { field: 'ParentName', headerName: 'Parent Name', width: 150, hide: false },
  { field: 'ParentID', headerName: 'Parent ID', width: 150, hide: false },
  { field: 'ContactMethod', headerName: 'Contact Method', width: 150, hide: false },
  { field: 'MethodType', headerName: 'Method Type', width: 150, hide: false },
  { field: 'PhoneNumber', headerName: 'Phone Number', width: 150, hide: false },
  {
    field: 'IsDefault',
    headerName: 'Is Default',
    width: 150,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 140, hide: false },
  {
    field: 'ParentIsDeleted',
    headerName: 'Parent Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'ParentDeleted',
    headerName: 'Parent Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'ParentDeletedBy', headerName: 'Parent Deleted By', width: 150, hide: false },
  { field: 'ParentDeletedIP', headerName: 'Parent Deleted IP', width: 140, hide: false },
];

function PhoneNumber(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [phoneNumberColumns, setPhoneNumberColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);
  const personPermissions = getFullPermissions('globals', 'People');
  const placePermissions = getFullPermissions('globals', 'Places');
  const Read = personPermissions.Read || placePermissions.Read;

  useEffect(() => {
    getPhoneNumberData();
  }, []);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        getPhoneNumberData();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const getPhoneNumberData = async () => {
    setLoaded(false);
    searchPhoneNumbers()
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const viewDetails = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showDisplayDataWindow({
      data: filterData(item),
      title: `Phone number Details - ${item.ParentName}`,
    });
  };

  const filterData = (rawData) => {
    const {
      PartyType,
      ParentName,
      ParentID,
      ContactMethod,
      MethodType,
      PhoneNumber,
      IsDefault,
      Notes,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    } = rawData;
    return {
      PartyType,
      ParentName,
      ParentID,
      ContactMethod,
      MethodType,
      PhoneNumber,
      IsDefault,
      Notes,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    };
  };

  const showEdit = () => {
    if (selection === null) return false;
    const item = rows.find((row) => row.id === selection);
    const { ptsParentID, PartyType } = item;
    if (PartyType === 'Place') {
      if (placePermissions.Edit && ptsParentID) return true;
    } else {
      if (personPermissions.Edit && ptsParentID) return true;
    }
    return false;
  }

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsParentID, PartyType } = item;
    if (PartyType === 'Place') {
      if (placePermissions.Edit) {
        props.showEditPlace(ptsParentID);
      } else {
        props.notify('You not\' have permissions to view this record');
      }
    } else {
      if (personPermissions.Edit) {
        props.showEditPerson(ptsParentID);
      } else {
        props.notify('You not\' have permissions to view this record');
      }
    }
  };

  if (!Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Phone Numbers</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={phoneNumberColumns}
            setFilteredRows={setFilteredRows}
          />
        </div>
        <div className={classes.actions}>
          <PrintsSearch2
            title="Phone Number Records"
            data={filteredRows}
            cols={phoneNumberColumns}
            kbdKey={kbdKey}
          />
          {selection !== null && (
            <Tooltip title="Veiw details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
          )}
          {showEdit() && (
            <Tooltip title="Edit person">
              <Fab className="ml-2" size="small" color="secondary" onClick={edit}>
                <EditIcon />
              </Fab>
            </Tooltip>
          )}
        </div>
      </div>
      <XGrid3 
        name="phoneNumbers"
        columns={phoneNumberColumns}
        setColumns={setPhoneNumberColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

export default connect(null, {
  handleError,
  showDisplayDataWindow,
  showEditPerson,
  showEditPlace,
  notify,
})(PhoneNumber);
