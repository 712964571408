import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { showReport } from 'reducers/DialogsReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchAdvancedAddresses } from 'reducers/SearchReducer';
import PrintsSearch2 from '../components/PrintsSearch2';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';

import SearchHotKeys from 'Search/SearchHotKeys';
import { showAddAttachment } from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { showAddressDialog } from 'reducers/DialogsReducer';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import XGrid3 from 'components/XGrid3';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'AddressID', headerName: 'Address ID', width: 150 },
  { field: 'FullAddress', headerName: 'Full Address', width: 150 },
  { field: 'PhoneNumber', headerName: 'Phone Number', width: 150 },
  { field: 'PersonID', headerName: 'Person ID', width: 150 },
  { field: 'FullName', headerName: 'Full Name', width: 150 },
  {
    field: 'IsPrimary',
    headerName: 'IsPrimary',
    width: 150,
    format: 'bool',
    renderCell: bool,
  },
  { field: 'AddressNumber', headerName: 'Address Number', width: 150, hide: false },
  { field: 'StreetName', headerName: 'Street Name', width: 150, hide: false },
  { field: 'StreetType', headerName: 'Street Type', width: 150, hide: false },
  { field: 'City', headerName: 'City', width: 150, hide: false },
  { field: 'State', headerName: 'State', width: 150, hide: false },
  { field: 'PostalCode', headerName: 'Postal Code', width: 150, hide: false },
  { field: 'PostalCodeExtension', headerName: 'Extension', width: 150, hide: false },
  { field: 'County', headerName: 'County', width: 150, hide: false },
  { field: 'AddressCategory', headerName: 'Address Category', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 140, hide: false },
];

function AdvancedAddress(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [advAddressColumns, setAdvAddressColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);
  const permissions = getFullPermissions('globals', 'Addresses');

  useEffect(() => {
    getAdvancedAddressData();
  }, []);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        getAdvancedAddressData();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const getAdvancedAddressData = async () => {
    setLoaded(false);
    searchAdvancedAddresses()
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const viewDetails = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({
      id: 40,
      data: { ptsAddressID: item.ptsAddressID },
      title: 'Address Details',
    });
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsAddressID } = item;
    props.showAddAttachment({ id: ptsAddressID, type: 'Address' });
  };

  const add = () => {
    props.showAddressDialog(true);
  };

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsAddressID } = item;
    props.showAddressDialog({ ptsAddressID });
  };

  if (!permissions.Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Advanced Addresses</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={advAddressColumns}
            setFilteredRows={setFilteredRows}
          />
        </div>
        <div className={classes.actions}>
          <PrintsSearch2
            title="Advanced Addresses"
            data={filteredRows}
            cols={advAddressColumns}
            kbdKey={kbdKey}
          />
          {permissions.Create && <Tooltip title="Veiw details">
            <Fab className="ml-2" size="small" color="secondary" onClick={add}>
              <AddIcon />
            </Fab>
          </Tooltip>}
          {selection !== null && (
            <>
              {permissions.Edit && <Tooltip title="Veiw details">
                <Fab className="ml-2" size="small" color="secondary" onClick={edit}>
                  <EditIcon />
                </Fab>
              </Tooltip>}
              <Tooltip title="Veiw details">
                <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add attachment">
                <Fab className="ml-2" color="secondary" size="small" onClick={addAttachment}>
                  <AttachFileIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3 
        name="advancedAddresses"
        columns={advAddressColumns}
        setColumns={setAdvAddressColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

export default connect(null, {
  handleError,
  showReport,
  showAddAttachment,
  showAddressDialog,
})(AdvancedAddress);
