import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
    '& > button': {
      marginTop: 7,
    }
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const today = new Date();
const defaultStartDate = new Date();
defaultStartDate.setDate(defaultStartDate.getDate() - 30);

function SearchDatePicker(props) {
  const classes = useStyles();
  const { dateFrom, setDateFrom, dateTo, setDateTo, actions } = props;

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, []);

  const search = () => props.search(dateFrom, dateTo);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.filterWrap}>
        <div className={classes.filters}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            margin="normal"
            label="Date From"
            value={dateFrom}
            onChange={(date) => setDateFrom(date)}
            maxDate={dateTo}
            autoOk
            size="small"
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            margin="normal"
            label="Date To"
            value={dateTo}
            onChange={(date) => setDateTo(date)}
            maxDate={today}
            minDate={dateFrom}
            autoOk
            size="small"
          />
          <Button variant="outlined" onClick={search}>Search</Button>
        </div>
        {actions}
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default SearchDatePicker;