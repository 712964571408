import { getService } from './service';

export const getReport = (reportId, data) => {
  const service = getService('ssrs');
  service.timeout = 90000;
  return service.get(reportId, {
    query: { data }
  });
};

export const sendRipAndRun = (ptsEventID) => {
  const service = getService('rip-and-run');
  service.timeout = 90000;
  return service.create({
    ptsEventID
  });
};