import React, { useState } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeAddAttachment } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import TextField from '@material-ui/core/TextField';
import { uploadAttachment } from 'reducers/FileReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { updateEvents } from 'reducers/EventsReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import Tooltip from 'components/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from 'components/AttachmentIcon';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  txtField: {
    minWidth: 600
  },
  fileInput: {
    display: 'none',
  },
  dropZone: {
    background: theme.colors.grey1,
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    marginBottom: 8,
    '& svg': {
      marginRight: 8,
    },
  },
  dropZoneOver: {
    background: theme.colors.grey1,
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `3px dashed ${theme.palette.primary.main}`,
    marginBottom: 8,
    '& svg': {
      marginRight: 8,
    },
  },
  fileInfo: {
    minHeight: 35,
    '& img': {
      maxHeight: 24,
      borderRadius: 3,
      marginRight: 8,
    },
  },
}));

function AddAttachment(props) {
  const classes = useStyles();
  const { id, type, title = 'Add Attachment' } = props.data;
  const [Description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [draggedOver, setDraggedOver] = useState(false);

  const close = () => {
    props.closeAddAttachment();
  };

  const handleAttachmentChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const save = async () => {
    props.showSpinner();
    try {
      await uploadAttachment(id, file, type, Description);
      props.updateEvents();
      props.notifyDataUpdate({ type: 'file-upload', data: type });
      props.notify('File uploaded', 'success');
    } catch (err) {
      props.handleError(err)
    }
    props.hideSpinner();
    close();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!file}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const onDrop = (ev) => {
    const files = ev.dataTransfer.files;
    if (!files.length) return setDraggedOver(false);
    setFile(files[0]);
    ev.preventDefault();
  }

  const onDragOver = (ev) => {
    if (!draggedOver) setDraggedOver(true);
    ev.preventDefault();
  }

  const onDragLeave = (ev) => {
    if (draggedOver) setDraggedOver(false);
    ev.preventDefault();
  }

  const renderFileInfo = () => {
    const idx = file.name.lastIndexOf('.');
    const type = file.name.substr(idx);
    return (
      <>
        <AttachmentIcon type={type} />
        <span>{file.name}</span>
      </>
    );
  }

  const inputId = `upload-file-${type}-${id}`;
  const className = (draggedOver || Boolean(file)) ? classes.dropZoneOver : classes.dropZone;
  return (
    <Dialog toolbar onClose={close} title={title} actions={renderActions()}>
      <div
        className={className}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      >
        <Tooltip title="Add Attachment">
          <label htmlFor={inputId}>
            <Button color="primary" variant="outlined" component="span">
              <GetAppIcon /> Add Attachment
            </Button>
          </label>
        </Tooltip>
        <input
          accept="*"
          className={classes.fileInput}
          onChange={handleAttachmentChange}
          id={inputId}
          type="file"
        />
      </div>
      <div className={classes.fileInfo}>
        {Boolean(file) && renderFileInfo()}
      </div>
      <TextField
        label="Description"
        variant="outlined"
        size="small"
        value={Description}
        onChange={e => setDescription(e.target.value)}
        className={classes.txtField}
      />
    </Dialog >
  );
}

export default connect(null, {
  closeAddAttachment,
  notify,
  handleError,
  updateEvents,
  showSpinner,
  hideSpinner,
  notifyDataUpdate,
})(AddAttachment);
