import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchSops } from 'reducers/SearchReducer';
import PrintsSearch2 from '../components/PrintsSearch2';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import { showReport, addSop, editSop } from 'reducers/DialogsReducer';
import SearchHotKeys from 'Search/SearchHotKeys';
import XGrid3 from 'components/XGrid3';
import { Redirect } from 'react-router-dom';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'Title', headerName: 'Title', width: 200, hide: false },
  { field: 'DispatcherMessage', headerName: 'Dispatcher Message', width: 200, hide: false },
  { field: 'AlertMessage', headerName: 'Alert Message', width: 150, hide: false },
  {
    field: 'IsAlertMessageUsed',
    headerName: 'Is Alert Message Used',
    width: 180,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'ShowPopupNotification',
    headerName: 'Show Popup Notification',
    width: 150,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 140, hide: false },
];

function Sop(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [sopColumns, setSopColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);
  const permissions = getFullPermissions('globals', 'SOPs');

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === 'update-sop') search();
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        search();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const search = async () => {
    setLoaded(false);
    searchSops()
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: row.ptsSOPID,
      };
    });
  };

  const viewDetails = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 48, data: { ptsSOPID: item.ptsSOPID }, title: 'SOP Details' });
  };

  const addSop = () => {
    props.addSop();
  };

  const editSop = () => {
    props.editSop(selection);
  };

  if (!permissions.Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>SOP Records</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={sopColumns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <PrintsSearch2
            title="SOP Records"
            data={filteredRows}
            cols={sopColumns}
            kbdKey={kbdKey}
          />
          <Tooltip title="Add">
            <Fab className="ml-2" size="small" color="secondary" onClick={addSop}>
              <AddIcon />
            </Fab>
          </Tooltip>
          {selection !== null && (
            <>
            <Tooltip title="Edit">
              <Fab className="ml-2" size="small" color="secondary" onClick={editSop}>
                <EditIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Veiw details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3 
        name="sops"
        columns={sopColumns}
        setColumns={setSopColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showReport,
  addSop,
  editSop,
})(Sop);
