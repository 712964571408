import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import { showReport } from 'reducers/DialogsReducer';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchUnits } from 'reducers/SearchReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import PrintsSearch2 from '../components/PrintsSearch2';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import SearchHotKeys from 'Search/SearchHotKeys';
import SyncIcon from '@material-ui/icons/Sync';
import UnitSearchHotKeys from './UnitSearchHotKeys';
import { showAddUnit, showEditUnit } from 'reducers/DialogsReducer';
import EditIcon from '@material-ui/icons/Edit';
import { showAddAttachment } from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { setUnitStatus, unitInitEvent } from 'reducers/UnitStatusReducer';
import { newUnitStatus, showUnitAddResource } from 'reducers/DialogsReducer';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  fileInput: {
    display: 'none',
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'Mobile', headerName: 'Mobile', width: 150, hide: false },
  { field: 'Unit', headerName: 'Unit', width: 150, hide: false },
  { field: 'Names', headerName: 'Names', width: 150, hide: false },
  { field: 'Resources', headerName: 'Resources', width: 150, hide: false },
  { field: 'UnitStatus', headerName: 'Unit Status', width: 150, hide: false },
  { field: 'Occured', headerName: 'Occured', width: 150, hide: false },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150, hide: false },
  { field: 'Division', headerName: 'Division', width: 150, hide: false },
  { field: 'Zone', headerName: 'Zone', width: 150, hide: false },
  { field: 'Location', headerName: 'Location', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 140, hide: false },
];

function UnitEntries(props) {
  const classes = useStyles();
  const { filter, dataUpdate, options } = props;
  const { UnitActionCodes } = options;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [unitColumns, setUnitColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const kbdTimeoutRef = useRef(0);
  const permissions = getFullPermissions('cad', 'Units');

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  useEffect(() => {
    getAllUnits();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (dataUpdate && (dataUpdate.type === 'add-unit' || dataUpdate.type === 'edit-unit'))
      getAllUnits();
    // eslint-disable-next-line
  }, [dataUpdate]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        getAllUnits();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const getAllUnits = async () => {
    setLoaded(false);
    searchUnits()
      .then((result) => {
        const tabFiltered = tabFilter(result);
        const processed = processData(tabFiltered);
        setRows(processed);
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const tabFilter = (units) => {
    if (!filter) return units;
    return units.filter((unit) => unit.AgencyID === filter);
  };

  const processData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: row.ptsUnitID,
      };
    });
  };

  const viewDetails = () => {
    if (!selection) return;
    props.showReport({ id: 28, data: { ptsUnitID: selection }, title: 'Unit Details' });
  };

  const addUnit = () => props.showAddUnit();

  const editUnit = () => {
    let item = filteredRows.find((row) => row.id === selection);
    props.showEditUnit(item);
  };

  const addAttachment = () => {
    props.showAddAttachment({ id: selection, type: 'Unit' });
  };

  const addResources = () => {
    props.showUnitAddResource(selection);
  }

  const renderStatusMenu = () => {
    const viewStatus = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleUnitInitEvent = () => {
      props.unitInitEvent(selection);
      handleClose();
    };

    const handleAddNewStatus = () => {
      props.newUnitStatus(selection);
      handleClose();
    };

    const handleInService = async () => {
      await props.setUnitStatus(UnitActionCodes.Inservice.status, selection);
      getAllUnits();
      handleClose();
    };

    const handleOffDuty = () => {
      props.setUnitStatus(UnitActionCodes.Outservice.status, selection);
      getAllUnits();
      handleClose();
    };

    return (
      <div>
        <Tooltip title="View status">
          <Fab size="small" color="secondary" onClick={viewStatus}>
            <MoreVertIcon />
          </Fab>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <MenuItem onClick={handleUnitInitEvent}>Unit Initiated Event</MenuItem>
          <MenuItem onClick={handleAddNewStatus}>Add New</MenuItem>
          <MenuItem onClick={handleInService}>In Service</MenuItem>
          <MenuItem onClick={handleOffDuty}>Off Duty</MenuItem>
        </Menu>
      </div>
    );
  };

  if (!permissions.Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Units</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={unitColumns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Refresh focused grid">
            <Fab onClick={getAllUnits} size="small" color="secondary">
              <SyncIcon />
            </Fab>
          </Tooltip>
          {permissions.Create && (
            <Tooltip title="Create new unit">
              <Fab onClick={addUnit} size="small" color="secondary">
                <AddIcon />
              </Fab>
            </Tooltip>
          )}
          {selection !== null && permissions.Edit && (
            <Tooltip title="Edit unit">
              <Fab onClick={editUnit} size="small" color="secondary">
                <EditIcon />
              </Fab>
            </Tooltip>
          )}
          <PrintsSearch2
            title="Units Details"
            data={filteredRows}
            cols={unitColumns}
            kbdKey={kbdKey}
          />
          {selection !== null && (
            <>
              <Tooltip title="Veiw details">
                <Fab size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add attachment">
                <Fab color="secondary" size="small" onClick={addAttachment}>
                  <AttachFileIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add resource">
                <Fab color="secondary" size="small" onClick={addResources}>
                  <AssignmentIcon />
                </Fab>
              </Tooltip>
              {renderStatusMenu()}
            </>
          )}
        </div>
      </div>
      <XGrid3 
        name="units"
        columns={unitColumns}
        setColumns={setUnitColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys
        setKey={setKbdKey}
        allowedKeys={[
          'F2',
          'F3',
          'F4',
          'F5',
          'F7',
          'F9',
          'F11',
          'F12',
          'Ctrl+KeyP',
          'Ctrl+KeyL',
          'Ctrl+Alt+KeyN',
        ]}
      />
      {Boolean(selection) && <UnitSearchHotKeys ptsUnitID={selection} kbdKey={kbdKey} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate,
    options: state.config.options,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showReport,
  showAddUnit,
  showEditUnit,
  showAddAttachment,
  setUnitStatus,
  newUnitStatus,
  unitInitEvent,
  showUnitAddResource,
})(UnitEntries);
