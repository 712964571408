import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { showReport } from 'reducers/DialogsReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchCredentials } from 'reducers/SearchReducer';
import PrintsSearch2 from '../components/PrintsSearch2';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';

import SearchHotKeys from 'Search/SearchHotKeys';
import { showAddAttachment } from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import EditIcon from '@material-ui/icons/Edit';
import { showEditCredential } from 'reducers/DialogsReducer';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'PersonID', headerName: 'PersonID', width: 150, hide: false },
  { field: 'FullName', headerName: 'Full Name', width: 150, hide: false },
  { field: 'IDNumber', headerName: 'ID Number', width: 150, hide: false },
  { field: 'Type', headerName: 'Type', width: 150, hide: false },
  { field: 'IssuedBy', headerName: 'Issued By', width: 150, hide: false },
  { field: 'Description', headerName: 'Description', width: 150, hide: false },
  { field: 'Class', headerName: 'Class', width: 150, hide: false },
  { field: 'State', headerName: 'State', width: 150, hide: false },
  {
    field: 'IsCommercial',
    headerName: 'Is Commercial',
    width: 150,
    renderCell: bool,
    format: 'bool',
    hide: false,
  },
  {
    field: 'Issued',
    headerName: 'Issued',
    width: 150,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  {
    field: 'ExpirationDate',
    headerName: 'Expiration Date',
    width: 150,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'Age', headerName: 'Age', width: 150, hide: false },
  {
    field: 'BirthDate',
    headerName: 'Birth Date',
    width: 150,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'Race', headerName: 'Race', width: 150, hide: false },
  { field: 'Height', headerName: 'Height', width: 150, hide: false },
  { field: 'Weight', headerName: 'Weight', width: 150, hide: false },
  { field: 'FirstName', headerName: 'First Name', width: 150, hide: false },
  { field: 'LastName', headerName: 'Last Name', width: 150, hide: false },
  { field: 'NickName', headerName: 'Nick Name', width: 150, hide: false },
  {
    field: 'Dangerous',
    headerName: 'Dangerous',
    width: 150,
    renderCell: bool,
    format: 'bool',
    hide: false,
  },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];

function Credential(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [credentialColumns, setCredentialColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);
  const permissions = getFullPermissions('globals', 'People');

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === 'edit-credential') getCredentialData();
    // eslint-disable-next-line
  }, [dataUpdate]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        search();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const search = async () => {
    setLoaded(false);
    searchCredentials()
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const viewDetails = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({
      id: 45,
      data: { ptsPersonID: item.ptsPersonID },
      title: 'Credential Details',
    });
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPersonID } = item;
    props.showAddAttachment({ id: ptsPersonID, type: 'Person' });
  };

  const editCred = () => {
    let item = filteredRows.find((row) => row.id === selection);
    // console.log(item)
    props.showEditCredential(item);
  };

  if (!permissions.Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Credentials History</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={credentialColumns}
            setFilteredRows={setFilteredRows}
          />
        </div>
        <div className={classes.actions}>
          <PrintsSearch2
            title="Credential History"
            data={filteredRows}
            cols={credentialColumns}
            kbdKey={kbdKey}
          />
          {selection !== null && (
            <>
              {permissions.Edit && <Tooltip title="Edit Credential">
                <Fab className="ml-2" size="small" color="secondary" onClick={editCred}>
                  <EditIcon />
                </Fab>
              </Tooltip>}
              <Tooltip title="Veiw details">
                <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add attachment">
                <Fab className="ml-2" color="secondary" size="small" onClick={addAttachment}>
                  <AttachFileIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3 
        name="credentials"
        columns={credentialColumns}
        setColumns={setCredentialColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showReport,
  showAddAttachment,
  showEditCredential,
})(Credential);
