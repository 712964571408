import React from 'react';
import ReactDOM from 'react-dom';
import store from './config/configureStore';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const isProduction = process.env.NODE_ENV !== 'development';
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
if (isProduction && sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampler: context => {
      if (context.exception) return 1;
      return 0; // block all but exceptions
    },
  });
}

ReactDOM.render(
  <Provider store={store.store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
