import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from 'components/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import { getPeopleAddresses } from 'reducers/SearchReducer';
import PrintsSearch2 from 'Search/components/PrintsSearch2';
import AddressLookup from 'components/AddressLookup';
import Button from '@material-ui/core/Button';
import { savePersonAddress } from 'reducers/SearchReducer';
import Checkbox2 from 'components/Checkbox2';
import XGrid3 from 'components/XGrid3';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  fileInput: {
    display: 'none',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0 12px',
  },
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 8,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  addressWidget: {
    width: 550,
    display: 'inline-block',
  },
  address: {
    display: 'flex',
    margin: '24px 0 16px',
  },
  addressActions: {
    marginLeft: 8,
    '& button': {
      marginLeft: 8,
    },
    '& svg': {
      marginRight: 8
    },
  },
  // panel: {
  //   '& .MuiDataGridPanelFooter-root': {
  //     display: 'none',
  //   },
  // },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'AddressID', headerName: 'Address ID', width: 150, hide: false },
  { field: 'FullAddressText', headerName: 'Full Address', width: 200, hide: false },
  {
    field: 'IsPrimary',
    headerName: 'Is Primary',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Country',
    headerName: 'Country',
    width: 125,
    hide: false,
  },
  {
    field: 'State',
    headerName: 'State',
    width: 100,
    hide: false,
  },
  {
    field: 'StreetName',
    headerName: 'StreetName',
    width: 150,
    hide: false,
  },
  {
    field: 'BldgType',
    headerName: 'BldgType',
    width: 150,
    hide: false,
  },
  {
    field: 'BldgIdentifier',
    headerName: 'Bldg Identifier',
    width: 150,
    hide: false,
  },
  {
    field: 'AddressNumberPrefix',
    headerName: 'Prefix',
    width: 150,
    hide: false,
  },
  {
    field: 'AddressNumber',
    headerName: 'Number',
    width: 150,
    hide: false,
  },
  {
    field: 'AddressNumberSuffix',
    headerName: 'Suffix',
    width: 150,
    hide: false,
  },
  {
    field: 'PreDirection',
    headerName: 'Pre Direction',
    width: 150,
    hide: false,
  },
  {
    field: 'City',
    headerName: 'City',
    width: 150,
    hide: false,
  },
  {
    field: 'PostalCode',
    headerName: 'Postal Code',
    width: 150,
    hide: false,
  },
  {
    field: 'PostalCodeExtension',
    headerName: 'Extension',
    width: 150,
    hide: false,
  },
  {
    field: 'FloorType',
    headerName: 'Floor Type',
    width: 150,
    hide: false,
  },
  {
    field: 'FloorIdentifier',
    headerName: 'Floor Identifier',
    width: 150,
    hide: false,
  },
  {
    field: 'UnitType',
    headerName: 'Unit Type',
    width: 150,
    hide: false,
  },
  {
    field: 'Unit Identifier',
    headerName: 'Unit Identifier',
    width: 150,
    hide: false,
  },
];

function Addresses(props) {
  const { ptsPersonID, dataUpdate } = props;
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [editing, setEditing] = useState(false);
  const [address, setAddress] = useState(null);
  const [IsPrimary, setIsPrimary] = useState(false);
  const [primaryAvail, setPrimaryAvail] = useState(false);
  const mountedRef = useRef(true);

  useEffect(() => {
    ptsPersonID && getData();
  }, []);

  useEffect(() => {
    if (!dataUpdate || dataUpdate.type !== 'Address') return;
    getData();
    // eslint-disable-next-line
  }, [dataUpdate]);

  const getData = async () => {
    try {
      const data = await getPeopleAddresses(ptsPersonID);
      if (!mountedRef.current) return;
      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (data) => {
    return data.map((row) => {
      const City = row.Address[0]?.City?.CityDescription;
      return {
        id: row.ptsAddressID,
        IsPrimary: row.IsPrimary,
        ...row.Address[0],
        City,
      };
    });
  };

  const onAddClick = () => {
    setEditing(true);
    setAddress(null);
    const primaryAvail = rows.reduce((res, obj) => obj.IsPrimary ? false : res, true);
    setPrimaryAvail(primaryAvail);
    const IsPrimary = primaryAvail ? true : false;
    setIsPrimary(IsPrimary);
  }

  const onAddAddress = (address) => {
    setAddress(address);
  }

  const clearPlace = () => {
    setAddress(null);
  }

  const save = () => {
    const data = {
      ptsPersonID,
      ptsAddressID: address.ptsAddressID,
      IsPrimary,
    };
    savePersonAddress(data)
      .then(() => {
        getData();
        setAddress(false);
        setEditing(false);
      })
      .catch(props.handleError);
  }

  const renderActions = () => {
    return (
      <div className={classes.addressActions}>
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!address}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={() => setEditing(false)}>
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };

  const renderIsPrimary = () => {
    const onChange = () => setIsPrimary(!IsPrimary);
    return (
      <Checkbox2
        checked={IsPrimary}
        onChange={onChange}
        label="Is Primary"
        disabled={!primaryAvail}
      />
    )
  }

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        {editing && <>
          <div className={classes.form}>
            <AddressLookup
              className={classes.addressWidget}
              ptsPlaces={false}
              ptsAddresses={true}
              googleAddresses={false}
              onPlaceValueSet={onAddAddress}
              onAddressValueSet={onAddAddress}
              onReset={clearPlace}
              label="Search Address"
              compact
            />
            {renderIsPrimary()}
          </div>
          <div>
            {renderActions()}
          </div>
        </>}
        {!editing && <>
          <div className={classes.filterWrap}>
            <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
          </div>
          <div className={classes.actions}>
            <PrintsSearch2
              title="Person Addresses"
              data={filteredRows}
              cols={columns}
            />
            <Tooltip title="Veiw details">
              <Fab className="ml-2" size="small" color="secondary" onClick={onAddClick}>
                <AddIcon />
              </Fab>
            </Tooltip>
          </div>
        </>}
      </div>
      <XGrid3
        name="PeopleAddresses"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
}

export default connect(null, {
  handleError,
  showSpinner,
  hideSpinner
})(Addresses);