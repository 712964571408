import React from 'react';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';
import UnitStatusHistory from './UnitStatusHistory';

function UnitStatusSearch() {

  return (
    <SearchMenu>
      <FilterConsumer>
        {(AgencyFilter) => <UnitStatusHistory filter={AgencyFilter} />}
      </FilterConsumer>
    </SearchMenu>
  );
}

export default UnitStatusSearch;
