import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import ContainerCard from './ContainerCard';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: '0 0 8px 0',
  },
  actions: {
    padding: theme.spacing(1.5, 0),
    '& button': {
      marginLeft: theme.spacing(1.5),
    },
  },
}));

export default function NotesForm(props) {
  const classes = useStyles();
  const { updateData, title, no, data, add, clear, save } = props;
  const { ptsCommentID } = data;
  const [validForm, setValidForm] = useState(false);
  const inputRef = useRef(null);
  const Note = data.Comment;

  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current) inputRef.current.focus();
    }, 0);
    // eslint-disable-next-line
  }, []);

  const onNoteChange = (ev) => {
    const Comment = ev.target.value;
    updateData({ Comment, ptsCommentID }, no);
    if (validateForm() && !validForm) setValidForm(true);
    if (!validateForm() && validForm) setValidForm(false);
  }

  const validateForm = () => {
    if (!Note || Note.trim() === '') return false;
    return true;
  };

  const addNote = () => {
    if (inputRef?.current) inputRef.current.focus();
    add();
  }

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        {!!add && (
          <Fab color="secondary" aria-label="save" onClick={addNote} size="small" disabled={!Note}>
            <AddIcon />
          </Fab>
        )}
        {!!save && (
          <Fab
            color="secondary"
            aria-label="save"
            onClick={save}
            disabled={!validForm}
            size="small">
            <SaveIcon />
          </Fab>
        )}
        {!!clear && (
          <Fab color="secondary" aria-label="close" onClick={clear} size="small">
            <CloseIcon />
          </Fab>
        )}
      </div>
    );
  };

  return (
    <ContainerCard title={title} actions={renderActions()} className={classes.container}>
      <TextField
        className={classes.textField}
        label={title}
        multiline
        rows="3"
        fullWidth
        value={Note}
        onChange={onNoteChange}
        variant="outlined"
        size="small"
        inputRef={inputRef}
      />
    </ContainerCard>
  );
}
