import React, { useState, createContext } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import settings from '../../config/settings';
const FilterContext = createContext('Some value');
const FilterProvider = FilterContext.Provider;
export const FilterConsumer = FilterContext.Consumer;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: `calc(100vh - ${settings.mainNavHeight}px)`,
    height: 'calc(100vh - 54px)',
    paddingTop: theme.spacing(1),
  },
  panel: {
    height: '100%',
    overflow: 'auto',
    display: 'block',
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    padding: theme.spacing(3),
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function SearchMenu(props) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(null);
  const { dictionary, children } = props;

  const tabChange = (event, newValue) => setTabValue(newValue);

  if (!dictionary?.Agencies) return '';

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabValue}
        onChange={tabChange}
        className={classes.tabs}>
        <Tab label="Unassigned" value="Unassigned" />
        <Tab label="All" value={null} />
        {dictionary.Agencies.map((agency) => (
          <Tab key={agency.AgencyID} label={agency.AgencyID} value={agency.AgencyID} />
        ))}
      </Tabs>
      <div className={classes.panel}>
        <FilterProvider value={tabValue}>
          {children}
        </FilterProvider>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps)(SearchMenu);
