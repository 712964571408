import React from 'react';
import { getInitials } from 'utils/functions';
import icons from 'components/EventTypeIcons';

function getFontSize(Initials) {
  const len = Initials ? Initials.length : '2';
  switch (len) {
    case 2:
      return '1em';
    case 3:
      return '0.7em';
    case 1:
      return '1.2em';
    default:
      return '1em';
  }
}

function EventIcon(props) {
  const { evType, color } = props;
  if (!evType) return '';
  const { Code, Icon, Initials, Description } = evType;
  if (Icon) {
    const Ico = icons[Icon];
    if (Ico) return <Ico style={{ color: color }} />;
  }
  const fontSize = getFontSize(Initials);
  return <span style={{ color, fontSize }}>{Initials ? Initials : getInitials(Code, Description)}</span>;
}

export default EventIcon;
