import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setUnitStatus, unitInitEvent } from 'reducers/UnitStatusReducer';
import { newUnitStatus } from 'reducers/DialogsReducer';

function UnitHotKeys(props) {
  const { ptsUnitID, kbdKey, options, setUnitStatus, newUnitStatus, unitInitEvent } = props;
  const { UnitActionCodes } = options;

  useEffect(() => {
    if (kbdKey) handleKbdChange();
    // eslint-disable-next-line
  }, [kbdKey]);

  const handleKbdChange = () => {
    if (!ptsUnitID) return;
    switch (kbdKey) {
      case 'F2':
        setUnitStatus(UnitActionCodes.Dispatch.status, ptsUnitID);
        break;
      case 'F3':
        setUnitStatus(UnitActionCodes.Enroute.status, ptsUnitID);
        break;
      case 'F4':
        setUnitStatus(UnitActionCodes.Arrived.status, ptsUnitID);
        break;
      case 'F7':
        setUnitStatus(UnitActionCodes.Completed.status, ptsUnitID);
        break;
      case 'F11':
        setUnitStatus(UnitActionCodes.Inservice.status, ptsUnitID);
        break;
      case 'F12':
        setUnitStatus(UnitActionCodes.Outservice.status, ptsUnitID);
        break;
      case 'F9':
        newUnitStatus(ptsUnitID);
        break;
      case 'Ctrl+Alt+KeyN':
        unitInitEvent(ptsUnitID);
        break;
    }
  }

  return ''
}

const mapStateToProps = (state) => ({
  options: state.config.options,
})

export default connect(mapStateToProps, {
  setUnitStatus,
  newUnitStatus,
  unitInitEvent
})(UnitHotKeys);