export const SET_MAP = 'MAP/SET_MAP';
export const SET_MAP_CENTER = 'MAP/SET_MAP_CENTER';
export const SET_MAP_ZOOM = 'MAP/SET_MAP_ZOOM';
export const SET_MAP_BOUNDS = 'MAP/SET_MAP_BOUNDS';
export const SET_EVENT_INFOWINDOW = 'MAP/SET_EVENT_INFOWINDOW';
export const SET_EVENT_INFOWINDOW_TAB = 'MAP/SET_EVENT_INFOWINDOW_TAB';
export const SET_UNIT_INFOWINDOW = 'MAP/SET_UNIT_INFOWINDOW';
const MAP_ACTION = 'MAP_ACTION';

let validTimeout = 0;

export const mapAction = (data) => {
  return async (dispatch) => {
    clearTimeout(validTimeout);
    dispatch({ type: MAP_ACTION, payload: data });
    validTimeout = setTimeout(() => {
      dispatch({ type: MAP_ACTION, payload: null });
    }, 1000);
  };
};

export const setUnitInfowindow = (state, unit) => {
  return async (dispatch) => {
    dispatch({
      type: SET_EVENT_INFOWINDOW,
      payload: { open: false, data: null },
    });
    if (unit !== null) {
      if (unit.UnitLatitudeDegree !== null && unit.UnitLongitudeDegree !== null) {
        const latitude = `${unit.UnitLatitudeSign === '-' ? unit.UnitLatitudeSign : ''}${unit.UnitLatitudeDegree
          }`;
        const longitude = `${unit.UnitLongitudeSign === '-' ? unit.UnitLongitudeSign : ''}${unit.UnitLongitudeDegree
          }`;
        const position = {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        };
        dispatch({
          type: SET_UNIT_INFOWINDOW,
          payload: { open: state, data: unit },
        });
        dispatch({ type: SET_MAP_CENTER, payload: position });
        dispatch({ type: SET_MAP_ZOOM, payload: 15 });
      } else {
        dispatch({
          type: SET_UNIT_INFOWINDOW,
          payload: { open: state, data: unit },
        });
      }
    } else {
      dispatch({
        type: SET_UNIT_INFOWINDOW,
        payload: { open: state, data: unit },
      });
    }
  };
};

export default function reducer(
  state = {
    action: null,
    options: {
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDoubleClickZoom: true,
      mapTypeControlOptions: {
        style: 1, // window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR
        position: 11, // window.google.maps.ControlPosition.BOTTOM_CENTER
      },
      zoomControlOptions: {
        position: 11, // window.google.maps.ControlPosition.BOTTOM_CENTER
      },
    },
  },
  action
) {
  switch (action.type) {
    case MAP_ACTION:
      return { ...state, action: action.payload };
    default:
      break;
  }
  return state;
}
