import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { closeAddVehicle } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import { Button } from '@material-ui/core';
import { handleError } from 'reducers/ErrorReducer';
import FormDivider from 'components/FormDivider';
import Autocomplete2 from 'components/Autocomplete2';
import TextField2 from 'components/TextField2';
import Checkbox2 from 'components/Checkbox2';
import formStyles, { gridStyle, Row, RowInner, ColCard, Fills, validateVin, formatSaveData } from 'utils/formStyles';
import PersonLookup from 'components/PersonLookup';
import PlaceLookup from 'components/PlaceLookup';
import DatePicker2 from 'components/DatePicker2';
import { searchPlaceAddresses, addVehicle } from 'reducers/SearchReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import Dictionary from 'components/Dictionary';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(200, 320),
  field: gridStyle(210, 400),
}));

const PartyTypes = [{ Code: 'Person' }, { Code: 'Place' }];

function AddVehicleDialog(props) {
  const classes = useStyles();
  const { dictionary } = props;
  const {
    States, PlateTypes, PlateColors, PlateStatuses,
    PlateDetails, VehicleMakes, VehicleStyles, VehicleColors, VehicleClassifications,
    VehicleBrands, VehicleBranders, VehicleProperty, VehicleCommercial, GarageFacilities, Transmissions,
    Places, VehicleMakesModels,
  } = dictionary;

  // ptsVehicleReg table entries
  const [PartyType, setPartyType] = useState(null);
  const [partyTypePerson, setPartyTypePerson] = useState(null);
  const [partyTypePlace, setPartyTypePlace] = useState(null);
  const [ptsParentID, setParentID] = useState(null);
  const [PlateNumber, setPlateNumber] = useState('');
  const [PlateState, setPlateState] = useState(null);
  const [PlateType, setPlateType] = useState(null);
  const [PlateExpiration, setPlateExpiration] = useState(null);
  const [authority, setAuthority] = useState(null);
  const [authorityAddress, setAuthorityAddress] = useState(null);
  const [AuthorityAddressOptions, setAuthorityAddressOptions] = useState(null);
  const [PlateColor, setPlateColor] = useState(null);
  const [PlateBackgroundText, setPlateBackgroundText] = useState('');
  const [PlateStatus, setPlateStatus] = useState(null);
  const [DecalExpiration, setDecalExpiration] = useState(null);
  const [PlateDetail, setPlateDetail] = useState(null);
  const [TitleText, setTitleText] = useState('');
  const [InsuranceCompany, setRegInsuranceCompany] = useState('');
  const [VehicleValue, setVehicleValue] = useState('');
  const [OdometerReading, setOdometerReading] = useState('');
  const [GrossWeightMeasure, setGrossWeightMeasure] = useState('');
  const [IsPermanentReg, setIsPermanentReg] = useState(false);
  const [RoadIndicator, setRoadIndicator] = useState(false);
  const [IsLeased, setIsLeased] = useState(false);
  const [LesseeType, setLesseeType] = useState(null);
  const [LessorType, setLessor] = useState(null);
  const [lessorPerson, setLessorPerson] = useState(null);
  const [lessorPlace, setLessorPlace] = useState(null);
  const [lesseePerson, setLesseePerson] = useState(null);
  const [lesseePlace, setLesseePlace] = useState(null);
  const [ptsLesseeID, setPtsLesseeID] = useState(null);
  const [ptsLessorID, setLessorID] = useState(null);
  const [Year, setYear] = useState('');
  const [Make, setMake] = useState(null);
  const [Model, setModel] = useState(null);
  const [Style, setStyle] = useState(null);
  const [DoorQuantity, setDoorQuantity] = useState('');
  const [PassengeSafeQty, setPassengeSafeQty] = useState('');
  const [SeatQty, setSeatQty] = useState('');
  const [PrimaryColor, setPrimaryColor] = useState(null);
  const [SecondaryColor, setSecondaryColor] = useState(null);
  const [InteriorColor, setInteriorColor] = useState(null);
  const [Classification, setClassification] = useState(null);
  const [Description, setDescription] = useState('');
  const [Brand, setBrand] = useState(null);
  const [Brander, setBrander] = useState(null);
  const [PropertyID, setPropertyID] = useState('');
  const [VIN, setVIN] = useState('');
  const [VINAnalysisText, setVINAnalysisText] = useState('');
  const [CommercialID, setCommercialID] = useState('');
  const [EmissionInspectionQty, setEmissionInspectionQty] = useState('');
  const [Property, setProperty] = useState(null);
  const [Commercial, setCommercial] = useState(null);
  const [Transmission, setTransmission] = useState(null);
  const [IsCMVIndicator, setIsCMVIndicator] = useState(false);
  const [IsRented, setIsRented] = useState(false);
  const [IsWanted, setIsWanted] = useState(false);
  const [GarageIndicator, setGarageIndicator] = useState(false);
  const [GarageFacility, setGarageFacility] = useState(null);
  const [makesModelsOptions, setMakesModelsOptions] = useState(null);

  useEffect(() => {
    if (!authority) {
      setAuthorityAddressOptions([]);
      setAuthorityAddress(null);
    } else {
      getPlaceAddresses(authority.ptsPlaceID);
    }
  }, [authority]);

  const getPlaceAddresses = async (ptsPlaceID) => {
    const addresses = await searchPlaceAddresses(ptsPlaceID);
    setAuthorityAddressOptions(addresses);
    if (addresses.length === 1) {
      setAuthorityAddress(addresses[0]);
    } else {
      setAuthorityAddress(null);
    }
  }

  const close = () => {
    props.closeAddVehicle();
  };

  const save = async () => {
    const vehRegData = formatSaveData({
      PlateNumber,
      PlateState,
      PlateType,
      PlateExpiration,
      PlateColor,
      PlateBackgroundText,
      PlateDetail,
      PlateStatus,
      DecalExpiration,
      InsuranceCompany,
      OdometerReading,
      TitleText,
      VehicleValue,
      GrossWeightMeasure,
      IsLeased,
      IsPermanentReg,
      RoadIndicator,
      PartyType,
      ptsParentID,
      LesseeType,
      LessorType,
      ptsLesseeID,
      ptsLessorID,
      IsDeleted: 0,
    }, true);

    vehRegData.ptsPlateIssuingAuthorityID = authority ? authority.ptsPlateIssuingAuthorityID : null;
    vehRegData.ptsAddressID = authorityAddress ? authorityAddress.ptsAddressID : null;

    const vehicleData = formatSaveData({
      Year,
      Make,
      Model,
      Style,
      DoorQuantity,
      PassengeSafeQty,
      SeatQty,
      PrimaryColor,
      SecondaryColor,
      InteriorColor,
      Classification,
      Brand,
      Brander,
      PropertyID,
      VIN,
      VINAnalysisText,
      Commercial,
      CommercialID,
      Property,
      EmissionInspectionQty,
      Transmission,
      IsCMVIndicator,
      IsRented,
      IsWanted,
      GarageFacility,
      GarageIndicator,
      Description,
      IsTowed: 0,
      IsDeleted: 0,
    }, true);

    props.showSpinner();
    try {
      const data = await addVehicle({ vehicleData, vehRegData });
      const { ptsVehicleID } = data.vehicle;
      props.notifyDataUpdate({ type: 'add-vehicle', data: { ptsVehicleID } });
      props.closeAddVehicle();
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const handlePartyPersonChange = (person) => {
    setPartyTypePerson(person);
    setParentID(person?.ptsPersonID);
  }

  const handlePartyPlaceChange = (place) => {
    setPartyTypePlace(place);
    setParentID(place?.ptsPlaceID);
  }

  const renderPartyType = () => {
    const onChange = (ev, val) => setPartyType(val);
    return (
      <>
        <Autocomplete2
          options={PartyTypes}
          className={classes.item}
          onChange={onChange}
          value={PartyType}
          label="Type"
        />
        {PartyType?.Code === 'Person' && <PersonLookup
          className={classes.item}
          onPersonChange={handlePartyPersonChange}
          label="Party Person Search"
        />}
        {PartyType?.Code === 'Place' && <PlaceLookup
          onChange={handlePartyPlaceChange}
          className={classes.item}
          label="Search Party Place"
        />}
      </>
    );
  }

  const renderPlateNumber = () => {
    const onChange = (ev, val) => setPlateNumber(val);
    return (
      <TextField2
        className={classes.item}
        label="Plate Number"
        value={PlateNumber}
        onChange={onChange}
        max={25}
      />
    );
  }

  const renderPlateState = () => {
    const onChange = (ev, val) => setPlateState(val);
    return (
      <Dictionary
        options="States"
        className={classes.item}
        onChange={onChange}
        value={PlateState}
        label="Plate State"
      />
    );
  }

  const renderPlateType = () => {
    const onChange = (ev, val) => setPlateType(val);
    return (
      <Dictionary
        options="PlateTypes"
        className={classes.item}
        onChange={onChange}
        value={PlateType}
        label="Plate Type"
      />
    );
  }

  const renderPlateExpiration = () => {
    return (
      <DatePicker2
        onChange={(date) => setPlateExpiration(date)}
        label="Plate Expiration Date"
        value={PlateExpiration}
        className={classes.item}
      />
    )
  }

  const renderPlateIssuingAuthority = () => {
    const onChange = (place) => {
      setAuthority(place);
    }
    return (
      <PlaceLookup
        onChange={onChange}
        className={classes.item}
        label="Plate Issuing Authority"
      />
    );
  }

  const renderPlateIssuingAuthorityAddress = () => {
    const onChange = (ev, val) => setAuthorityAddress(val);
    return (
      <Autocomplete2
        options={AuthorityAddressOptions}
        className={classes.item}
        onChange={onChange}
        value={authorityAddress}
        label="Issuing Authority Address"
        getOptionLabel={(option) => option.FullAddressText || 'undefined'}
        getOptionSelected={(option, value) => option.ptsAddressID === value.ptsAddressID}
        renderOption={(option) => <span>{option.FullAddressText || 'undefined'}</span>}
      />
    );
  }

  const renderPlateColor = () => {
    const onChange = (ev, val) => setPlateColor(val);
    return (
      <Dictionary
        options="PlateColors"
        className={classes.item}
        onChange={onChange}
        value={PlateColor}
        label="Plate Color"
      />
    );
  }

  const renderPlateBgText = () => {
    const onChange = (ev, val) => setPlateBackgroundText(val);
    return (
      <TextField2
        className={classes.item}
        label="Plate Background Text"
        value={PlateBackgroundText}
        onChange={onChange}
        max={2000}
      />
    );
  }

  const renderPlateStatus = () => {
    const onChange = (ev, val) => setPlateStatus(val);
    return (
      <Dictionary
        options="PlateStatuses"
        className={classes.item}
        onChange={onChange}
        value={PlateStatus}
        label="Plate Status"
      />
    );
  }

  const renderDecalExpiration = () => {
    return (
      <DatePicker2
        onChange={(date) => setDecalExpiration(date)}
        label="Decal Expiration"
        value={DecalExpiration}
        className={classes.item}
      />
    )
  }

  const renderPlateDetails = () => {
    const onChange = (ev, val) => setPlateDetail(val);
    return (
      <Dictionary
        options="PlateDetails"
        className={classes.item}
        onChange={onChange}
        value={PlateDetail}
        label="Plate Detail"
      />
    );
  }

  const renderTitleText = () => {
    const onChange = (ev, val) => setTitleText(val);
    return (
      <TextField2
        className={classes.item}
        label="Title Text"
        value={TitleText}
        onChange={onChange}
        max={2000}
      />
    );
  }

  const renderInsuranceCompany = () => {
    const onChange = (ev, val) => setRegInsuranceCompany(val);
    return (
      <TextField2
        className={classes.item}
        label="Insurance Company"
        value={InsuranceCompany}
        onChange={onChange}
        max={300}
      />
    );
  }

  const renderVehicleValue = () => {
    const onChange = (ev, val) => setVehicleValue(val);
    return (
      <TextField2
        className={classes.item}
        label="Vehicle Value"
        value={VehicleValue}
        onChange={onChange}
        type="number"
        min={0}
        max={100000000000}
      />
    );
  }

  const renderOdometerReading = () => {
    const onChange = (ev, val) => setOdometerReading(val);
    return (
      <TextField2
        className={classes.item}
        label="Odometer Reading"
        value={OdometerReading}
        onChange={onChange}
        type="number"
        min={-900000000}
        max={900000000}
      />
    );
  }

  const renderGrossWeightMeasure = () => {
    const onChange = (ev, val) => setGrossWeightMeasure(val);
    return (
      <TextField2
        className={classes.item}
        label="Gross Weight Measure"
        value={GrossWeightMeasure}
        onChange={onChange}
        type="number"
        min={-90}
        max={90}
      />
    );
  }

  const renderPermanentReg = () => {
    const onChange = () => setIsPermanentReg(!IsPermanentReg);
    return (
      <Checkbox2
        checked={IsPermanentReg}
        onChange={onChange}
        label="Permanent Registration"
      />
    )
  }

  const renderRoadIndicator = () => {
    const onChange = () => setRoadIndicator(!RoadIndicator);
    return (
      <Checkbox2
        checked={RoadIndicator}
        onChange={onChange}
        label="Road Indicator"
      />
    )
  }

  const renderLeased = () => {
    const onChange = () => setIsLeased(!IsLeased);
    return (
      <Checkbox2
        checked={IsLeased}
        onChange={onChange}
        label="Leased"
      />
    )
  }

  const handleLesseePersonChange = (person) => {
    setLesseePerson(person);
    setPtsLesseeID(person?.ptsPersonID);
  }

  const handleLessePlaceChange = (place) => {
    setLesseePlace(place);
    setPtsLesseeID(place?.ptsPlaceID);
  }

  const renderLessee = () => {
    const onChange = (ev, val) => setLesseeType(val);
    return (
      <>
        <Autocomplete2
          options={PartyTypes}
          className={classes.item}
          onChange={onChange}
          value={LesseeType}
          label="Lessee"
        />
        {LesseeType?.Code === 'Person' && <PersonLookup
          className={classes.item}
          onPersonChange={handleLesseePersonChange}
          label="Lesse Person Search"
        />}
        {LesseeType?.Code === 'Place' && <PlaceLookup
          onChange={handleLessePlaceChange}
          className={classes.item}
          label="Search Lessee Place"
        />}
      </>
    );
  }

  const handleLessorPersonChange = (person) => {
    setLessorPerson(person);
    setLessorID(person?.ptsPersonID);
  }

  const handleLessorPlaceChange = (place) => {
    setLessorPlace(place);
    setLessorID(place?.ptsPlaceID);
  }

  const renderLessor = () => {
    const onChange = (ev, val) => setLessor(val);
    return (
      <>
        <Autocomplete2
          options={PartyTypes}
          className={classes.item}
          onChange={onChange}
          value={LessorType}
          label="Lessor"
        />
        {LessorType?.Code === 'Person' && <PersonLookup
          className={classes.item}
          onPersonChange={handleLessorPersonChange}
          label="Lessor Person Search"
        />}
        {LessorType?.Code === 'Place' && <PlaceLookup
          onChange={handleLessorPlaceChange}
          className={classes.item}
          label="Search Lessor Place"
        />}
      </>
    );
  }

  const renderYear = () => {
    const onChange = (ev, val) => setYear(val);
    // const error = !Year || Year > 1900 ? false : 'Not Valid';
    return (
      <TextField2
        className={classes.item}
        label="Year"
        value={Year}
        // error={error}
        onChange={onChange}
        type="number"
        min={0}
        max={2100}
      />
    );
  }

  const renderMake = () => {
    const onChange = (ev, val) => {
      setMake(val);
      setModel(null);
      if (val) {
        const makesModels = VehicleMakesModels
          .filter(mm => mm.MakeCode === val.Code)
          .map(m => {
            return { Code: m.ModelCode, Description: m.ModelDescription };
          });
        setMakesModelsOptions(makesModels);
      }
      else {
        setMakesModelsOptions(null);
      }
    }
    return (
      <Dictionary
        options="VehicleMakes"
        className={classes.item}
        onChange={onChange}
        value={Make}
        label="Make"
      />
    );
  }


  const renderModel = () => {
    const onChange = (ev, val) => setModel(val);
    return (
      <Autocomplete2
        options={makesModelsOptions}
        className={classes.item}
        onChange={onChange}
        value={Model}
        label="Model"
      />
    );
  }


  const renderStyle = () => {
    const onChange = (ev, val) => setStyle(val);
    return (
      <Dictionary
        options="VehicleStyles"
        className={classes.item}
        onChange={onChange}
        value={Style}
        label="Style"
      />
    );
  }

  const renderDoors = () => {
    const onChange = (ev, val) => setDoorQuantity(val);
    return (
      <TextField2
        className={classes.item}
        label="Doors"
        value={DoorQuantity}
        onChange={onChange}
        type="number"
        min={0}
        max={20}
      />
    );
  }

  const renderPassengeQuantity = () => {
    const onChange = (ev, val) => setPassengeSafeQty(val);
    return (
      <TextField2
        className={classes.item}
        label="Passenger Quantity"
        value={PassengeSafeQty}
        onChange={onChange}
        type="number"
        min={0}
        max={1000}
      />
    );
  }

  const renderSeatQuantity = () => {
    const onChange = (ev, val) => setSeatQty(val);
    return (
      <TextField2
        className={classes.item}
        label="Seat Quantity"
        value={SeatQty}
        onChange={onChange}
        type="number"
        min={0}
        max={1000}
      />
    );
  }

  const renderPrimaryColor = () => {
    const onChange = (ev, val) => setPrimaryColor(val);
    return (
      <Dictionary
        options="VehicleColors"
        className={classes.item}
        onChange={onChange}
        value={PrimaryColor}
        label="Primary Color"
      />
    );
  }

  const renderSecondaryColor = () => {
    const onChange = (ev, val) => setSecondaryColor(val);
    return (
      <Dictionary
        options="VehicleColors"
        className={classes.item}
        onChange={onChange}
        value={SecondaryColor}
        label="Secondary Color"
      />
    );
  }

  const renderInteriorColor = () => {
    const onChange = (ev, val) => setInteriorColor(val);
    return (
      <Dictionary
        options="VehicleColors"
        className={classes.item}
        onChange={onChange}
        value={InteriorColor}
        label="Interior Color"
      />
    );
  }

  const renderClassification = () => {
    const onChange = (ev, val) => setClassification(val);
    return (
      <Dictionary
        options="VehicleClassifications"
        className={classes.item}
        onChange={onChange}
        value={Classification}
        label="Classification"
      />
    );
  }

  const renderDescription = () => {
    const onChange = (ev, val) => setDescription(val);
    return (
      <TextField2
        className={classes.item}
        label="Description"
        value={Description}
        onChange={onChange}
        max={8000}
      />
    );
  }

  const renderBrand = () => {
    const onChange = (ev, val) => setBrand(val);
    return (
      <Dictionary
        options="VehicleBrands"
        className={classes.item}
        onChange={onChange}
        value={Brand}
        label="Brand"
      />
    );
  }

  const renderBrander = () => {
    const onChange = (ev, val) => setBrander(val);
    return (
      <Dictionary
        options="VehicleBranders"
        className={classes.item}
        onChange={onChange}
        value={Brander}
        label="Brander"
      />
    );
  }

  const renderVin = () => {
    const onChange = (ev, val) => setVIN(val.toUpperCase());
    const error = VIN.length > 0 && !validateVin(VIN);
    return (
      <TextField2
        className={classes.item}
        label="VIN"
        value={VIN}
        onChange={onChange}
        max={17}
        error={error}
      />
    );
  }

  const renderVinAnalysis = () => {
    const onChange = (ev, val) => setVINAnalysisText(val);
    return (
      <TextField2
        className={classes.item}
        label="VIN Analysis Test"
        value={VINAnalysisText}
        onChange={onChange}
        max={2000}
      />
    );
  }

  const renderPropertyID = () => {
    const onChange = (ev, val) => setPropertyID(val);
    return (
      <TextField2
        className={classes.item}
        label="Property ID"
        value={PropertyID}
        onChange={onChange}
        max={25}
      />
    );
  }

  const renderPropertyCode = () => {
    const onChange = (ev, val) => setProperty(val);
    return (
      <Dictionary
        options="VehicleProperty"
        className={classes.item}
        onChange={onChange}
        value={Property}
        label="Property Code"
      />
    );
  }

  const renderCommercialID = () => {
    const onChange = (ev, val) => setCommercialID(val);
    return (
      <TextField2
        className={classes.item}
        label="Commercial ID"
        value={CommercialID}
        onChange={onChange}
        max={25}
      />
    );
  }

  const renderCommercialCode = () => {
    const onChange = (ev, val) => setCommercial(val);
    return (
      <Autocomplete2
        options={VehicleCommercial}
        className={classes.item}
        onChange={onChange}
        value={Commercial}
        label="Commercial Code"
      />
    );
  }

  const renderEmissionInspection = () => {
    const onChange = (ev, val) => setEmissionInspectionQty(val);
    return (
      <TextField2
        className={classes.item}
        label="Emission Inspection"
        value={EmissionInspectionQty}
        onChange={onChange}
        type="number"
      />
    );
  }

  const renderTransmissionCode = () => {
    const onChange = (ev, val) => setTransmission(val);
    return (
      <Dictionary
        options="Transmissions"
        className={classes.item}
        onChange={onChange}
        value={Transmission}
        label="Transmission Code"
      />
    );
  }

  const renderCmv = () => {
    const onChange = () => setIsCMVIndicator(!IsCMVIndicator);
    return (
      <Checkbox2
        checked={IsCMVIndicator}
        onChange={onChange}
        label="CMV"
      />
    )
  }

  const renderRented = () => {
    const onChange = () => setIsRented(!IsRented);
    return (
      <Checkbox2
        checked={IsRented}
        onChange={onChange}
        label="Rented"
      />
    )
  }

  const renderWanted = () => {
    const onChange = () => setIsWanted(!IsWanted);
    return (
      <Checkbox2
        checked={IsWanted}
        onChange={onChange}
        label="Wanted"
      />
    )
  }

  const renderGarageIndicator = () => {
    const onChange = () => setGarageIndicator(!GarageIndicator);
    return (
      <Checkbox2
        checked={GarageIndicator}
        onChange={onChange}
        label="Garage Indicator"
      />
    )
  }

  const renderGarageFacility = () => {
    const onChange = (ev, val) => setGarageFacility(val);
    return (
      <Dictionary
        options="GarageFacilities"
        className={classes.item}
        onChange={onChange}
        value={GarageFacility}
        label="Garage Facility"
        disabled={!GarageIndicator}
      />
    );
  }

  return (
    <Dialog toolbar onClose={close} title="Add Vehicle" actions={renderActions()} fullScreen>
      <Row>
        <ColCard minWidth={500}>
          {/* <FormDivider title="Vehicles" />
          <RowInner>
            <TextField2 className={classes.item} label="Vehicle Lookap Placeholder" />
          </RowInner> */}
          <FormDivider title="Party" />
          <RowInner>
            {renderPartyType()}
            <Fills className={classes.item} />
          </RowInner>
          <FormDivider title="Plate" />
          <RowInner>
            {renderPlateNumber()}
            {renderPlateState()}
            {renderPlateType()}
            {renderPlateExpiration()}
            {renderPlateIssuingAuthority()}
            {renderPlateIssuingAuthorityAddress()}
            {renderPlateColor()}
            {renderPlateBgText()}
            {renderPlateStatus()}
            {renderDecalExpiration()}
            {renderPlateDetails()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <FormDivider title="Registration" />
          <RowInner>
            {renderTitleText()}
            {renderInsuranceCompany()}
            {renderVehicleValue()}
            {renderOdometerReading()}
            {renderGrossWeightMeasure()}
            {/* <Fills className={classes.item} /> */}
            {renderPermanentReg()}
            {renderRoadIndicator()}
            {renderLeased()}
            {renderLessee()}
            {renderLessor()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Details" />
          <RowInner>
            {renderYear()}
            {renderMake()}
            {renderModel()}
            {renderStyle()}
            {renderDoors()}
            {renderPassengeQuantity()}
            {renderSeatQuantity()}
            {renderPrimaryColor()}
            {renderSecondaryColor()}
            {renderInteriorColor()}
            {renderClassification()}
            {renderDescription()}
            {renderBrand()}
            {renderBrander()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <FormDivider title="Credentials and Analysis" />
          <RowInner>
            {renderVin()}
            {renderVinAnalysis()}
            {renderPropertyID()}
            {renderPropertyCode()}
            {renderCommercialID()}
            {renderCommercialCode()}
            {renderCmv()}
            {renderEmissionInspection()}
            {renderTransmissionCode()}
            <Fills className={classes.item} />
          </RowInner>
          <FormDivider title="Flags" />
          <RowInner>
            {renderRented()}
            {renderWanted()}
            {renderGarageIndicator()}
            {renderGarageFacility()}
          </RowInner>
        </ColCard>
      </Row>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(mapStateToProps, {
  closeAddVehicle, handleError, notifyDataUpdate, showSpinner, hideSpinner
})(AddVehicleDialog);
