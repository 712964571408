import { getService } from './service';

const GET_EVENT_SELECT_ITEMS = 'VFC/GET_EVENT_SELECT_ITEMS';
const GET_CASE_SELECT_ITEMS = 'VFC/GET_CASE_SELECT_ITEMS';
const GET_BOOKING_SELECT_ITEMS = 'VFC/GET_BOOKING_SELECT_ITEMS';
const GET_WARRANT_SELECT_ITEMS = 'VFC/GET_WARRANT_SELECT_ITEMS';

const GET_CASE = 'VFC/GET_CASE';
const GET_EVENT = 'VFC/GET_EVENT';
const GET_BOOKING = 'VFC/GET_BOOKING';
const GET_WARRANT = 'VFC/GET_WARRANT';

export const findEvents = async (data) => {
  const service = getService('archive-browser');
  const result = await service.find({
    query: { type: 'events', data }
  });
  return parseEvents(result);
}

const parseEvents = (data) => {
  return data.map((ev) => ({
    ...ev,
    // AddtlInfo: JSON.parse(ev.AddtlInfo),
    // MainInfo: JSON.parse(ev.MainInfo),
    // Notes: JSON.parse(ev.Notes),
    id: ev.vfcMainId
  }))
}

export const findCases = async (data) => {
  const service = getService('archive-browser');
  const result = await service.find({
    query: { type: 'cases', data }
  });
  return parseCases(result);
}

const parseCases = (data) => {
  return data.map((ev) => ({
    ...ev,
    //AddtlInfo: JSON.parse(ev.AddtlInfo),
    //MainInfo: JSON.parse(ev.MainInfo),
    id: ev.vfcMainId
  }))
}

export const findBookings = async (data) => {
  const service = getService('archive-browser');
  const result = await service.find({
    query: { type: 'bookings', data }
  });
  return parseBookings(result);
}

const parseBookings = (data) => {
  return data.map((ev) => ({
    ...ev,
    // AddtlInfo: JSON.parse(ev.AddtlInfo),
    // MainInfo: JSON.parse(ev.MainInfo),
    id: ev.vfcMainId
  }))
}

export const findWarrants = async (data) => {
  const service = getService('archive-browser');
  const result = await service.find({
    query: { type: 'warrants', data }
  });
  return parseWarrants(result);
}

const parseWarrants = (data) => {
  return data.map((ev) => ({
    ...ev,
    // AddtlInfo: JSON.parse(ev.AddtlInfo),
    // MainInfo: JSON.parse(ev.MainInfo),
    id: ev.vfcMainId
  }))
}

function formatEventsData(data) {
  data = data[0];

  // const out = { ...data };
  // out.AddtlInfo = data.AddtlInfo ? JSON.parse(data.AddtlInfo) : null;
  // out.MainInfo = data.MainInfo ? JSON.parse(data.MainInfo) : null;  
  // out.Notes = data.Notes ? JSON.parse(data.Notes) : null;  

  // console.log("===============data", data,"======================Original Events:",data.Notes, "-- Changed data:", out.Notes);

  return {
    ...data,
    AddtlInfo: data.AddtlInfo ? JSON.parse(data.AddtlInfo) : null,
    MainInfo: data.MainInfo ? JSON.parse(data.MainInfo) : null,
    Notes: data.Notes ? JSON.parse(data.Notes) : null
  }

}

function formatCasesData(data) {
  data = data[0];

  return {
    ...data,
    AddtlInfo: data.AddtlInfo ? JSON.parse(data.AddtlInfo) : null,
    MainInfo: data.MainInfo ? JSON.parse(data.MainInfo) : null
  }
}

// export const clearPrintData = () => {
//   return async dispatch => {
//     dispatch({ type: PRINT_DATA, payload: null });
//   };
// }

export const getBookingsSelectItems = () => {
  // const state = store.store.getState();
  //console.log("getBookingSelectItems");
  //console.log(state);
  //const client = state.websocket.websocket;
  return async dispatch => {
    //dispatch(showSpinner());
    try {
      //const service = client.service('archive-browser');
      const service = getService('archive-browser');
      service.timeout = 50000;
      const row = await service.get({ type: 'booking-select-items', data: '' });
      console.log("getBookingsSelectItems---", row);
      dispatch({ type: GET_BOOKING_SELECT_ITEMS, payload: row });
    } catch (error) {
      //dispatch(notify('Error, data not received # - booking-select-items', 'error'));
    }
    //dispatch(hideSpinner());
  };
};

export const getEventsSelectItems = () => {
  //const state = store.store.getState();  
  //console.log(state);
  //const client = state.websocket.websocket;
  return async dispatch => {
    //dispatch(showSpinner());
    try {
      const service = getService('archive-browser');
      console.log("getEventsSelectItems: ", service);
      //const service = client.service('archive-browser');
      service.timeout = 50000;
      console.log("events-select-items");
      const row = await service.get({ type: 'events-select-items', data: '' });

      console.log(row);

      dispatch({ type: GET_EVENT_SELECT_ITEMS, payload: row });
    } catch (error) {
      console.log("getEventsSelectItems error: ", error);
      //dispatch(notify('Error, data not received #EV2 - events-select-items', 'error'));
    }
    //dispatch(hideSpinner());
  };
};

export const getWarrantsSelectItems = () => {
  //const state = store.store.getState();  
  //console.log(state);
  //const client = state.websocket.websocket;
  return async dispatch => {
    //dispatch(showSpinner());
    try {
      const service = getService('archive-browser');
      console.log("getWarrantsSelectItems: ", service);
      //const service = client.service('archive-browser');
      service.timeout = 50000;
      console.log("warrants-select-items");
      const row = await service.get({ type: 'warrants-select-items', data: '' });

      console.log(row);

      dispatch({ type: GET_WARRANT_SELECT_ITEMS, payload: row });
    } catch (error) {
      console.log("getWarrantsSelectItems error: ", error);
      //dispatch(notify('Error, data not received #EV2 - events-select-items', 'error'));
    }
    //dispatch(hideSpinner());
  };
};

export const getCasesSelectItems = () => {
  //const state = store.store.getState();
  // console.log("getCaseSelectItems");
  // console.log(state);
  //const client = state.websocket.websocket;
  return async dispatch => {
    //dispatch(showSpinner());
    try {
      //const service = client.service('archive-browser');
      const service = getService('archive-browser');
      //service.timeout = 20000;
      const row = await service.get({ type: 'cases-select-items', data: '' });
      //console.log(row);
      dispatch({ type: GET_CASE_SELECT_ITEMS, payload: row });
    } catch (error) {
      //dispatch(notify('Error, data not received # - cases-select-items', 'error'));
    }
    //dispatch(hideSpinner());
  };
};

export const getCaseDetails = vfcMainId => {
  // const state = store.store.getState();
  // const client = state.websocket.websocket;
  return async dispatch => {
    //dispatch(showSpinner());
    try {
      //const service = client.service('archive-browser');
      const service = getService('archive-browser');
      //console.log("getCaseDetails", service);
      service.timeout = 20000;
      const row = await service.get({ type: 'cases', data: vfcMainId });
      dispatch({ type: GET_CASE, payload: row });
    } catch (error) {
      //dispatch(notify('Error, data not received #CA2', 'error'));
    }
    //dispatch(hideSpinner());
  };
};

export const getEventDetails = vfcMainId => {
  // const state = store.store.getState();
  //console.log("getEvent", vfcMainId);
  // const client = state.websocket.websocket;
  return async dispatch => {
    //dispatch(showSpinner());
    try {
      //const service = client.service('archive-browser');
      //console.log("=============service");
      const service = getService('archive-browser');
      //console.log("=============service", service);
      service.timeout = 20000;
      const row = await service.get({ type: 'events', data: vfcMainId });
      //console.log(row);

      dispatch({ type: GET_EVENT, payload: row });
    } catch (error) {
      //dispatch(notify('Error, data not received #EV2', 'error'));
      console.log(error);
    }
    //dispatch(hideSpinner());
  };
};

export const getBookingDetails = vfcMainId => {
  // const state = store.store.getState();
    // const client = state.websocket.websocket;
  return async dispatch => {
    //dispatch(showSpinner());
    try {
      //const service = client.service('archive-browser');
      //console.log("=============getBookingDetails service");
      const service = getService('archive-browser');
      //console.log("=============service", service);
      service.timeout = 20000;
      const row = await service.get({ type: 'bookings', data: vfcMainId });
      //console.log("getBookingDetails---", row);

      dispatch({ type: GET_BOOKING, payload: row });
    } catch (error) {
      //dispatch(notify('Error, data not received #EV2', 'error'));
      console.log(error);
    }
    //dispatch(hideSpinner());
  };
};

export const getWarrantDetails = vfcMainId => {
  // const state = store.store.getState();
    // const client = state.websocket.websocket;
  return async dispatch => {
    //dispatch(showSpinner());
    try {
      //const service = client.service('archive-browser');
      //console.log("=============getWarrantsDetails service");
      const service = getService('archive-browser');
      //console.log("=============service", service);
      service.timeout = 20000;
      const row = await service.get({ type: 'warrants', data: vfcMainId });
      //console.log("getWarrantsDetails---", row);

      dispatch({ type: GET_WARRANT, payload: row });
    } catch (error) {
      //dispatch(notify('Error, data not received #EV2', 'error'));
      console.log(error);
    }
    //dispatch(hideSpinner());
  };
};
// ======================= REDUCER =======================
const defaultState = {
  eventsSelectItems: {
    EventTypes: [{ code: '', description: '' }],
    EventDescription: [{ code: '', description: '' }],
    Zones: [{ code: '', description: '' }],
    Officers: [{ code: '', description: '' }],
    UnitVehicles: [{ code: '', description: '' }],
    CallerCity: [{ code: '', description: '' }],
    CallerZip: [{ code: '', description: '' }],
    SceneCity: [{ code: '', description: '' }],
    SceneZip: [{ code: '', description: '' }]
  },
  casesSelectItems: {
    Offense: [{ code: '', description: '' }],
    ReportingOfficer: [{ code: '', description: '' }],
    PartyType: [{ code: '', description: '' }],
    Race: [{ code: '', description: '' }],
    Sex: [{ code: '', description: '' }],
    DLStates: [{ code: '', description: '' }],
    VehicleMake: [{ code: '', description: '' }],
    VehicleModel: [{ code: '', description: '' }],
    LicenseState: [{ code: '', description: '' }],
    PropertyCode: [{ code: '', description: '' }]
  },
  bookingsSelectItems: {
    Race: [{ code: '', description: '' }],
    Sex: [{ code: '', description: '' }],
    IDState: [{ code: '', description: '' }],
    Offense: [{ code: '', description: '' }],
    ArrestCity: [{ code: '', description: '' }],
    ArrestingOfficer: [{ code: '', description: '' }],
    ArrestingAgency: [{ code: '', description: '' }],
    BookingOfficer: [{ code: '', description: '' }],
    ReleasingOfficer: [{ code: '', description: '' }],
    Disposition: [{ code: '', description: '' }]
  },
  warrantsSelectItems: {
    Race: [{ code: '', description: '' }],
    Sex: [{ code: '', description: '' }],
    ChargeDescription: [{ code: '', description: '' }]    
  },
  eventDetails: null,
  caseDetails: null,
  bookingDetails: null,
  warrantDetails: null
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_CASE_SELECT_ITEMS:
      //console.log("GET_CASE_SELECT_ITEMS", action.payload);
      return {
        ...state,
        casesSelectItems: action.payload
      };
    case GET_EVENT_SELECT_ITEMS:
      console.log("GET_EVENT_SELECT_ITEMS", action.payload);
      return {
        ...state,
        eventsSelectItems: action.payload
      };
    case GET_BOOKING_SELECT_ITEMS:
      console.log("GET_BOOKING_SELECT_ITEMS", action.payload);
      return {
        ...state,
        bookingsSelectItems: action.payload
      };
    case GET_WARRANT_SELECT_ITEMS:
      console.log("GET_WARRANT_SELECT_ITEMS", action.payload);
      return {
        ...state,
        warrantsSelectItems: action.payload
      };
    case GET_EVENT:
      return {
        ...state,
        eventDetails: formatEventsData(action.payload)
      };
    case GET_CASE:
      //console.log("GET_CASE ", action.payload);
      return {
        ...state,
        caseDetails: formatCasesData(action.payload)
      };
    case GET_BOOKING:
      //console.log("GET_BOOKING ", action.payload);
      return {
        ...state,
        bookingDetails: formatCasesData(action.payload)
      };
    case GET_WARRANT:
        //console.log("GET_WARRANT ", action.payload);
        return {
          ...state,
          warrantDetails: formatCasesData(action.payload)
        };
    default:
      return state;
  }
}
