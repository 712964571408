import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { showDisplayDataWindow } from 'reducers/DialogsReducer';
import { handleError } from 'reducers/ErrorReducer';
import PrintsSearch from 'Search/components/PrintsSearch';
import { XGrid } from '@material-ui/x-grid';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import InfoIcon from '@material-ui/icons/Info';
import { getCoordinates, geocodeAddress } from 'utils/mapFunctions';
import { getZoneMatch } from 'reducers/ZonesReducer';

const columns = [
  { field: 'ZoneCode', headerName: 'Zone', width: 150 },
  { field: 'Description', headerName: 'Description', width: 150 },
  { field: 'AgencyID', headerName: 'AgencyID', width: 150 },
];

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function AddressZones(props) {
  const { address } = props;
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [data, setData] = useState([]);
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => mountedRef.current = false;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!address?.ptsAddressID) return;
    getAddressZones();
    // eslint-disable-next-line
  }, [address]);

  const getAddressZones = async () => {
    const { ptsCoordinateID } = address;
    if (ptsCoordinateID) {
      try {
        const coords = await getCoordinates(ptsCoordinateID);
        if (!mountedRef.current) return;
        await getZonesForCoords(coords);
      } catch (err) {
        props.handleError(err, "Error requiring address coordinates");
      }
    } else {
      try {
        const coords = await geocodeAddress(address.FullAddressText);
        if (!mountedRef.current) return;
        if (coords) {
          await getZonesForCoords(coords);
        } else {
          props.notify('Error geocoding address: ' + address.FullAddressText);
        }
      } catch (err) {
        props.handleError(err, "Error geocoding address coordinates");
      }
    }
  }

  const getZonesForCoords = async (coords) => {
    try {
      const zones = await getZoneMatch(coords);
      if (!mountedRef.current) return;
      setData(zones.map(zone => {
        return { ...zone, id: zone.ZoneCode }
      }));
      setLoaded(true);
    } catch (err) {
      props.handleError(err, 'Error getting zone data');
    }
  }

  const viewDetails = () => {
    const item = data.find(row => row.id === selection);
    if (!item) return;
    props.showDisplayDataWindow({ data: filterData(item), title: `${item.ZoneCode} - ${item.Description}` });
  }

  const filterData = (rawData) => {
    const { ZoneCode, Description, AgencyID } = rawData;
    return { ZoneCode, Description, AgencyID };
  }

  return (
    <div className={classes.wrap}>
      <div className={classes.actions}>
        <PrintsSearch
          title="SOP Records"
          data={data}
          cols={columns}
          hiddenCols={hiddenColumns}
        />
        {selection !== null && (
          <Tooltip title="Veiw details">
            <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
              <InfoIcon />
            </Fab>
          </Tooltip>
        )}
      </div>
      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={data}
          loading={!loaded}
          rowHeight={38}
          disableMultipleSelection={true}
          showToolbar
          disableColumnFilter
          onSelectionModelChange={(newSelection) => {
            setSelection(newSelection.selectionModel[0]);
          }}
          onColumnVisibilityChange={(col) => setHiddenColumns([...hiddenColumns, col.field])}
        />
      </div>
    </div>
  );
}

export default connect(null, {
  handleError,
  showDisplayDataWindow,
})(AddressZones);
