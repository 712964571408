import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { makeStyles } from '@material-ui/core/styles';
import { closeAddressDialog } from 'reducers/DialogsReducer';
import { handleError } from 'reducers/ErrorReducer';
import { XGrid } from '@material-ui/x-grid';
import { showDisplayDataWindow } from 'reducers/DialogsReducer';
import PrintsSearch from 'Search/components/PrintsSearch';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AddressHistoryQuickFilterSearch from 'Search/components/AddressHistoryQuickFilterSearch';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  formControl: {
    marginLeft: theme.spacing(2),
    minWidth: 200,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  { field: 'EventID', headerName: 'Event ID', width: 150 },
  { field: 'CallType', headerName: 'Event Type', width: 130 },
  { field: 'Status', headerName: 'Status', width: 120 },
  { field: 'AgencyID', headerName: 'Agency ID', width: 160 },
  { field: 'CaseID', headerName: 'Case ID', width: 160 },
  {
    field: 'Received',
    headerName: 'Received',
    width: 180,
    valueFormatter: (params) => displayDateTime(params.value),
    format: "date"
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 180,
    valueFormatter: (params) => displayDateTime(params.value),
    format: "date"
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 120 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: "date"
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 120 },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 120,
    format: "bool",
    renderCell: bool
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 120 },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 120 },
];

function AddressHistory(props) {
  const classes = useStyles();
  const { data, loaded, filter } = props;
  const [rows, setRows] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [duration, setDuration] = useState("All");
  const [dayCount, setDayCount] = useState(0);
  const [weekCount, setWeekCount] = useState(0);
  const [monthCount, setMonthCount] = useState(0);
  const [moreMonthCount, setMoreMonthCount] = useState(0);
  const [allCount, setAllCount] = useState(0);

  useEffect(() => {
    setRows(data);
    setFilteredRows(filterRows(data))
    // eslint-disable-next-line
  }, [data, filter]);

  useEffect(() => {
    //render duration count list
    if (!data) return;
    let day = 0, week = 0, month = 0, moreMonth = 0;;
    // console.log(data);

    const dayStart = new Date();
    dayStart.setDate(dayStart.getDate() - 1);
    const weekStart = new Date();
    weekStart.setDate(weekStart.getDate() - 7);
    const monthStart = new Date();
    monthStart.setDate(monthStart.getDate() - 30);
    const endDate = new Date();

    data.forEach(row => {
      const curDate = new Date(row.Received);
      if (curDate > dayStart) day++;
      if (curDate > weekStart) week++;
      if (curDate > monthStart) month++;
      else moreMonth++;
    });
    setDayCount(day);
    setWeekCount(week);
    setMonthCount(month);
    setMoreMonthCount(moreMonth);
    setAllCount(data.length);
  }, [data]);

  const viewDetails = () => {
    const item = rows.find(row => row.id === selection);
    if (!item) return;
    props.showDisplayDataWindow({ data: filterData(item), title: `${item.EventID} - ${item.CallType}` });
  }

  const filterRows = (allRows) => {
    if (filter === 'filter-all') return allRows;
    if (filter === 'filter-unassigned') return allRows.filter(row => !row.AgencyIDs.length);
    return allRows.filter(row => row.AgencyIDs.indexOf(filter) !== -1);
  }

  const filterData = (rawData) => {
    const { EventID, CallType, Status, AgencyID, CaseID, Created, CreatedBy, Updated,
      UpdatedBy, IsDeleted, DeletedBy, DeletedIP } = rawData;
    return {
      EventID, CallType, Status, AgencyID, CaseID, Created, CreatedBy, Updated,
      UpdatedBy, IsDeleted, DeletedBy, DeletedIP
    };
  }

  const renderDurationFilter = () => {
    const handleChange = (event) => {
      setDuration(event.target.value);
    }
    return (
      <FormControl variant="outlined" size="small" className={classes.formControl}>
        <InputLabel>Duration</InputLabel>
        <Select
          value={duration}
          onChange={handleChange}
          label="Duration"
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Day">Last 24 hours</MenuItem>
          <MenuItem value="Week">Last 7 days</MenuItem>
          <MenuItem value="Month">Last 30 days</MenuItem>
          <MenuItem value="More-Month">More than 30 days</MenuItem>
        </Select>
      </FormControl>
    )
  }

  const renderDurationCountList = () => {
    return (
      <List dense >
        <ListItem button onClick={() => setDuration("All")}>
          <ListItemText primary={`All - ${allCount}`} />
        </ListItem>
        <ListItem button onClick={() => setDuration("Day")}>
          <ListItemText primary={`Last 24 hours - ${dayCount}`} />
        </ListItem>
        <ListItem button onClick={() => setDuration("Week")}>
          <ListItemText primary={`Last 7 days - ${weekCount}`} />
        </ListItem>
        <ListItem button onClick={() => setDuration("Month")}>
          <ListItemText primary={`Last 30 days - ${monthCount}`} />
        </ListItem>
        <ListItem button onClick={() => setDuration("More-Month")}>
          <ListItemText primary={`More than 30 days - ${moreMonthCount}`} />
        </ListItem>
      </List>
    )
  }

  return (
    <div>
      <div>
        {renderDurationCountList()}
      </div>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <AddressHistoryQuickFilterSearch
            rows={rows}
            columns={columns}
            setFilteredRows={setFilteredRows}
            duration={duration}
          />

          {renderDurationFilter()}
        </div>
        {/* <div className={classes.actions}>
          {renderDurationFilter()}
        </div> */}
        <div className={classes.actions}>
          <PrintsSearch
            title="SOP Records"
            data={filteredRows}
            cols={columns}
            hiddenCols={hiddenColumns}
          />
          {selection !== null && (
            <Tooltip title="Veiw details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
          )}
        </div>
      </div>
      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={filteredRows}
          loading={!loaded}
          rowHeight={38}
          disableMultipleSelection={true}
          showToolbar
          disableColumnFilter
          onSelectionModelChange={(newSelection) => {
            setSelection(parseInt(newSelection.selectionModel[0]));
          }}
          onColumnVisibilityChange={(col) => setHiddenColumns([...hiddenColumns, col.field])}
        />
      </div>
    </div>
  );
}

export default connect(null, {
  closeAddressDialog,
  handleError,
  showDisplayDataWindow,
})(AddressHistory);
