import React, { useState, useRef, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleMap, OverlayView } from '@react-google-maps/api';
import SearchPanel from './SearchPanel';
import { mapNight, mapDay } from '../../config/configureMap';
import { geocodeCoords } from '../../utils/mapFunctions';
import { handleError } from '../../reducers/ErrorReducer';

const useStyles = makeStyles((theme) => ({
  map: {
    height: 200,
  },
  marker: {
    position: 'sticky',
    minWidth: 20,
    height: 20,
    borderRadius: '50%',
    background: theme.palette.secondary.main,
    color: '#fff',
    textAlign: 'center',
    paddingTop: 3,
    boxSizing: 'border-box',
    border: '1px solid #000',
    cursor: 'pointer',
  },
  activeMarker: {
    position: 'sticky',
    minWidth: 22,
    height: 22,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: '#fff',
    textAlign: 'center',
    paddingTop: 4,
    boxSizing: 'border-box',
    border: '1px solid #000',
    cursor: 'pointer',
    transform: 'translate(-1px, -1px)',
  },
  addresses: {
    height: 75,
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      padding: theme.spacing(0.25, 2),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.grey4,
      },
    },
  },
}));

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const positionOverlayView = (width, height) => ({
  x: -width / 2,
  y: -height / 2,
});

const mapOptions = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
};

function RebidsMapPenel(props) {
  const classes = useStyles();
  const { CallerPhone, e911, update, themeMode, setLocation } = props;
  const [selected, setSelected] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const rebids = e911[CallerPhone];
  const mapRef = useRef();
  const zoomRef = useRef();
  mapOptions.styles = themeMode === 'day' ? mapDay : mapNight;
  const data = rebids?.data || [];

  useEffect(() => {
    if (!data.length) return;
    const record = data[data.length - 1];
    const lat = parseFloat(record.XCoordinate);
    const lng = parseFloat(record.YCoordinate);
    if (!lat || !lng) return;
    setMapCenter({ lat, lng });
    // eslint-disable-next-line
  }, [e911]);

  useEffect(() => {
    zoomRef.current = 14;
    // eslint-disable-next-line
  }, []);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    mapRef.current.setZoom(zoomRef.current);
  }, []);

  const MyMarker = (props) => {
    const { no } = props;
    return (
      <OverlayView
        {...props}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={positionOverlayView}>
        <div
          className={selected === no ? classes.activeMarker : classes.marker}
          onClick={props.onClick}>
          {no}
        </div>
      </OverlayView>
    );
  };

  const handleMarkerClick = (rebid) => {
    const { XCoordinate, YCoordinate } = rebid;
    const coords = { lat: parseFloat(XCoordinate), lng: parseFloat(YCoordinate) };
    update(rebid);
    setSelected(rebid.no);
    geocodeCoords(coords)
      .then((addresses) => {
        setAddresses(addresses);
        if (addresses.length) setLocation(addresses[0]);
      })
      .catch((err) => {
        props.handleError(err, 'Error, Problem with geocoding service');
      });
  };

  const renderRebid = (rebid) => {
    const { CallDateTime, XCoordinate, YCoordinate, no } = rebid;
    const lat = parseFloat(XCoordinate);
    const lng = parseFloat(YCoordinate);
    if (lat === NaN || lng === NaN) return '';
    return (
      <MyMarker
        key={CallDateTime}
        position={{ lat, lng }}
        no={no}
        onClick={() => handleMarkerClick(rebid)}
      />
    );
  };

  const renderRebids = () => {
    const rebids = data
      .map((rebid, idx) => {
        return { ...rebid, no: idx + 1 };
      })
      .filter((rebid) => rebid.XCoordinate);
    return rebids.map(renderRebid);
  };

  const renderMap = () => {
    return (
      <div className={classes.map}>
        {mapCenter !== null && (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={8}
            center={mapCenter}
            options={mapOptions}
            key={themeMode}
            onLoad={onMapLoad}
            className={classes.map}>
            {renderRebids()}
          </GoogleMap>
        )}
      </div>
    );
  };

  const renderAddresses = () => {
    return (
      <div className={classes.addresses}>
        {addresses.map((address) => (
          <div key={address.formattedAddress} onClick={() => setLocation({ ...address, IsPrimary: true })}>
            {address.formattedAddress}
          </div>
        ))}
      </div>
    );
  };

  return (
    <SearchPanel title="Rebids Map">
      {renderMap()}
      {renderAddresses()}
    </SearchPanel>
  );
}

const mapStateToProps = (state) => {
  return {
    e911: state.e911,
    themeMode: state.theme.mode,
    mapOptions: state.map.options,
  };
};

export default connect(mapStateToProps, { handleError })(RebidsMapPenel);
