import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { searchBulletins } from 'reducers/BulletinReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import SearchDatePicker from '../components/SearchDatePicker2';
import CheckIcon from '@material-ui/icons/Check';
import { addBulletin, editBulletin } from 'reducers/DialogsReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import PrintsSearch2 from '../components/PrintsSearch2';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { showReport } from 'reducers/DialogsReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import SearchHotKeys from 'Search/SearchHotKeys';
import XGrid3 from 'components/XGrid3';
import PrintSearch3 from 'Search/components/PrintSearch3';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  formControl: {
    marginLeft: theme.spacing(2),
    minWidth: 200,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const today = new Date();
const startDate = new Date();
startDate.setDate(startDate.getDate() - 1);

const defaultColumns = [
  { field: 'AlertType', headerName: 'Alert Type', width: 150, hide: false },
  { field: 'AlertTypeDescription', headerName: 'Description', width: 150, hide: false },
  { field: 'AgencyID', headerName: 'Agency ID', width: 120, hide: false },
  {
    field: 'Beginning',
    headerName: 'Beginning',
    width: 170,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  {
    field: 'Ending',
    headerName: 'Ending',
    width: 170,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'Notification', headerName: 'Notes', width: 150, hide: false },
  { field: 'Read', headerName: 'Read', width: 100, renderCell: bool, hide: false },
  {
    field: 'ReadDate',
    headerName: 'Read Date',
    width: 170,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'Priority', headerName: 'Priority', width: 150, hide: false },
  { field: 'AlertID', headerName: 'Alert ID', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 170,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'CreatedBy', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 170,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150, hide: false },
  { field: 'IsDeleted', headerName: 'Is Deleted', width: 150, renderCell: bool, hide: false },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 170,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'DeletedBy', width: 150, hide: false },
];

function BulletinSearchResults(props) {
  const classes = useStyles();
  const { filter, unreadBulletins, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filteredRows2, setFilteredRows2] = useState([]);
  const [dateFrom, setDateFrom] = useState(startDate);
  const [dateTo, setDateTo] = useState(today);
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [readFilter, setReadFilter] = useState('All');
  const [printData, setPrintData] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);
  const permissions = getFullPermissions('cad', 'Alerts');

  useEffect(() => {
    search();
  }, [filter, unreadBulletins]);

  useEffect(() => {
    if (!dataUpdate || dataUpdate.type !== 'Bulletin') return;
    search();
  }, [dataUpdate]);

  useEffect(() => {
    let filteredRows2;
    switch (readFilter) {
      case 'All':
        filteredRows2 = filteredRows;
        break;
      case 'Read':
        filteredRows2 = filteredRows.filter((row) => row.Read);
        break;
      default:
        filteredRows2 = filteredRows.filter((row) => !row.Read);
    }
    setFilteredRows2(filteredRows2);
    // eslint-disable-next-line
  }, [filteredRows, readFilter]);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  useEffect(() => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const printData = [{ name: 'Bulletin Details', id: 26, data: { ptsAlertID: item.ptsAlertID }, title: 'Bulletin Details' }];
    setPrintData(printData);
  }, [selection]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        search();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const processData = (data) => {
    return data.map((row) => {
      return { ...row, id: row.ptsAlertID, Read: row.ReadDate ? true : false };
    });
  };

  const search = async () => {
    setLoaded(false);
    searchBulletins(dateFrom, dateTo, filter)
      .then((result) => {
        const processed = processData(result);
        setRows(processed);
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const viewDetails = async () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 26, data: { ptsAlertID: item.ptsAlertID }, title: 'Bulletin Details' });
  };

  const add = () => props.addBulletin();

  const edit = () => props.editBulletin(selection);

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <PrintSearch3
          title="Events"
          data={filteredRows}
          cols={columns}
          kbdKey={kbdKey}
          printData={printData}
        />
        {permissions.Create && (
          <Fab onClick={add} size="small" color="secondary">
            <AddIcon />
          </Fab>
        )}
        {!!selection && (
          <Tooltip title="Edit Bulletin">
            <Fab onClick={edit} size="small" color="secondary">
              <EditIcon />
            </Fab>
          </Tooltip>
        )}
      </div>
    );
  };

  const renderReadSelectFilter = () => {
    const handleChange = (event) => {
      setReadFilter(event.target.value);
    };
    return (
      <FormControl variant="outlined" size="small" className={classes.formControl}>
        <InputLabel>Read filter</InputLabel>
        <Select value={readFilter} onChange={handleChange} label="Read filter">
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Read">Read</MenuItem>
          <MenuItem value="Unread">Unread</MenuItem>
        </Select>
      </FormControl>
    );
  };

  return (
    <div>
      <h5>Bulletin History</h5>
      <QuickFilterSearch
        rows={rows}
        setFilteredRows={setFilteredRows}
        columns={columns}
        readFilter={readFilter}
      />
      {renderReadSelectFilter()}
      <SearchDatePicker
        search={search}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        actions={renderActions()}
        kbdKey={kbdKey}
      />
      <XGrid3
        name="bulletin"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    addState: state.dialogs.addBulletin,
    bulletinDetails: state.dialogs.bulletinDetails,
    unreadBulletins: state.unreadBulletins,
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  addBulletin,
  editBulletin,
  showReport,
})(BulletinSearchResults);
