/**
 * Menu item configurations for desktop and mobile
 */

const menuSettings = [
  // {
  //   label: 'HOME',
  //   tooltip: 'Home page',
  //   link: '/home',
  //   icon: 'headset',
  // },
  // {
  //   label: 'MAP',
  //   tooltip: 'MAP',
  //   link: '/map',
  //   icon: 'map-pin',
  // },

  {
    label: 'Events',
    tooltip: 'CAD Events',
    link: '/events',
    icon: 'calendar-week',
  },
  {
    label: 'Cases',
    tooltip: 'Offense/Case',
    link: '/cases',
    icon: 'clipboard',
  },
  {
    label: 'Booking',
    tooltip: 'Booking',
    link: '/booking',
    icon: 'address-book',
  },
  {
    label: 'Warrants',
    tooltip: 'Warrants',
    link: '/Warrants',
    icon: 'swatchbook',
  },

];

export default menuSettings;
