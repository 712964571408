import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { IoMdAlert } from 'react-icons/io';
import { displayDateTime } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  alertIcon: {
    textAlign: 'center',
    display: 'block',
    '@media (max-width:413px)': {
      display: 'none !important'
    }
  },
  innerCard: {
    marginTop: 16,
    marginBottom: 16,
    background: theme.content.bg,
    fontSize: 12,
    borderRadius: 5,
    padding: 12
  },
  textColor: {
    color: theme.colors.text1
  },
  printStyle: {
    display: 'none',
  }
}));

function BulletinContent(props) {
  const classes = useStyles();
  const { data, setRef } = props;
  const {
    AlertType, AgencyID, Beginning, Ending, Notification, Priority,
    OLN, OLNState, Plate, PlateState, Ranges, Person, Vehicle,
  } = data;
  const printRef = useRef();

  useEffect(() => {
    if (!setRef) return;
    setRef(printRef);
    // eslint-disable-next-line
  }, []);

  const renderContent = () => {
    return (
      <>
        <div className="d-flex align-items-center mb-3">
          <div className={`d-block p-0 avatar-icon-wrapper m-2 d-100 ${classes.alertIcon}`}>
            <div className="rounded-circle overflow-hidden">
              <IoMdAlert size={35} color="#1E61A6" />
            </div>
          </div>
          <div className="w-100">
            <p className="font-weight-bold font-size-lg">
              {AlertType}
            </p>
            <span className={`${classes.textColor} d-block`}>
              {AgencyID}
            </span>
            <div className="pt-1">
              <div className="justify-content-between">
                <span>Begin:</span>
                <span className={`${classes.textColor} p-2`}>
                  {displayDateTime(Beginning)}
                </span>
              </div>
            </div>
            <div className="pt-1">
              <div className="justify-content-between">
                <span>End:</span>
                <span className={`${classes.textColor} p-2`}>
                  {displayDateTime(Ending)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <p
          className={`${classes.textColor} font-size-md mb-0`}
          style={{ minHeight: '25px' }}>
          {Notification}
        </p>
        <div className={classes.innerCard}>
          <div className="d-flex justify-content-between">
            <span className="font-weight-bold">Priority:</span>
            <span className={classes.textColor}>{Priority}</span>
          </div>
          <div className="d-flex justify-content-between py-2">
            <span className="font-weight-bold">Person:</span>
            <span className={classes.textColor}>{Person}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="font-weight-bold">Vehicle:</span>
            <span className={classes.textColor}>{Vehicle}</span>
          </div>
          <div className="d-flex justify-content-between py-2">
            <span className="font-weight-bold">OLN:</span>
            <span className={classes.textColor}>{OLN}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="font-weight-bold">OLN State</span>
            <span className={classes.textColor}>{OLNState}</span>
          </div>
          <div className="d-flex justify-content-between py-2">
            <span className="font-weight-bold">Plate:</span>
            <span className={classes.textColor}>{Plate}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="font-weight-bold">Plate State:</span>
            <span className={classes.textColor}>
              {PlateState}
            </span>
          </div>
          <div className="d-flex justify-content-between py-2">
            <span className="font-weight-bold">Ranges:</span>
            <span className={classes.textColor}>{Ranges}</span>
          </div>
        </div>
      </>
    )
  }

  const renderPrintContent = () => {
    return (
      <div className={classes.printStyle} ref={printRef}>
        <div className="d-flex align-items-center mb-3">
          <div className="w-100">
            <div className="font-weight-bold font-size-lg">
              <p>Bulletin Type: {AlertType} </p>
              <p>Agency ID: {AgencyID} </p>
            </div>
            <div className="pt-1">
              <p>
                <span>Begin: </span>
                <span className={`${classes.textColor} p-2`}>
                  {displayDateTime(Beginning)}
                </span>
              </p>
            </div>
            <div className="pt-1">
              <p>
                <span>End: </span>
                <span className={`${classes.textColor} p-2`}>
                  {displayDateTime(Ending)}
                </span>
              </p>
            </div>
          </div>
        </div>
        <p
          className={`${classes.textColor} font-size-md mb-0`}
          style={{ minHeight: '25px' }}>
          <span className="font-weight-bold">Notes: </span>
          <span className={classes.textColor}>{Notification}</span>
        </p>
        <p>
          <span className="font-weight-bold">Priority: </span>
          <span className={classes.textColor}>{Priority}</span>
        </p>
        <p>
          <span className="font-weight-bold">Person: </span>
          <span className={classes.textColor}>{Person}</span>
        </p>
        <p>
          <span className="font-weight-bold">Vehicle: </span>
          <span className={classes.textColor}>{Vehicle}</span>
        </p>
        <p>
          <span className="font-weight-bold">OLN: </span>
          <span className={classes.textColor}>{OLN}</span>
        </p>
        <p>
          <span className="font-weight-bold">OLN State: </span>
          <span className={classes.textColor}>{OLNState}</span>
        </p>
        <p>
          <span className="font-weight-bold">Plate: </span>
          <span className={classes.textColor}>{Plate}</span>
        </p>
        <p>
          <span className="font-weight-bold">Plate State: </span>
          <span className={classes.textColor}>
            {PlateState}
          </span>
        </p>
        <p>
          <span className="font-weight-bold">Ranges: </span>
          <span className={classes.textColor}>{Ranges}</span>
        </p>
      </div>
    );
  }

  return (
    <div >
      {renderContent()}
      {renderPrintContent()}
    </div>
  );
}

export default connect(null, {})(BulletinContent);
