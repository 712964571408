import { getService } from './service';
import { handleError } from './ErrorReducer';
import { getDictionaries } from './DictionaryReducer';
import store from 'config/configureStore';

export const DICTIONARY_PERMISSIONS = 'DICTIONARY_PERMISSIONS';
const PERMISSIONS = 'PERMISSIONS';

const getfalsePermissions = () => ({
  Create: false,
  Read: false,
  Edit: false,
  Delete: false,
  ViewDeleted: false
});

const defaultState = {
  dictionary: {},
  globals: {},
  modules: {},
  cad: {},
  default: getfalsePermissions()
};

const mergePermissions = (perm1, perm2) => {
  return {
    Create: perm1.Create || perm2.Create,
    Read: perm1.Read || perm2.Read,
    Edit: perm1.Edit || perm2.Edit,
    Delete: perm1.Delete || perm2.Delete,
    ViewDeleted: perm1.ViewDeleted || perm2.ViewDeleted
  }
}

export const getFullPermissions = (permGroup, permName, AgencyID = null) => {
  const state = store.store.getState();
  const p = state.permissions;
  if (!p) return getfalsePermissions();
  if (!AgencyID) {
    if (!p[permGroup]) return p.default ? p.default : getfalsePermissions();
    const permissions = p[permGroup][permName];
    if (permissions) return permissions;
    if (p.default) return p.default;
    return getfalsePermissions();
  } else {
    let permissions = p.default ? p.default : getfalsePermissions();
    if (p[permGroup]) {
      const perm2 = p[permGroup][permName];
      if (perm2) permissions = mergePermissions(permissions, perm2);
    }
    if (p.agencies[AgencyID] && p.agencies[AgencyID][permGroup]) {
      const perm2 = p.agencies[AgencyID][permGroup][permName];
      if (perm2) permissions = mergePermissions(permissions, perm2);
    }
    return permissions;
  }

};

export const getAccessPermission = (permGroup, permName, AgencyID = null) => {
  const state = store.store.getState();
  const p = state.permissions;
  if (!p[permGroup]) return false;
  if (!AgencyID) {
    const access = p[permGroup][permName];
    return Boolean(access);
  } else {
    let access = Boolean(p[permGroup][permName]);
    if (p.agencies[AgencyID] && p.agencies[AgencyID][permGroup]) {
      access = access || Boolean(p.agencies[AgencyID][permGroup][permName]);
    }
    return access;
  }
}

export const getGroups = () => {
  const state = store.store.getState();
  const p = state.permissions;
  if (!p.groups) return [];
  return p.groups.map(g => g.Label);
}

export const getProfile = () => {
  const state = store.store.getState();
  const p = state.permissions;
  if (!p.profile) return 'Unknown';
  return p.profile.Label;
}

let permissionsService;
export const subscribePermissions = () => {
  return async (dispatch) => {
    try {
      permissionsService = getService('permissions');
      permissionsService.on('updated', () => {
        dispatch(getUserPermissions());
        dispatch(getDictionaries());
      });
      permissionsService.on('error', () => console.log('Error in permissions service listener'));
      permissionsService.on('unhandledRejection', (reason, p) => {
        console.log('PermissionsReducer Unhandled Rejection at: Promise ', p, ' reason: ', reason);
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const unsubscribePermissions = () => (dispatch) => {
  if (permissionsService) {
    try {
      permissionsService.off('created');
      permissionsService.off('error');
      permissionsService.off('unhandledRejection');
      permissionsService = false;
    } catch (error) {
      console.log('PermissionsReducer/unsubscribePermissions: error: ', error, error.code);
    }
  }
};

export const notifyUpdatedPermissions = () => {
  const service = getService('permissions');
  return service.get(0);
}

export const getUserPermissions = () => async (dispatch) => {
  try {
    const service = getService('permissions');
    const permissions = await service.find({
      query: {
        options: ['cad', 'modules', 'default', 'globals', 'profile', 'groups', 'agencies']
      }
    });
    dispatch({ type: PERMISSIONS, payload: permissions });
  } catch (err) {
    dispatch(handleError(err));
  }
}

export const setDictionaryPermissions = (permissions) => (dispatch) => {
  dispatch({ type: DICTIONARY_PERM, payload: permissions });
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case DICTIONARY_PERMISSIONS:
      return { ...state, dictionary: { ...state.dictionary, ...action.payload } }
    case PERMISSIONS:
      return { ...state, ...action.payload }
    default:
      return state;
  }
}