import React, { useState } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeAddBulletin } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import DateFnsUtils from '@date-io/date-fns';
import { getFormStyle, getRowStyle, get100prStyle } from 'utils/functions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import PersonLookup from 'components/PersonLookup';
import { addAlert } from 'reducers/BulletinReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import VehicleLookup from 'components/VehicleLookup';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import {
  formatSaveDate,
  getFormat24,
  getDateTimeFormat,
  getCurrentDate,
} from 'reducers/TimeReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import Dictionary from 'components/Dictionary';

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    margin: '0 4px 8px',
  },
  option: {
    fontSize: 14,
  },
  row: getRowStyle(),
  w90: getFormStyle(90, 90),
  w100x200: getFormStyle(100, 200),
  w170x400: getFormStyle(170, 400),
  agency: getFormStyle(300, 600),
  date: getFormStyle(200, 300),
  w100pr: get100prStyle(),
  notes: {
    fontSize: 14,
  },
}));

const priorities = ['1', '2', '3', '4', '5'];

function AddBulletin(props) {
  const classes = useStyles();
  const { dictionary } = props;
  const [Beginning, setBeginning] = useState(getCurrentDate());
  const [Ending, setEnding] = useState(null);
  const [AlertType, setAlertType] = useState(null);
  const [Agency, setAgency] = useState(null);
  const [rangeOfEffect, setRangeOfEffect] = useState('');
  const [person, setPerson] = useState(null);
  const [Priority, setPriority] = useState('1');
  const [vehicle, setVehicle] = useState(null);
  const [Plate, setPlate] = useState('');
  const [PlateState, setPlateState] = useState(null);
  const [OLN, setOLN] = useState('');
  const [OLNState, setOLNState] = useState(null);
  const [Notification, setNotification] = useState('');
  const format24 = getFormat24();

  const close = () => {
    props.closeAddBulletin();
  };

  const save = async () => {
    const data = {
      AlertType: AlertType ? AlertType.Code : null,
      Beginning: formatSaveDate(Beginning),
      Ending: formatSaveDate(Ending),
      Notification,
      ptsPersonID: person ? person.ptsPersonID : null,
      ptsVehicleID: vehicle ? vehicle.ptsVehicleID : null,
      Plate,
      PlateState: PlateState ? PlateState.Code : null,
      OLN,
      OLNState: OLNState ? OLNState.Code : null,
      AgencyID: Agency ? Agency.AgencyID : null,
      Priority: Priority,
      RangeOfEffectiveness: rangeOfEffect,
    };
    props.showSpinner();
    try {
      const result = await addAlert(data);
      const { ptsAlertID } = result;
      props.notifyDataUpdate({ type: 'Bulletin', data: { ptsAlertID } });
      props.closeAddBulletin();
    } catch (err) {
      props.handleError(err, 'Error saving bulletin.');
    }
    props.hideSpinner();
  };

  const onVehicleSelect = (vehicle) => {
    setVehicle(vehicle);
    if (vehicle) {
      vehicle.PlateNumber && setPlate(vehicle.PlateNumber);
      vehicle.PlateState && setPlateState({ Code: vehicle.PlateState });
    }
  };

  const renderAlertTypes = () => {
    const onChange = (ev, val) => setAlertType(val);
    return (
      <Dictionary
        options="AlertTypes"
        className={classes.w100pr}
        onChange={onChange}
        value={AlertType}
        label="Bulletin type"
        compact
      />
    );
  };

  const renderAgencies = () => {
    const onChange = (ev, val) => setAgency(val);
    return (
      <Dictionary
        options="Agencies"
        className={classes.agency}
        onChange={onChange}
        value={Agency}
        label="Agency"
        compact
      />
    );
  };

  const renderPriority = () => {
    return (
      <Autocomplete
        options={priorities}
        classes={{
          option: classes.option,
          root: classes.autocomplete,
        }}
        className={classes.w90}
        autoHighlight
        onChange={(ev, newValue) => setPriority(newValue)}
        value={Priority}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Priority"
            variant="outlined"
            size="small"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
      />
    );
  };

  const renderDates = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          ampm={!format24}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format={getDateTimeFormat()}
          margin="normal"
          label="Date From"
          value={Beginning}
          onChange={setBeginning}
          maxDate={Ending}
          autoOk
          size="small"
          className={classes.date}
        />
        <KeyboardDateTimePicker
          ampm={!format24}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          margin="normal"
          label="Date To"
          value={Ending}
          onChange={setEnding}
          minDate={Beginning}
          autoOk
          size="small"
          className={classes.date}
          format={getDateTimeFormat()}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderRangeOfEffect = () => {
    const onChange = (ev) => setRangeOfEffect(ev.target.value.substr(0, 50));
    return (
      <TextField
        className={classes.w100pr}
        label="Range of Effectiveness"
        value={rangeOfEffect}
        onChange={onChange}
        variant="outlined"
        size="small"
      />
    );
  };

  const renderPlate = () => {
    const onChange = (ev) => setPlate(ev.target.value.substr(0, 25));
    return (
      <TextField
        className={classes.w170x400}
        label="Plate"
        value={Plate}
        onChange={onChange}
        variant="outlined"
        size="small"
      />
    );
  };

  const renderPlateState = () => {
    const onChange = (ev, val) => setPlateState(val);
    return (
      <Dictionary
        options="States"
        className={classes.w100x200}
        onChange={onChange}
        value={PlateState}
        label="Plate State"
        compact
      />
    );
  }

  const renderOLN = () => {
    const onChange = (ev) => setOLN(ev.target.value.substr(0, 50));
    return (
      <TextField
        className={classes.w170x400}
        label="OLN"
        value={OLN}
        onChange={onChange}
        variant="outlined"
        size="small"
      />
    );
  };

  const renderOlnState = () => {
    const onChange = (ev, val) => setOLNState(val);
    return (
      <Dictionary
        options="States"
        className={classes.w100x200}
        onChange={onChange}
        value={OLNState}
        label="OLN State"
        compact
      />
    );
  }

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Add Bulletin" actions={renderActions()}>
      <div className={classes.row}>{renderAlertTypes()}</div>
      <div className={classes.row}>
        {renderAgencies()}
        {renderPriority()}
      </div>
      <div className={classes.row}>{renderDates()}</div>
      <div className={classes.row}>{renderRangeOfEffect()}</div>
      <div className={classes.row}>
        <PersonLookup
          className={classes.w100pr}
          onPersonChange={(val) => setPerson(val)}
          compact
        />
      </div>
      <div className={classes.row}>
        <VehicleLookup
          className={classes.w100pr}
          onChange={onVehicleSelect}
          label="Search Vehicle"
        />
      </div>
      <div className={classes.row}>
        {renderPlate()}
        {renderPlateState()}
      </div>
      <div className={classes.row}>
        {renderOLN()}
        {renderOlnState()}
      </div>
      <div className={classes.row}>
        <TextField
          label="Notes"
          multiline
          rows={4}
          variant="outlined"
          className={classes.w100pr}
          value={Notification}
          onChange={(ev) => setNotification(ev.target.value)}
          inputProps={{ className: classes.notes }}
        />
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  closeAddBulletin,
  showSpinner,
  hideSpinner,
  handleError,
  notifyDataUpdate,
})(AddBulletin);
