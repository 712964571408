import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeTowingDialog } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import formStyles, { gridStyle, Row } from 'utils/formStyles';
import Checkbox2 from 'components/Checkbox2';
import PersonLookup from 'components/PersonLookup';
import { addVehicleTowing, updateVehicleTowing, getVehicleTowing } from 'reducers/VehicleReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import PlaceLookup from 'components/PlaceLookup';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  addressCategory: gridStyle(220, 220),
  item: gridStyle(200, 320),
  btn: {
    marginTop: theme.spacing(3),
    display: "block",
    marginRight: 0,
    marginLeft: "auto",
    '& svg': {
      marginRight: theme.spacing(1),
    },
  }
}));

function TowingDialog(props) {
  const classes = useStyles();
  const [IsTowed, setIsTowed] = useState(true);
  const [tower, setTower] = useState(null);
  const [driver, setDriver] = useState(null);
  const [ptsPersonID, setPtsPersonID] = useState(null);
  const { unitResources } = props;
  const { ptsVehicleID, ptsTowingID, ptsPlaceID, ptsUnitID } = props.data;
  const [changed, setChanged] = useState(false);
  const [unitPersons, setUnitPersons] = useState();
  const mountedRef = useRef(true);
  const valid = tower || ptsPlaceID;

  useEffect(() => {
    getTowingDetails();
    if (ptsUnitID) updateUnitsResources();
    return () => mountedRef.current = false;
    // eslint-disable-next-line
  }, []);

  const getTowingDetails = async () => {
    if (!ptsTowingID) return;
    props.showSpinner();
    try {
      const data = await getVehicleTowing(ptsTowingID);
      setPtsPersonID(data.ptsDriverID);
      setIsTowed(data.IsTowed);
    } catch (err) {
      props.handleError(err, 'Error loading towing data');
    }
    props.hideSpinner();
  }

  const updateUnitsResources = () => {
    const resources = unitResources[ptsUnitID];
    if (!resources || !resources.length) return;
    const persons = resources.filter(r => r.ParentType === 'Unit' && r.ChildType === 'Person')
      .map(r => {
        const names = r.ComponentName.split(' ').map(s => s.trim());
        const FirstName = names[0] ? names[0] : '';
        const LastName = names[1] ? names[1] : '';
        return {
          ptsPersonID: r.ptsChildID,
          FullName: r.ComponentName,
          FirstName,
          LastName,
          ActiveWarrant: null,
        }
      });
    setUnitPersons(persons);
  }

  const close = () => {
    props.closeTowingDialog();
  };

  const save = async () => {
    if (ptsTowingID) {
      const data = {
        ptsTowingID,
        IsTowed,
      };
      if (tower) data.ptsPlaceID = tower.ptsPlaceID;
      if (driver) data.ptsDriverID = driver.ptsPersonID;
      try {
        await updateVehicleTowing(data);
        props.closeTowingDialog();
      } catch (err) {
        props.handleError(err, 'Error adding towing to vehicle');
      }
    } else {
      const placeId = tower ? tower.ptsPlaceID : ptsPlaceID;
      try {
        const data = {
          ptsVehicleID,
          IsTowed,
          ptsPlaceID: placeId,
          ptsDriverID: driver?.ptsPersonID,
        };
        await addVehicleTowing(data);
        props.closeTowingDialog();
      } catch (err) {
        props.handleError(err, 'Error adding towing to vehicle');
      }
    }
  };

  const onTowingChange = (ev, val) => {
    setIsTowed(val);
    setChanged(true);
  }

  const onPlaceChange = (place) => {
    setTower(place);
    setChanged(true);
  }

  const onPersonChange = (person) => {
    setDriver(person);
    setChanged(true);
  }

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!valid}
        >
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Towing" actions={renderActions()}>
      <Row>
        <Checkbox2
          checked={IsTowed}
          onChange={onTowingChange}
          label="Towed"
        />
      </Row>
      <Row>
        <PlaceLookup
          onChange={onPlaceChange}
          className={classes.item}
          style={{ marginBottom: 16 }}
          ptsPlaceID={ptsPlaceID}
          label="Tower Reference"
        />
      </Row>
      <Row>
        <PersonLookup
          className={classes.item}
          onPersonChange={onPersonChange}
          label="Search Driver"
          ptsPersonID={ptsPersonID}
          defaultOptions={unitPersons}
        />
      </Row>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    unitResources: state.unitResources,
  }
}

export default connect(mapStateToProps, {
  closeTowingDialog,
  handleError,
  showSpinner,
  hideSpinner,
})(TowingDialog);
