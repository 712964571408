import store from '../config/configureStore';
import { hideSpinner } from './UiReducer';
import { notify, notifyPanel } from './NotifierReducer';
import { updateUnits } from './UnitsReducer';
import { handleError } from './ErrorReducer';
import { getService } from './service';
import settings from '../config/settings';
import { processSOPs } from './SopsReducer';
import { formatSaveDateFrom, formatSaveDateTo } from './TimeReducer';
import { addCoordsToLocation } from '../utils/mapFunctions';
import { getZoneMatch } from './ZonesReducer';
import { setUnitStatus } from './UnitStatusReducer';
import { removeClosedEventSort } from './EventSortingReducer';
import { notifyDataUpdate } from './DataUpdateReducer';
import { asyncForEach } from '../utils/functions';

const pushEvToLegacyActive = process.env.REACT_APP_PUSH_TO_LEGACY === 'true';
let eventUpdatesCounter = 0;
let eventsService = false;
export const SET_EVENTS = 'EVENTS/SET_EVENTS';
export const CLEAR_EVENTS = 'EVENTS/CLEAR_EVENTS';

export const addDispositions = (dispositions) => {
  return async (dispatch) => {
    try {
      const service = getService('event-dispositions');
      await service.create(dispositions);
      dispatch(updateEvents());
      dispatch(updateUnits());
      dispatch(notifyDataUpdate({ type: 'event', data: dispositions[0].ptsEventID }));
    } catch (error) {
      dispatch(handleError(error, 'Error, disposition not created'));
    }
  };
};

export const removeDisposition = async (ptsEventID, disposition) => {
  const { AgencyID, Disposition } = disposition;
  const service = getService('event-dispositions');
  await service.remove(ptsEventID, { query: { AgencyID, Disposition } });
};

const emptyDispositions = { reqDispAgencies: [], reqMergedDisp: [] };
export const getRequiredDispositions = (ptsEventID) => {
  const state = store.store.getState();
  const event = state.events.find((ev) => ev.ptsEventID === ptsEventID);
  if (!event || !event.UnitStatuses || !event.UnitStatuses.length) return { ...emptyDispositions };
  const { dictionary, config } = state;
  const { UnitStatuses } = event;
  const eventAgencies = UnitStatuses.reduce(
    (res, u) => (res.indexOf(u.AgencyID) === -1 ? [...res, u.AgencyID] : res),
    []
  );

  const currentDisp = event.dispositions.map((disp) => ({
    ...disp,
    IntCode: dictionary.Dispositions.find(
      (d) => d.AgencyID === disp.AgencyID && d.Code === disp.Disposition
    ).IntCode,
  }));

  const currentDispAgencies = currentDisp.map((a) => a.AgencyID);
  const reqDispAgencies = eventAgencies.reduce(
    (result, AgencyID) =>
      config.options.RequireDisposition[AgencyID] &&
        !currentDispAgencies.find((ID) => ID === AgencyID)
        ? [...result, AgencyID]
        : result,
    []
  );
  const reqMergedDispCode = eventAgencies.reduce((result, AgencyID) => {
    const req = config.options.RequireMergedDisposition[AgencyID];
    if (!req) return result;
    const met = Boolean(
      currentDisp.find(
        (disp) =>
          disp.AgencyID === AgencyID &&
          disp.IntCode === parseInt(config.options.DefaultMergedDispositionCode[AgencyID])
      )
    );
    if (met) return result;
    const IntCode = parseInt(config.options.DefaultMergedDispositionCode[AgencyID]);
    return [...result, { AgencyID, IntCode }];
  }, []);

  const reqMergedDisp = reqMergedDispCode.map((d) => ({
    ...d,
    ...dictionary.Dispositions.find(
      (disp) => disp.IntCode === parseInt(d.IntCode) && disp.AgencyID === d.AgencyID
    ),
  }));

  return {
    reqDispAgencies,
    reqMergedDisp,
  };
};

export const reinstateEvent = (ptsEventID) => async (dispatch) => {
  try {
    const service = getService('event-status-change');
    await service.update(ptsEventID, { ptsEventID, Status: 'Pending' });
    dispatch(updateEvents());
    dispatch(updateUnits());
    dispatch(notify('Event reinstated', 'success'));
    dispatch(notifyDataUpdate({ type: 'event' }));
  } catch (error) {
    dispatch(handleError(error));
  }
}

export const eventStatusChange = (ptsEventID, Status) => {
  getRequiredDispositions(ptsEventID);
  const state = store.store.getState();
  const { options, dictionary } = state.config;
  const event = state.events.find((ev) => ev.ptsEventID === ptsEventID);

  const findDispositionIntCode = (disposition) => {
    const { Disposition, AgencyID } = disposition;
    const obj = dictionary.Dispositions.find(
      (d) => d.AgencyID === AgencyID && d.Code === Disposition
    );
    return obj ? obj.IntCode : null;
  };

  const getDispositionsRequired = () => {
    // Dispositions Required
    const { UnitStatuses, dispositions } = event;
    if (!UnitStatuses || !UnitStatuses.length) return null;
    const currentDispAgencies = [];
    const requiredDispAgencies = [];
    const requiredDispositionsCodes = [];
    const currentDispositionCodes = [];

    const agencies = UnitStatuses.reduce(
      (res, u) => (res.indexOf(u.AgencyID) === -1 ? [...res, u.AgencyID] : res),
      []
    );

    dispositions &&
      dispositions.forEach((disposition) => {
        if (!currentDispAgencies.find((d) => d === disposition.AgencyID)) {
          currentDispAgencies.push(disposition.AgencyID);
        }
        const IntCode = findDispositionIntCode(disposition);
        if (!currentDispositionCodes.find((d) => d === IntCode)) {
          currentDispositionCodes.push(IntCode);
        }
      });

    agencies.forEach((AgencyID) => {
      // Require disposition
      if (
        options.RequireDisposition[AgencyID] &&
        !currentDispAgencies.find((d) => d === AgencyID)
      ) {
        requiredDispAgencies.push(AgencyID);
      }
      // Require Merged dispositions
      const IntCode = parseInt(options.DefaultMergedDispositionCode[AgencyID]);
      if (
        options.RequireMergedDisposition[AgencyID] &&
        !currentDispositionCodes.find((c) => c === IntCode)
      ) {
        requiredDispositionsCodes.push(IntCode);
      }
    });

    // Check if merged dispositions are beign met
    if (requiredDispositionsCodes.length) {
      return (
        'The following dispositions are missing: ' +
        requiredDispositionsCodes.reduce((result, value, idx) => {
          if (idx) result += ', ';
          const disposition = dictionary.Dispositions.find((d) => d.IntCode === value);
          return `${result} ${disposition.Description} (${disposition.AgencyID})`;
        }, '')
      );
    }

    // Check if Require dispositions are beign met
    if (requiredDispAgencies.length) {
      return (
        'A disposition is required by the following: ' +
        requiredDispAgencies.reduce((result, value, idx) => {
          if (idx) result += ', ';
          return result + value;
        }, '')
      );
    }

    return false;
  };

  return async (dispatch) => {
    const dispositionRequired = getDispositionsRequired();

    if (Status === 'Closed' && dispositionRequired) {
      const { EventID } = event;
      const notification = {
        title: EventID,
        message: dispositionRequired,
      };
      dispatch(notifyPanel(notification, 'error'));
    } else {
      try {
        const service = getService('event-status-change');
        await service.update(ptsEventID, { ptsEventID, Status });
        if (pushEvToLegacyActive) {
          dispatch(pushEvToLegacyRMS(ptsEventID));
        }
      } catch (error) {
        dispatch(handleError(error));
      }
    }
  };
};

export const pushEvToLegacyRMS = (ptsEventID, informSuccess = false) => async (dispatch) => {
  try {
    const event = await getCmplxEvent(ptsEventID);
    if (!event) throw new Error('Error: Cannot push event to legacy DB');
    const { CaseIds } = event;
    if (!CaseIds) {
      if (informSuccess) dispatch(notify('No Case Id\'s found. Event will not be pushed to legacy RMS', 'warning'));
      return;
    };
    const ids = JSON.parse(CaseIds).map(c => c.AgencyId);
    if (!ids || !ids.length) {
      if (informSuccess) dispatch(notify('No Case Id\'s found. Event will not be pushed to legacy RMS', 'warning'));
      return;
    };
    const duplicates = ids.filter((i, x) => ids.indexOf(i) !== x);
    if (duplicates.length) {
      dispatch(notify('Multiple Case ID\'s, event will not be pushed to legacy RMS', 'warning'));
      return;
    }
    const alreadyPushed = await isEventPushedToLegacyRMS(ptsEventID);
    let pushEvent = false;
    if (alreadyPushed) {
      if (window.confirm('Event already pused to legacy RMS. Do you want to send it again?')) {
        await removeEventFromLegacyRMS(ptsEventID);
        pushEvent = true;
      }
    } else {
      pushEvent = true;
    }
    if (pushEvent) {
      await pushEventToLegacyRMS(ptsEventID)
      if (informSuccess) dispatch(notify('Push to legacy RMS complete', 'success'));
    }
  } catch (err) {
    dispatch(handleError(err));
  }
}

export const updateEvents = () => async (dispatch) => {
  if (settings.synchronizeData) {
    dispatch(getEvents());
  } else {
    dispatch(hideSpinner());
  }
};

export const getEventDuplicates = (data) => {
  const service = getService('cad-event-duplicates');
  return service.find({
    query: { ...data },
  });
};

let getEventsTimeout = 0;
export const getEvents = () => {
  return async (dispatch) => {
    clearTimeout(getEventsTimeout);
    getEventsTimeout = setTimeout(() => {
      const time = new Date().getTime();
      const service = getService('cmplx-events');
      service
        .find()
        .then((events) => {
          processQueuedUnits(events, dispatch);
          dispatch({ type: SET_EVENTS, events: processEvents(events) });
          console.log('get events: ', new Date().getTime() - time);
        })
        .catch((error) => dispatch(handleError(error)))
        .finally(() => dispatch(hideSpinner()));
    }, settings.reqThrottlingTime);
  };
};

export const newEvent = (data) => {
  const service = getService();
  return service.create({ type: 'new-event', data });
};

export const pushEventToLegacyRMS = (ptsEventID) => {
  const service = getService('event-push-to-legacy');
  return service.create({ ptsEventID });
};

export const isEventPushedToLegacyRMS = (ptsEventID) => {
  const service = getService('event-push-to-legacy');
  return service.get(ptsEventID);
};

export const removeEventFromLegacyRMS = (ptsEventID) => {
  const service = getService('event-push-to-legacy');
  return service.remove(ptsEventID);
};

export const newCaseID = (ptsEventID, AgencyID) => {
  const data = { ptsEventID, AgencyID };
  return async (dispatch) => {
    try {
      const service = getService();
      const result = await service.create({ type: 'add-caseid', data });
      if (result) {
        dispatch(notify('New Case ID created', 'success'));
        dispatch(notifyDataUpdate({ type: 'event', data: ptsEventID }));
      } else {
        dispatch(notify('Case ID not created, check agency specific settings', 'warning'));
      }
      dispatch(updateEvents());
    } catch (error) {
      dispatch(handleError(error, 'Error, Case ID not created'));
    }
  };
};

export const getEventVehicles = (ptsEventID) => {
  const service = getService('cad-event-vehicles');
  return service.find({
    query: { ptsEventID },
  });
};

export const addVehicleToEvent = (ptsEventID, ptsVehicleID) => {
  const service = getService('cad-event-vehicles');
  return service.create({ ptsEventID, ptsVehicleID });
};

export const removeVehicleFromEvent = (ptsEventID, ptsVehicleID) => {
  const service = getService('cad-event-vehicles');
  return service.patch(ptsEventID, { ptsEventID, ptsVehicleID });
};

export const subscribeEvents = () => {
  const state = store.store.getState();
  const authenticated = state.user.isAuthenticated;
  const client = state.websocket.websocket;
  return async (dispatch) => {
    if (!client || !authenticated) return;
    dispatch(getEvents());
    try {
      eventsService = client.service('cmplx-events');
      eventsService.on('newEventsData', (events) => {
        processQueuedUnits(events, dispatch);
        removeClosedEventSort(events, dispatch);
        dispatch({ type: SET_EVENTS, events: processEvents(events) });
      });
      eventsService.on('eventChange', (event) => {
        //if data in not sysnc
        if (event.eventUpdatesCounter !== eventUpdatesCounter + 1) {
          eventUpdatesCounter = event.eventUpdatesCounter;
          dispatch(getEvents());
          return;
        }
        //if data in sync

        eventUpdatesCounter = event.eventUpdatesCounter;
        const action = event.eventActionType;
        if (action !== 'modify' && action !== 'remove') return;
        const state = store.store.getState();
        const newEvents = [...state.events.filter((e) => e.ptsEventID !== event.ptsEventID)];
        if (action === 'modify' && event.ptsEventID) {
          const processedEvents = processEvents([event]);
          newEvents.push(processedEvents[0]);
        }
        processQueuedUnits(newEvents, dispatch);
        removeClosedEventSort(newEvents, dispatch);
        dispatch({ type: SET_EVENTS, events: newEvents });
      });
      eventsService.on('unhandledRejection', (reason, p) => {
        console.log('EventReducer Unhandled Rejection at: Promise ', p, ' reason: ', reason);
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const unsubscribeEvents = () => {
  if (eventsService) {
    try {
      eventsService.off('newEventsData');
      eventsService.off('eventChange');
      eventsService = false;
    } catch (error) {
      console.log('EventsReducer/unsubscribeEvents: error: ', error, error.code);
    }
  }
  return () => { };
};

export const clearEvents = () => (dispatch) => {
  dispatch({ type: CLEAR_EVENTS });
};

export const getCmplxEvent = async (ptsEventID) => {
  const service = getService('cmplx-events');
  return await service.get(ptsEventID);
};

export const copyEvent = async (ptsEventID) => {
  if (!window.confirm('Are you sure you want to copy this event?')) return;
  const event = await getEventData(ptsEventID);

  const { CallType, CallSubType, CallMethod, RequestedAction, Description, lat, lng } = event.Event;
  const Event = { CallType, CallSubType, CallMethod, RequestedAction, Description, lat, lng };

  const Locations = [];
  await asyncForEach(event.Locations, async (location) => {
    const {
      AddressNumber,
      PreDirection,
      StreetName,
      StreetType,
      PostDirection,
      UnitIdentifier,
      Notes,
      UnitType,
      ptsCityID,
      State,
      PostalCode,
      PostalCodeExtension,
      County,
      IsPrimary,
      ptsPlaceID,
    } = location;
    const Location = await addCoordsToLocation({
      AddressNumber,
      PreDirection,
      StreetName,
      StreetType,
      PostDirection,
      UnitIdentifier,
      Notes,
      UnitType,
      ptsCityID,
      State,
      PostalCode,
      PostalCodeExtension,
      County,
      IsPrimary,
      ptsPlaceID,
    });
    Locations.push(Location);
  });

  const Callers = event.Callers.map((caller) => {
    const {
      ptsCallerID,
      AdvisoryText,
      CallerLocation,
      FirstName,
      MiddleName,
      LastName,
      Info,
      Prefix,
      Suffix,
      FullName,
      AreaCode,
      Number,
      Extension,
    } = caller;
    return {
      ptsCallerID,
      AdvisoryText,
      CallerLocation,
      FirstName,
      MiddleName,
      LastName,
      Info,
      Prefix,
      Suffix,
      FullName,
      AreaCode,
      Number,
      Extension,
    };
  });

  const Notes = event.Notes.map((note) => ({ Comment: note.Comment }));
  const data = { Event, Locations, Callers, Notes };

  const newPtsEventID = await newEvent(data);
  return await getEvent(newPtsEventID);
};

// ===========  REDUCERS  ======================

export default function reducer(state = [], action) {
  switch (action.type) {
    case SET_EVENTS:
      return action.events;
    case CLEAR_EVENTS:
      return [];
    default:
      break;
  }
  return state;
}

// ===============  HELPER fUNCTIONS  ==============

function processEvents(events) {

  const newEvents = events.map((event) => {
    const { CaseIds, Attachments, EventRouting, UnitStatuses } = event;
    const CaseIdsStr = CaseIds && CaseIds.length ? JSON.parse(CaseIds) : '';
    const UnitStatusesStr = UnitStatuses && UnitStatuses.length ? JSON.parse(UnitStatuses) : '';
    const AttachmentsStr = Attachments && Attachments.length ? JSON.parse(Attachments) : '';
    const EventRoutingStr = EventRouting && EventRouting.length ? JSON.parse(EventRouting) : '';

    const newEvent = {
      ...event,
      CaseIds: CaseIdsStr,
      UnitStatuses: UnitStatusesStr,
      attachments: AttachmentsStr,
      EventRouting: EventRoutingStr,
    };

    const {
      ArrivedDate,
      CancelledDate,
      CompletedDate,
      DispatchedDate,
      EnrouteDate,
      QueuedDate,
      ReceiveDate,
      LatitudeSign,
      LatitudeDegree,
      LongitudeSign,
      LongitudeDegree,
    } = newEvent;

    const coordinates =
      (LatitudeSign === '-' ? '-' : '') +
      LatitudeDegree +
      ', ' +
      (LongitudeSign === '-' ? '-' : '') +
      LongitudeDegree;
    let EventChangeDate = null;

    if (CancelledDate) {
      EventChangeDate = CancelledDate;
    } else if (CompletedDate) {
      EventChangeDate = CompletedDate;
    } else if (ArrivedDate) {
      EventChangeDate = ArrivedDate;
    } else if (EnrouteDate) {
      EventChangeDate = EnrouteDate;
    } else if (DispatchedDate) {
      EventChangeDate = DispatchedDate;
    } else if (QueuedDate) {
      EventChangeDate = QueuedDate;
    } else if (ReceiveDate) {
      EventChangeDate = ReceiveDate;
    }
    newEvent.EventChangeDate = EventChangeDate;
    newEvent.sops = processSOPs(newEvent);
    newEvent.coordinates = coordinates;

    return newEvent;
  });
  return newEvents;
}

// ============ Services that ommit redux and returns results directly =================
/**
 *  Return SOPs numbers for different data types (0 if not found)
 *
 *  In data object we should provide AssignedType, ptsParentID and ParentCode params.
 *
 *  AssignedType can be Person, Place, Address, CADType, CADSubType or Zone.
 *  If AssignedType is one of the following: Person, Place or Address we should provide ptsParentID
 *    pointing to key in ptsPerson, ptsPlace or ptsAddress.
 *  If AssignedType is CADType, CADSubType or Zone we should provide ParentCode pointing to
 *    Code from codeCADTypes table, codeCADSubTypes or codeZones.
 */
export const veryfySOP = (AssignedType, ptsParentID, ParentCode) => {
  const service = getService('cad');
  return service.get({
    type: 'verify-sops',
    data: { AssignedType, ptsParentID, ParentCode },
  });
};

export const unitInitiatedEvent = (ptsEventID, ptsUnitID, Occurred) => {
  const service = getService('cad-unit-init-event');
  return service.patch(ptsEventID, { ptsEventID, ptsUnitID, Occurred });
};

// event data needed for edit event form
export const getEventData = (ptsEventID) => {
  const service = getService('cad');
  return service.get({
    type: 'event-form-details',
    data: { ptsEventID },
  });
};

// all event data as they are in event loop - for tabs
export const getEvent = async (ptsEventID) => {
  const service = getService('cmplx-events');
  const rawData = await service.get(ptsEventID);
  const processed = processEvents([rawData]);
  return processed.length ? processed[0] : null;
};

export const getEventRouting = (ptsEventID) => {
  const service = getService('cad');
  return service.get({
    type: 'event-routing',
    data: { ptsEventID },
  });
};

export const getEventLocatins = (ptsEventID) => {
  const service = getService('cad');
  return service.get({
    type: 'event-locations',
    data: { ptsEventID },
  });
};

export const addEventRouting = (AgencyID, ptsEventID) => {
  const service = getService('cad');
  return service.create({
    type: 'create-event-routing',
    data: { AgencyID, ptsEventID },
  });
};

export const saveEventNote = (data, ptsEventID = null) => {
  const { Comment, ptsCommentID } = data;
  const service = getService('cad');
  if (ptsCommentID) {
    return service.patch(ptsCommentID, {
      type: 'update-event-note',
      data: { Comment, ptsCommentID },
    });
  }
  return service.create({
    type: 'add-event-note',
    data: { Comment, ptsEventID },
  });
};

export const addEventNote = (Comment, ptsEventID) => {
  const service = getService('cad');
  return service.create({
    type: 'add-event-note',
    data: { Comment, ptsEventID },
  });
};

export const removeEventNote = (ptsCommentID) => {
  const service = getService('cad');
  return service.patch(ptsCommentID, { type: 'remove-event-note', ptsCommentID });
};

export const saveEventLocation = (data, ptsEventID) => {
  const service = getService('cad');
  const { ptsLocationAddressID } = data;
  if (ptsLocationAddressID) {
    return service.patch(ptsEventID, {
      type: 'update-event-location',
      data,
    });
  }
  return service.create({
    type: 'add-event-location',
    data: { data, ptsEventID },
  });
};

export const saveLocation = async (location, ptsEventID, dictionary) => {
  try {
    const updatedLocation = await addCoordsToLocation(location, dictionary);
    if (!updatedLocation) return;
    updatedLocation.zones = await getZoneMatch(updatedLocation);
    return await saveEventLocation(updatedLocation, ptsEventID);
  } catch (err) {
    throw new Error('Error, problems with adding location');
  }
};

export const saveEventCaller = (data, ptsEventID) => {
  const service = getService('cad');
  const { ptsCallerID } = data;
  if (ptsCallerID) {
    return service.patch(ptsCallerID, { type: 'update-event-caller', data });
  }
  return service.create({
    type: 'add-event-caller',
    data: { data, ptsEventID },
  });
};

export const saveEventEvent = (data) => {
  const service = getService('cad');
  return service.patch(data.ptsEventID, { type: 'update-event-event', data });
};

export const removeEventCaller = (ptsCallerID) => {
  const service = getService('cad');
  return service.patch(ptsCallerID, { type: 'remove-event-caller', ptsCallerID });
};

export const removeEventLocation = (ptsLocationAddressID) => {
  const service = getService('cad');
  return service.patch(ptsLocationAddressID, {
    type: 'remove-event-location',
    ptsLocationAddressID,
  });
};

export const getPlace = (ptsPlaceID) => {
  const service = getService('cad');
  return service.get({
    type: 'get-place',
    data: { ptsPlaceID },
  });
};

export const getPlaceName = (ptsLocationID) => {
  const service = getService('cad');
  return service.get({
    type: 'get-place-name',
    data: { ptsLocationID },
  });
};

export const saveParty = (ROWGUID, data) => {
  const service = getService('cad');
  const { party } = data;
  if (ROWGUID) {
    // update data
    if (party === 'caller') {
      return service.patch(ROWGUID, { type: 'update-event-party-caller', data });
    } else if (party === 'person') {
      return service.patch(ROWGUID, { type: 'update-event-party-person', data });
    } else if (party === 'place') {
      return service.patch(ROWGUID, { type: 'update-event-party-place', data });
    }
  } else {
    // create data
    if (party === 'caller') {
      return service.create({ type: 'create-event-party-caller', data });
    } else if (party === 'person') {
      return service.create({ type: 'create-event-party-person', data });
    } else if (party === 'place') {
      return service.create({ type: 'create-event-party-place', data });
    }
  }
};

export const getPlaceDetails = (ptsPlaceID) => {
  const service = getService('cad');
  return service.get({
    type: 'party-place-details',
    data: { ptsPlaceID },
  });
};

export const findPartyPerson = (searchText) => {
  const service = getService('cad');
  return service.get({
    type: 'find-party-person',
    data: { searchText },
  });
};

export const getPersonContactInfo = (ptsPersonID) => {
  const service = getService('cad');
  return service.get({
    type: 'get-person-contact-info',
    data: { ptsPersonID },
  });
};

export const getPartyPerson = (ptsPersonID) => {
  const service = getService('cad');
  return service.get({
    type: 'get-party-person',
    data: { ptsPersonID },
  });
};

export const delPartyCaller = (ROWGUID) => {
  const service = getService('cad');
  return service.patch(ROWGUID, { type: 'remove-event-party-caller' });
};

export const delPartyPerson = (ROWGUID) => {
  const service = getService('cad');
  return service.patch(ROWGUID, { type: 'remove-event-party-person' });
};

export const delPartyPlace = (ROWGUID) => {
  const service = getService('cad');
  return service.patch(ROWGUID, { type: 'remove-event-party-place' });
};

export const getEventHistory = async (dateFrom, dateTo, filter) => {
  const service = getService();
  return service.get({
    type: 'event-history',
    data: {
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

export const getEventDetails = (ptsEventID) => {
  const service = getService('cad');
  return service.get({
    type: 'event-details',
    data: { ptsEventID },
  });
};

export const getEventLocationAddress = (ptsEventID) => {
  const service = getService('cad-event-location-address');
  return service.get(ptsEventID);
};

export const getEventLocationAddresses = (ptsEventID) => {
  const service = getService('cad-event-location-address');
  return service.find({ query: { ptsEventID } });
};

function processQueuedUnits(newEvents, dispatch) {
  const releasedUnits = getReleasedInits(newEvents);
  if (!releasedUnits.length) return;
  const state = store.store.getState();
  const dispatchStatus = state.config.options.UnitActionCodes.Dispatch.status;
  const { units, events } = state;
  releasedUnits.forEach(({ ptsUnitID, ptsEventID }) => {
    const unit = units.find((unit) => unit.ptsUnitID === ptsUnitID);
    const event = events.find((ev) => ev.ptsEventID === ptsEventID);
    if (unit && event) {
      const notificationOpts = {
        title: `Unit ${unit.Unit} is ready for dispatch.`,
        message: `${event.EventID} - ${event.CallTypeDescription} - ${event.FullLocationAddress}`,
        position: 'tr',
        autoDismiss: 0,
        action: {
          label: 'Dispatch',
          callback: () => dispatch(setUnitStatus(dispatchStatus, ptsUnitID, ptsEventID)),
        },
      };
      dispatch(notifyPanel(notificationOpts, 'info'));
    }
  });
}

/** returns queued events if they are avail to dispatch */
function getReleasedInits(newEvents) {
  const state = store.store.getState();
  const queuedStatus = state.config.options.UnitActionCodes?.Queued?.status;
  const events = state?.events || [];
  const qUnits = [];
  events.forEach((ev) =>
    ev.assignedUnits.forEach((unit) => {
      if (unit.UnitStatus === queuedStatus) qUnits.push(unit.ptsUnitID);
    })
  );

  const qEvents = {};
  events.forEach((ev) => {
    const { ptsEventID, assignedUnits } = ev;
    assignedUnits.forEach((unit) => {
      const { ptsUnitID } = unit;
      if (qUnits.indexOf(ptsUnitID) !== -1) {
        const obj = { ptsUnitID, queued: unit.UnitStatus === queuedStatus };
        if (qEvents[ptsEventID]) {
          qEvents[ptsEventID].push(obj);
        } else {
          qEvents[ptsEventID] = [obj];
        }
      }
    });
  });

  const relievedUnits = []; // [{ ptsEventID, ptsUnitID }]
  Object.entries(qEvents).forEach(([ptsEventID, units]) => {
    const event = newEvents.find((ev) => ev.ptsEventID === parseInt(ptsEventID));
    if (event) {
      const evUnits = event.assignedUnits;
      units.forEach((unit) => {
        const { ptsUnitID } = unit;
        if (!evUnits.find((u) => u.ptsUnitID === ptsUnitID)) relievedUnits.push(ptsUnitID);
      });
    } else {
      units.forEach((u) => relievedUnits.push(u.ptsUnitID));
    }
  });

  if (!relievedUnits.length) return [];
  const queuedUnits = [];
  const otherStatuses = [];
  newEvents.forEach((ev) => {
    ev.assignedUnits.forEach((unit) => {
      const { ptsUnitID } = unit;
      if (relievedUnits.indexOf(ptsUnitID) !== -1) {
        if (unit.UnitStatus === queuedStatus) {
          queuedUnits.push({ ptsEventID: ev.ptsEventID, ptsUnitID: ptsUnitID });
        } else {
          otherStatuses.push(ptsUnitID);
        }
      }
    });
  });
  return queuedUnits.filter((u) => otherStatuses.indexOf(u.ptsUnitID) === -1);
}
