import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { showReport } from 'reducers/DialogsReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchPlaces } from 'reducers/SearchReducer';
import PrintsSearch2 from '../components/PrintsSearch2';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';

import SearchHotKeys from 'Search/SearchHotKeys';
import { showAddAttachment } from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { showEditPlace } from 'reducers/DialogsReducer';
import XGrid3 from 'components/XGrid3';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'PlaceID', headerName: 'Place ID', width: 150, hide: false },
  { field: 'PlaceName', headerName: 'Place Name', width: 150, hide: false },
  { field: 'PlaceType', headerName: 'Place Type', width: 150, hide: false },
  { field: 'PlaceORI', headerName: 'Place ORI', width: 150, hide: false },
  { field: 'PlaceDepartmentID', headerName: 'Place Department ID', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 140, hide: false },
];

function Place(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tables, setTables] = useState(null);
  const [colsSeq, setColsSeq] = useState([]);
  const [placeColumns, setPlaceColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);

  useEffect(() => {
    getPlaceData();
  }, []);

  useEffect(() => {
    if (!dataUpdate || dataUpdate.type !== 'Place') return;
    getPlaceData();
    // eslint-disable-next-line
  }, [props.dataUpdate]);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        getPlaceData();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const getPlaceData = async () => {
    setLoaded(false);
    searchPlaces()
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const viewDetails = () => {
    let item = filteredRows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 30, data: { ptsPlaceID: item.ptsPlaceID }, title: 'Place Details' });
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPlaceID } = item;
    props.showAddAttachment({ id: ptsPlaceID, type: 'Place' });
  };

  const add = () => {
    props.showEditPlace(true);
  };

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPlaceID } = item;
    props.showEditPlace(ptsPlaceID);
  };

  return (
    <div>
      <h5>Places History</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={placeColumns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <PrintsSearch2
            title="Place Records"
            data={filteredRows}
            cols={placeColumns}
            kbdKey={kbdKey}
          />
          <Tooltip title="Veiw details">
            <Fab className="ml-2" size="small" color="secondary" onClick={add}>
              <AddIcon />
            </Fab>
          </Tooltip>
          {selection !== null && (
            <>
              <Tooltip title="Veiw details">
                <Fab className="ml-2" size="small" color="secondary" onClick={edit}>
                  <EditIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Veiw details">
                <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add attachment">
                <Fab className="ml-2" color="secondary" size="small" onClick={addAttachment}>
                  <AttachFileIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3 
        name="places"
        columns={placeColumns}
        setColumns={setPlaceColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, {
  handleError,
  showReport,
  showAddAttachment,
  showEditPlace,
})(Place);
