import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { handleError } from '../../reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { showDisplayDataWindow, showReport, showAddPerson } from '../../reducers/DialogsReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import { searchImages } from '../../reducers/SearchReducer';
import PrintsSearch from '../components/PrintsSearch';
import CheckIcon from '@material-ui/icons/Check';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import fallbackImage from '../../assets/images/stock-photos/fallback-image.png';
import Typography from '@material-ui/core/Typography';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import InfiniteScroll from "react-infinite-scroll-component";
import Fab from '@material-ui/core/Fab';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import SearchHotKeys from 'Search/SearchHotKeys';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    width: 300,
    height: 520,
    margin: '10px'
  },
  imageList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
  },
  selectedCard: {
    border: `3px solid ${theme.palette.primary.main}`
  }
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const imageColumns = [
  { field: 'PersonID', headerName: 'Person ID', width: 150 },
  { field: 'FullName', headerName: 'Full Name', width: 150 },
  {
    field: 'ImageDateTime',
    headerName: 'Image Date Time',
    width: 150,
    valueFormatter: (params) => displayDateTime(params.value),
    format: "date"
  },
  {
    field: 'IsDeleted',
    headerName: 'Is Deleted',
    width: 100,
    format: "bool",
    renderCell: bool
  },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: "date"
  },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 140 },
];

function Image(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);

  useEffect(() => {
    console.log('filteredrows: ', filteredRows)
  }, [filteredRows])

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  useEffect(() => {
    fetchMoreData();
  }, [pageNumber])

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        fetchMoreData();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  }

  const fetchMoreData = () => {
    setTimeout(() => {
      searchImages(pageNumber)
        .then((result) => {
          if (result.length === 0) return setHasMore(false);
          const processed = processData(result);
          const newRows = [...rows, ...processed];
          setRows(newRows);
          setPageNumber(prevPageNumber => prevPageNumber + 1);
        })
        .catch(props.handleError)
    }, 500);
  }

  const processData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: `${row.ptsImageID}${Math.random()}`,
      };
    });
  };

  const viewDetails = () => {
    props.showReport({ id: 108, data: { ptsImageID: selectedImageId }, title: 'PTS Image Print' });
  }

  const handleImageClick = (person, index) => {
    setSelectedImageId(person.ptsImageID);
    setSelectedIndex(index);
    setSelectedPerson(person.ptsPersonID);
  }

  const handleEdit = () => props.showAddPerson(selectedPerson);

  return (
    <div>
      <h5>Image History</h5>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.filterWrap}>
          <div className={classes.filterWrap}>
            <QuickFilterSearch
              rows={rows}
              columns={imageColumns}
              setFilteredRows={setFilteredRows}
            />
          </div>
          <div className={classes.actions}>
            <PrintsSearch
              title="Images Records"
              data={filteredRows}
              cols={imageColumns}
              hiddenCols={hiddenColumns}
              kbdKey={kbdKey}
            />
            {selectedIndex !== null && (
              <>
                <Tooltip title="Edit">
                  <Fab className="ml-2" size="small" color="secondary" onClick={handleEdit}>
                    <EditIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="Veiw details">
                  <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                    <InfoIcon />
                  </Fab>
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </MuiPickersUtilsProvider>
      <Grid container spacing={4} justify='space-around'>
        <InfiniteScroll
          dataLength={filteredRows.length}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>That's all the recorded images.</b>
            </p>
          }>
          <div className={classes.imageList}>
            {filteredRows.map((person, idx) => (
              <Card
                key={idx}
                className={clsx(classes.card, idx === selectedIndex ? classes.selectedCard : null)}
                onClick={() => handleImageClick(person, idx)}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="Image"
                    height="300"
                    image={person.Image ? `data:image/jpeg;base64,${person.Image}` : fallbackImage}
                    title={person.FullName}
                  />
                  <CardContent>
                    <Typography variant="body2" gutterBottom>
                      Person ID: {person.PersonID}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Full Name: {person.FullName}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Image Date Time: {displayDateTime(person.ImageDateTime)}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Is Deleted: {person.IsDeleted ?
                        <CheckIcon /> : <CheckBoxOutlineBlankIcon />}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Deleted: {displayDateTime(person.Deleted)}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Deleted By: {person.DeletedBy}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Deleted IP: {person.DeletedIP}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </div>
        </InfiniteScroll>
      </Grid>
      <SearchHotKeys
        setKey={setKbdKey}
      />
    </div>
  );
}

export default connect(null, {
  showSpinner,
  hideSpinner,
  handleError,
  showDisplayDataWindow,
  showReport,
  showAddPerson,
})(Image);
