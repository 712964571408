import React from 'react';
import History from './History';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';

function EventsSearch() {
  return (
    <SearchMenu>
      <FilterConsumer>
        {(AgencyFilter) => <History filter={AgencyFilter} />}
      </FilterConsumer>
    </SearchMenu>
  );
}

export default EventsSearch;
