import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { AppBar, Toolbar, Button, Avatar, Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Tooltip from './Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import prodMenuItems from 'config/configureMenu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MenuBulletIcon from '@material-ui/icons/FiberManualRecord';
import settings from 'config/settings';
import { addPageDialog, showReport, closeReport, showTestingDialog } from 'reducers/DialogsReducer';
import Clock from './Clock';
import { getTestingLevel } from 'utils/functions';
import UnreadBulletins from './UnreadBulletins';
import UnitsNeedAttention from './UnitsNeedAttention';
import UserMenu from './UserMenu';
import { authorizeExternalApp } from 'reducers/UserReducer';
import { getAccessPermission, getFullPermissions } from 'reducers/PermissionsReducer';

function getNcicUrl() {
  let url = process.env.REACT_APP_NCIC_URL;
  if (!url) return false;
  if (url.substr(-1) !== '/') url += '/';
  url += 'Search';
  return url;
}

const ncicUrl = getNcicUrl();

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftMenu: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '& button': {
      '& span': {
        //marginRight: 8,
      },
    },
  },
  rightMenu: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.topNav.bg,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    height: settings.mainNavHeight,
    minHeight: settings.mainNavHeight,
    backgroundColor: theme.palette.bgElements,
  },
  btn: {
    width: '85px',
    height: '60px',
    marginRight: 8,
    border: '3px solid rgba(1, 83, 163, 0.5) !important',
  },
  btnLabel: {
    position: 'absolute',
    bottom: -3,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  activeNav: {
    '& > button': {
      background: '#c9d5e0',
      fontWeight: 600,
    }
    // '& > li': {
    //   fontWeight: 600,
    // },
  },
  userName: {
    color: theme.colors.text1,
    marginLeft: 8,
  },
  arrowDn: {
    color: theme.colors.text1,
    marginLeft: 8,
  },
  fullWidthMenu: {
    zIndex: 2,
    position: 'absolute',
    top: settings.mainNavHeight,
    left: 0,
    right: 0,
    width: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  searchMenu: {
    display: 'flex',
    '& h5': {
      paddingLeft: theme.spacing(2),
    },
    '& svg': {
      marginRight: theme.spacing(1),
      fontSize: 16,
      opacity: 0.25,
    },
    '& a': {
      color: theme.colors.text1,
    },
    '& a:hover': {
      color: theme.colors.text1,
    },
  },
  fwMenuWrap: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  menuUnread: {
    width: 3800
  },
}));

const testingLevel = getTestingLevel();

const MainNav = (props) => {
  const classes = useStyles();
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);
  const [searchMenuVisible, setSearchMenuVisible] = useState(false);
  const [menuItems, setMenuItems] = useState(prodMenuItems);
  const userName = props.user?.userData?.user?.Username || '';
  const disableExexNcic = !getAccessPermission('globals', 'Execute NCIC Queries');
  const disableViewNcic = !getAccessPermission('globals', 'View NCIC Queries');
  const disableSendNotification = !getAccessPermission('globals', 'Send General Page');
  const disableNotificationSearch = !getAccessPermission('globals', 'View Paging History');
  const disableBulletinSearch = !getFullPermissions('cad', 'Alerts').Read;
  // useEffect(() => {
  //   const menu = [...menuItems];
  //   if (testingLevel > 0) {
  //     menu.splice(1, 0, { label: 'Alternate', tooltip: 'Alternate home view', link: '/alt-home', icon: 'headset' });
  //     menu.push({ label: '911', tooltip: '911', link: '/911', icon: 'map-pin' });
  //   }
  //   setMenuItems(menu);
  //   // eslint-disable-next-line
  // }, []);

  const handleUserMenuClick = (event) => {
    setUserMenuAnchor(event.currentTarget);
  };

  const closeUserMenu = () => {
    setUserMenuAnchor(null);
  };

  const handleSearchClick = () => {
    setSearchMenuVisible(!searchMenuVisible);
  };

  const handleaddPage = () => {
    props.addPageDialog();
  };

  const test = () => {
    props.showTestingDialog();
  }

  const renderDesktopMenu = () => {
    return (
      <div className={classes.wrapper}>
        <div className={classes.leftMenu}>
          {/* <div> */}
          {menuItems.map((btn, idx) => (
            <Tooltip title={btn.tooltip} key={idx}>
              <NavLink to={btn.link} activeClassName={classes.activeNav}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={clsx(classes.btn, 'p-0 s-2 d-block')}>
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon
                      icon={btn.icon}
                      className="font-size-xxl"
                    />
                  </span>
                  <span className={classes.btnLabel}>{btn.label}</span>
                </Button>
              </NavLink>
            </Tooltip>
          ))}
            {/* {menuItems.map((btn, idx) => (
              <Tooltip title={btn.tooltip} key={idx}>
                <NavLink to={btn.link} activeClassName={classes.activeNav}>
                  <Button>{btn.label}</Button>
                </NavLink>
              </Tooltip>
            ))} */}
            {/* {!disableSendNotification && <Button onClick={handleaddPage}>
              <span>Send Notification</span>
            </Button>}
            {testingLevel > 1 && <Button onClick={test}>
              <span>Test</span>
            </Button>}
            <Button onClick={handleSearchClick}>
              <span>SEARCH</span>
              <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
            </Button> */}
          {/* </div> */}
        </div>

        <div className={classes.rightMenu}>
          {/* <UnreadBulletins />
          <UnitsNeedAttention /> */}
          {/* <div className="mr-3">
            <Clock />
          </div> */}
          <div>
            <Button
              color="inherit"
              onClick={handleUserMenuClick}
              className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
              <Box className={classes.avatar}>
                <Avatar sizes="30" alt={userName} />
              </Box>
              <span className={classes.userName}>{userName}</span>
              <span className={classes.arrowDn}>
                <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  };


  const renderSearchMenu = () => {
    return (
      <div className={classes.searchMenu}>
        <div>
          <h5>Communication</h5>
          <MenuList>
            {disableBulletinSearch && <MenuItem button disabled>
              <MenuBulletIcon />  Bulletin
            </MenuItem>}
            {!disableBulletinSearch && <NavLink to="/search/bulletin" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Bulletin
              </MenuItem>
            </NavLink>}
            {disableNotificationSearch && <MenuItem button disabled>
              <MenuBulletIcon />  Notification History
            </MenuItem>}
            {!disableNotificationSearch && <NavLink to="/search/notifications" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Notification History
              </MenuItem>
            </NavLink>}
            <NavLink to="/search/sops" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> SOPs
              </MenuItem>
            </NavLink>
            <NavLink to="/search/unit-status" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Unit Status
              </MenuItem>
            </NavLink>
          </MenuList>
        </div>
        <div>
          <h5>Events</h5>
          <MenuList>
            <NavLink to="/search/advanced-events" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Advanced Events
              </MenuItem>
            </NavLink>
            <NavLink to="/search/events" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Events
              </MenuItem>
            </NavLink>
            <NavLink to="/search/notes" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Notes
              </MenuItem>
            </NavLink>
            <NavLink to="/search/rotation-history" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Rotation History
              </MenuItem>
            </NavLink>
            <MenuItem>
              <MenuBulletIcon /> Shifts
            </MenuItem>
            <NavLink to="/search/units" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Units
              </MenuItem>
            </NavLink>
          </MenuList>
        </div>
        <div>
          <h5>Parties</h5>
          <MenuList>
            <NavLink to="/search/agencies" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Agencies
              </MenuItem>
            </NavLink>
            <NavLink to="/search/credentials" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Credentials
              </MenuItem>
            </NavLink>
            <NavLink to="/search/employees" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Employees
              </MenuItem>
            </NavLink>
            <NavLink to="/search/images" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Images
              </MenuItem>
            </NavLink>
            <NavLink to="/search/names" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Names
              </MenuItem>
            </NavLink>
            <NavLink to="/search/people" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> People
              </MenuItem>
            </NavLink>
            <NavLink to="/search/phone-numbers" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Phone Numbers
              </MenuItem>
            </NavLink>
          </MenuList>
        </div>
        <div>
          <h5>Property</h5>
          <MenuList>
            <NavLink to="/search/addresses" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Addresses
              </MenuItem>
            </NavLink>
            <NavLink to="/search/advanced-addresses" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Advanced Addresses
              </MenuItem>
            </NavLink>
            <NavLink to="/search/items" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Items
              </MenuItem>
            </NavLink>
            <NavLink to="/search/places" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Places
              </MenuItem>
            </NavLink>
            <NavLink to="/search/vehicles" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Vehicles
              </MenuItem>
            </NavLink>
            <NavLink to="/search/vehicle-towings" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Vehicle Towings
              </MenuItem>
            </NavLink>
            <NavLink to="/search/zones" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Zones
              </MenuItem>
            </NavLink>
          </MenuList>
        </div>
        <div>
          <h5>State</h5>
          <MenuList>
            <MenuItem onClick={authorizeExternalApp} button component="a" href={ncicUrl} target="_ncic" disabled={disableExexNcic}>
              <MenuBulletIcon /> NCIC
            </MenuItem>
            <MenuItem>
              <MenuBulletIcon /> Password
            </MenuItem>
            {disableViewNcic && <MenuItem button disabled>
              <MenuBulletIcon /> Queries
            </MenuItem>}
            {!disableViewNcic && <NavLink to="/search/ncic-queries" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Queries
              </MenuItem>
            </NavLink>}
          </MenuList>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <AppBar color="primary" position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {renderDesktopMenu()}
          {Boolean(userMenuAnchor) && <UserMenu anchorEl={userMenuAnchor} onClose={closeUserMenu} user={props.user} />}
        </Toolbar>
      </AppBar>
      {/* {searchMenuVisible && (
        <div className={classes.fwMenuWrap} onClick={() => setSearchMenuVisible(false)}>
          <Card className={classes.fullWidthMenu}>{renderSearchMenu()}</Card>
        </div>
      )} */}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, {
    addPageDialog,
    showReport,
    closeReport,
    showTestingDialog,
  })(MainNav)
);
