import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { displayDateTime } from 'reducers/TimeReducer';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from 'components/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import CheckIcon from '@material-ui/icons/Check';
import XGrid3 from 'components/XGrid3';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import { getPeopleAssociate, createPeopleAssociate } from 'reducers/SearchReducer';
import PersonLookup from 'components/PersonLookup';
import Dictionary from 'components/Dictionary';
import Checkbox2 from 'components/Checkbox2';
import formStyles, { gridStyle, RowInner, formatSaveData } from 'utils/formStyles';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(150, 220),
  lookup: gridStyle(300, 550),
  wrap: {
    padding: theme.spacing(3),
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  lookupActions: {
    marginLeft: 8,
    '& button': {
      marginLeft: 8,
    },
    '& svg': {
      marginRight: 8
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'ptsAssociateID', headerName: 'Associate ID', width: 150, hide: false },
  { field: 'Relationship', headerName: 'Relationship', width: 150, hide: false },
  {
    field: 'IsKeepSeparate',
    headerName: 'IsKeepSeparate',
    width: 150,
    format: 'bool',
    renderCell: bool,
    hide: false,
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];

const PersonAssociate = (props) => {
  const classes = useStyles();
  const { personId } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [editing, setEditing] = useState(false);
  const [ptsAssociateID, setPtsAssociateID] = useState();
  const [associate, setAssociate] = useState(null);
  const [Relationship, setRelationship] = useState(null);
  const [IsKeepSeparate, setIsKeepSeparate] = useState(false);
  const mountedRef = useRef(true);

  useEffect(() => {
    personId && getData();
  }, []);

  const getData = async () => {
    setLoaded(false);
    try {
      const data = await getPeopleAssociate(personId);
      if (!mountedRef.current) return;
      setRows(processData(data.data));
      setLoaded(true);
      setEditing(false);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: row.ptsAssociateID,
      };
    });
  };

  const getCurrentItem = () => rows.find(a => a.ptsAssociateID === selection);

  const save = () => {
    const data = formatSaveData({
      ptsPersonID: personId,
      IsKeepSeparate,
      Relationship,
      ptsAssociateID: associate?.ptsPersonID,
    });
    props.showSpinner();
    createPeopleAssociate(data)
      .then(getData)
      .catch(props.handleError)
      .finally(props.hideSpinner)
  }

  const renderEdit = () => {
    return (
      <>
        <div>
          <RowInner>
            <PersonLookup
              className={classes.lookup}
              ptsPersonID={ptsAssociateID}
              onChange={(val) => setAssociate(val)}
            />
            <Dictionary
              options="AssociateRelationships"
              value={Relationship}
              label="Relationship"
              className={classes.item}
              onChange={(ev, val) => setRelationship(val)}
            />
            <Checkbox2
              checked={IsKeepSeparate}
              onChange={(ev, val) => setIsKeepSeparate(val)}
              label="Keep Separate"
            />
          </RowInner>
        </div>

        <div className={classes.lookupActions}>
          <Button
            color="primary"
            variant="contained"
            autoFocus
            onClick={save}
            disabled={!associate || !Relationship}
          >
            <SaveIcon /> Save
          </Button>
          <Button color="primary" onClick={() => setEditing(false)}>
            <CloseIcon /> Close
          </Button>
        </div>
      </>
    );
  }


  const renderActions = () => {
    const onAdd = () => {
      setPtsAssociateID(null);
      setAssociate(null);
      setRelationship(null);
      setIsKeepSeparate(false);
      setEditing(true);
    }

    // const onEdit = () => {
    //   console.log('edit', item)
    // }

    return (
      (
        <div className={classes.actions}>
          <Tooltip title="Add Registration">
            <Fab size="small" color="secondary" onClick={onAdd}>
              <AddIcon />
            </Fab>
          </Tooltip>
          {selection !== null && <>
            {/* <Tooltip title="Edit Registration">
              <Fab size="small" color="secondary" onClick={onEdit}>
                <EditIcon />
              </Fab>
            </Tooltip> */}
            {/* <Tooltip title="Download Credential">
              <Fab size="small" color="secondary" onClick={del}>
                <DeleteIcon />
              </Fab>
            </Tooltip> */}
          </>}
        </div>
      )
    );
  }

  return (
    <div className={classes.wrap}>
      {editing && <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          {renderEdit()}
        </div>
      </div>}
      {!editing && <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={columns}
            setFilteredRows={setFilteredRows}
            style={{ marginTop: 0, paddingBottom: 14 }}
          />
          {renderActions()}
        </div>
      </div>}
      <XGrid3
        name="PeopleAssociate"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    editPeopleEmployment: state.dialogs.editPeopleEmployment,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
  notifyDataUpdate
})(PersonAssociate);
