import React from 'react';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';
import Rotation from './Rotation';

function RotationsSearch() {

  return (
    <SearchMenu>
      <FilterConsumer>
        {(AgencyFilter) => <Rotation filter={AgencyFilter} />}
      </FilterConsumer>
    </SearchMenu>
  );
}

export default RotationsSearch;
