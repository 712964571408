import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ContainerCard from './ContainerCard';
import { veryfySOP } from 'reducers/EventsReducer';
import Fab from '@material-ui/core/Fab';
import Dictionary from 'components/Dictionary';

const useStyles = makeStyles((theme) => ({
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  textField: {
    margin: '0 4px 8px',
  },
  w180: {
    maxWidth: '100%',
    flexBasis: 180,
    minWidth: 180,
    flexGrow: 1,
  },
  w300: {
    maxWidth: '100%',
    flexBasis: 300,
    minWidth: 300,
    flexGrow: 1,
  },
  w100pr: {
    width: '100%',
  },
  actions: {
    padding: theme.spacing(1.5, 0),
    '& button': {
      marginLeft: theme.spacing(1.5),
    },
  },
}));

export default function EventForm(props) {
  const classes = useStyles();
  const { dictionary, title, idx, data, updateData, setPriority } = props;
  const { ptsEventID } = props.data;
  const [CallType, setCallType] = useState(null);
  const [CallSubType, setCallSubType] = useState(null);
  const [CallMethod, setCallMethod] = useState(null);
  const [RequestedAction, setRequestedAction] = useState(null);
  const [Description, setDescription] = useState('');
  const [validForm, setValidForm] = useState(false);
  const [sopsAvailable, setSopsAvailable] = useState(false);
  const [changed, setChanged] = useState(false);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');

  useEffect(() => {
    formatInputData();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!updateData) return;
    updateData(formatOutputData());
    if (validateForm() && !validForm) setValidForm(true);
    if (!validateForm() && validForm) setValidForm(false);
    // eslint-disable-next-line
  }, [CallType, CallSubType, CallMethod, RequestedAction, Description, lat, lng]);

  useEffect(() => {
    if (CallType) {
      veryfySOP('CADType', null, CallType.Code)
        .then((result) => {
          setSopsAvailable(result);
        })
        .catch((e) => console.log(e));
    } else {
      sopsAvailable && setSopsAvailable(false);
    }
    const priority = CallType ? CallType.Priority : null;
    setPriority && setPriority(priority);
    // eslint-disable-next-line
  }, [CallType]);

  const formatOutputData = () => {
    return {
      CallType: CallType ? CallType.Code : null,
      CallSubType: CallSubType ? CallSubType.Code : null,
      CallMethod: CallMethod ? CallMethod.Code : null,
      RequestedAction: RequestedAction ? RequestedAction.Code : null,
      Description: Description ? Description : null,
      lat: lat ? parseFloat(lat) : null,
      lng: lng ? parseFloat(lng) : null,
      ptsEventID,
    };
  };

  const formatInputData = () => {
    const { lat, lng } = data;
    const CallTypeObj = dictionary.CallTypes.find(c => c.Code === data.CallType);
    !CallType && setCallType(CallTypeObj ? CallTypeObj : null);
    !CallSubType && setCallSubType(getDataObj('CallSubTypes', data.CallSubType));
    !CallMethod && setCallMethod(getDataObj('CallMethods', data.CallMethod));
    !RequestedAction && setRequestedAction(getDataObj('RequestedActions', data.RequestedAction));
    !Description && setDescription(data.Description ? data.Description : '');
    setLat(lat ? String(lat) : '');
    setLng(lng ? String(lng) : '');
  };

  const getDataObj = (type, Code) => {
    const obj = dictionary[type].find((obj) => obj.Code === Code);
    return obj ? obj : null;
  };

  const clear = (ev) => {
    ev.stopPropagation();
    props.clear(idx);
    setChanged(false);
  };

  const save = (ev) => {
    ev.stopPropagation();
    props.save();
    setChanged(false);
  };

  const onLocationChange = (ev) => {
    const value = ev.target.value;
    if (value === '' || value.length < 2000) setDescription(value);
    !changed && setChanged(true);
  };

  const onCallTypeChange = (ev, val) => {
    setCallType(val);
    !changed && setChanged(true);
  };

  const onCallSubTypeChange = (ev, val) => {
    setCallSubType(val);
    !changed && setChanged(true);
  };

  const onCallMethodChange = (ev, val) => {
    setCallMethod(val);
    !changed && setChanged(true);
  };

  const onReqActionChange = (ev, val) => {
    setRequestedAction(val);
    !changed && setChanged(true);
  };

  const isLatValid = () => {
    if (lat === '') return true;
    const val = parseFloat(lat);
    return val > -85.05112878 && val < 85.05112878;
  };

  const isLngValid = () => {
    if (lng === '') return true;
    const val = parseFloat(lng);
    return val > -180 && val < 180;
  };

  const onLatChange = (ev) => {
    !changed && setChanged(true);
    setLat(ev.target.value);
  };

  const onLngChange = (ev) => {
    !changed && setChanged(true);
    setLng(ev.target.value);
  };

  const validateForm = () => {
    if (!CallType || !isLatValid() || !isLngValid()) return false;
    return true;
  };

  const renderActions = () => {
    const closePresent = !!props.clear;
    const savePresent = !!props.save;
    const disabled = !validForm || !changed;
    return (
      <div className={classes.actions}>
        {savePresent && (
          <Fab color="secondary" aria-label="save" onClick={save} disabled={disabled} size="small">
            <SaveIcon />
          </Fab>
        )}
        {closePresent && (
          <Fab color="secondary" aria-label="close" onClick={clear} size="small">
            <CloseIcon />
          </Fab>
        )}
      </div>
    );
  };

  return (
    <>
      <ContainerCard title="Location">
        <div className={classes.form}>
          <TextField
            className={clsx(classes.textField, classes.w100pr)}
            label="Location Description**"
            variant="outlined"
            value={Description}
            onChange={onLocationChange}
            size="small"
          />
        </div>
      </ContainerCard>
      <ContainerCard
        title={title}
        actions={renderActions()}
        info={sopsAvailable ? { text: 'Selections have attached SOPs', type: 'error' } : null}>
        <div className={classes.form}>
          <Dictionary
            className={classes.w180}
            options="CallTypes"
            value={CallType}
            onChange={onCallTypeChange}
            label="Event Type*"
            compact
          />
          <Dictionary
            className={classes.w180}
            options="CallSubTypes"
            value={CallSubType}
            onChange={onCallSubTypeChange}
            label="Event Subtype"
            compact
          />
          <Dictionary
            className={classes.w180}
            options="CallMethods"
            value={CallMethod}
            onChange={onCallMethodChange}
            label="Call Method"
            compact
          />
        </div>
        <div className={classes.form}>
          <Dictionary
            className={classes.w180}
            options="RequestedActions"
            value={RequestedAction}
            onChange={onReqActionChange}
            label="Requested Action"
            compact
          />
          <TextField
            className={clsx(classes.textField, classes.w180)}
            label="Latitude"
            variant="outlined"
            value={lat}
            onChange={onLatChange}
            size="small"
            type="number"
            error={!isLatValid()}
          />
          <TextField
            className={clsx(classes.textField, classes.w180)}
            label="Longitude"
            variant="outlined"
            value={lng}
            onChange={onLngChange}
            size="small"
            error={!isLngValid()}
          />
        </div>
      </ContainerCard>
    </>
  );
}
