import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { searchNotifications } from 'reducers/SearchReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';

import SearchDatePicker from '../components/SearchDatePicker';
import CheckIcon from '@material-ui/icons/Check';
import { showDisplayDataWindow } from 'reducers/DialogsReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import SearchHotKeys from 'Search/SearchHotKeys';
import XGrid3 from 'components/XGrid3';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const today = new Date();
const startDate = new Date();
startDate.setDate(startDate.getDate() - 500);

const defaultColumns = [
  { field: 'MessageFrom', headerName: 'Message From', width: 150, hide: false },
  { field: 'Subject', headerName: 'Subject', width: 150, hide: false },
  { field: 'Message', headerName: 'Message', width: 150, hide: false },
  { field: 'Recipient', headerName: 'Recipient', width: 150, hide: false },
  { field: 'Label', headerName: 'Label', width: 150, hide: false },
  { field: 'CaseID', headerName: 'Case ID', width: 150, hide: false },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150, hide: false },
  { field: 'EventIsDeleted', headerName: 'Event Is Deleted', width: 150, hide: false },
  { field: 'Info', headerName: 'Info', width: 150, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 170,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'CreatedBy', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 170,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150, hide: false },
  { field: 'IsDeleted', headerName: 'Is Deleted', width: 150, renderCell: bool, hide: false },
  {
    field: 'Deleted',
    headerName: 'Deleted',
    width: 170,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  { field: 'DeletedBy', headerName: 'DeletedBy', width: 150, hide: false },
  { field: 'DeletedIP', headerName: 'Deleted IP', width: 150, hide: false },
];

function AlertsSearchResults(props) {
  const classes = useStyles();
  const { filter } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [dateFrom, setDateFrom] = useState(startDate);
  const [dateTo, setDateTo] = useState(today);
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [kbdKey, setKbdKey] = useState(null);
  const kbdTimeoutRef = useRef(0);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (kbdKey) handleKeyPress();
    kbdTimeoutRef.current = setTimeout(() => {
      setKbdKey(null);
    }, 500);
  }, [kbdKey]);

  const handleKeyPress = () => {
    switch (kbdKey) {
      case 'F5':
        search();
        break;
      case 'Ctrl+KeyP':
        viewDetails();
        break;
    }
  };

  const processData = (data) => {
    return data.map((row, id) => {
      return { ...row, id };
    });
  };

  const search = () => {
    setLoaded(false);
    searchNotifications(dateFrom, dateTo, filter)
      .then((result) => setRows(processData(result)))
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const viewDetails = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showDisplayDataWindow({
      data: filterData(item),
      title: `Notification Details - ${item.Subject}`,
    });
  };

  const filterData = (rawData) => {
    const {
      MessageFrom,
      Subject,
      Message,
      Recipient,
      Label,
      EventID,
      CaseID,
      AgencyID,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    } = rawData;
    return {
      MessageFrom,
      Subject,
      Message,
      Recipient,
      Label,
      EventID,
      CaseID,
      AgencyID,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    };
  };

  return (
    <div>
      <h5>Notifications History</h5>
      <QuickFilterSearch rows={rows} setFilteredRows={setFilteredRows} columns={columns} />
      <SearchDatePicker
        search={search}
        viewDetails={typeof selection === 'number' ? viewDetails : null}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        printTitle="Notifications History"
        filteredRows={filteredRows}
        columns={columns}
        kbdKey={kbdKey}
      />
      <XGrid3 
        name="notificationHistory"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
      <SearchHotKeys setKey={setKbdKey} />
    </div>
  );
}

export default connect(null, {
  handleError,
  showDisplayDataWindow,
})(AlertsSearchResults);
