import { getService } from './service';
import { formatSaveDateFrom, formatSaveDateTo } from './TimeReducer';
import { addCreatedBy, addCreatedUpdatedBy, addUpdatedBy } from 'utils/functions';

export const searchNotifications = async (dateFrom, dateTo, filter) => {
  const service = getService('cad-search-notifications');
  return service.find({
    query: {
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

export const searchPlaceAddresses = async (ptsPlaceID) => {
  const service = getService('cad-search-place-address');
  const result = await service.find({
    query: { ptsPlaceID, IsDeleted: 0 },
  });

  if (!result?.data?.length) return [];
  return result.data.map(r => r.Address);
};

export const searchUnitStatus = (dateFrom, dateTo, filter) => {
  const service = getService('cad-search-unit-status');
  return service.find({
    query: {
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

export const searchAdvancedEvents = (dateFrom, dateTo, filter) => {
  const service = getService('cad-search-advanced-events');
  return service.find({
    query: {
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

export const searchEvents = (dateFrom, dateTo, filter) => {
  const service = getService('cad-search-events');
  return service.find({
    query: {
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

export const searchNotes = (dateFrom, dateTo, filter) => {
  const service = getService('cad-search-notes');
  return service.find({
    query: {
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

export const searchUnits = () => {
  const service = getService('cad-search-units');
  return service.find();
};

export const addUnit = (data) => {
  const service = getService('cad-search-units');
  return service.create(data);
};

export const editUnit = (ptsUnitID, data) => {
  const service = getService('cad-search-units');
  return service.patch(ptsUnitID, data);
};

export const searchAgencies = () => {
  const service = getService('cad-search-agencies');
  return service.find();
};

export const searchEmployees = () => {
  const service = getService('cad-search-employees');
  return service.find();
};

export const searchNames = () => {
  const service = getService('cad-search-names');
  return service.find();
};

export const getName = (ptsNameID) => {
  const service = getService('cad-search-names');
  return service.get(ptsNameID);
};

export const updateName = (ptsNameID, data) => {
  const service = getService('cad-search-names');
  return service.patch(ptsNameID, data);
};

export const searchCredentials = () => {
  const service = getService('cad-search-credentials');
  return service.find();
};

export const searchImages = (pageNumber) => {
  const service = getService('cad-search-images');
  return service.find({
    query: {
      pageNumber,
    },
  });
};

export const searchSops = () => {
  const service = getService('cad-search-sops');
  return service.find();
};

export const searchPhoneNumbers = () => {
  const service = getService('cad-search-phone-numbers');
  return service.find();
};

export const searchPeople = () => {
  const service = getService('cad-search-people');
  return service.find();
};

export const searchAddresses = () => {
  const service = getService('cad-search-addresses');
  return service.find();
};

export const searchAddress = (ptsAddressID) => {
  const service = getService('cad-search-addresses');
  return service.get(ptsAddressID);
};

export const searchAdvancedAddresses = () => {
  const service = getService('cad-search-advanced-addresses');
  return service.find();
};

export const searchItems = () => {
  const service = getService('cad-search-items');
  return service.find();
};

export const searchPlaces = () => {
  const service = getService('cad-search-places');
  return service.find();
};

export const searchVehicles = () => {
  const service = getService('cad-search-vehicles');
  return service.find();
};

export const searchSpecificVehicle = (ptsVehicleID) => {
  const service = getService('cad-pts-vehicle');
  return service.get(ptsVehicleID);
};

export const editVehicle = (ptsVehicleID, data) => {
  const service = getService('cad-pts-vehicle');
  return service.patch(ptsVehicleID, data);
};

export const addVehicle = (data) => {
  const service = getService('cad-search-vehicles');
  return service.create(data);
};

export const removeVehicle = (data) => {
  const service = getService('cad-search-vehicles');
  return service.remove(data);
};

export const searchVehicleRegistrations = (ptsVehicleID) => {
  const service = getService('cad-pts-vehicle-reg');
  return service.find({
    query: { ptsVehicleID },
  });
};

export const addVehicleReg = (data) => {
  const service = getService('cad-pts-vehicle-reg');
  return service.create(data);
};

export const updateVehicleReg = (id, data) => {
  const service = getService('cad-pts-vehicle-reg');
  return service.patch(id, data);
};

export const searchVehicleTowings = () => {
  const service = getService('cad-search-vehicle-towings');
  return service.find();
};

export const searchZones = () => {
  const service = getService('cad-search-zones');
  return service.find();
};

export const getPtsPerson = (ptsPersonID) => {
  const service = getService('cad-search-people');
  return service.get(ptsPersonID);
};

export const getPtsPlace = (ptsPlaceID) => {
  const service = getService('places');
  return service.get(ptsPlaceID);
};

export const updatePtsPlace = (ptsPlaceID, data) => {
  const service = getService('cad-search-places');
  return service.patch(ptsPlaceID, data);
};

export const addPlace = (data) => {
  const service = getService('cad-search-places');
  return service.create(data);
};

export const searchRotations = (dateFrom, dateTo, filter) => {
  const service = getService('cad-search-rotation-history');
  return service.find({
    query: {
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

export const searchQueries = (dateFrom, dateTo, filter) => {
  const service = getService('cad-search-ncic-queries');
  return service.find({
    query: {
      dateFrom: formatSaveDateFrom(dateFrom),
      dateTo: formatSaveDateTo(dateTo),
      filter,
    },
  });
};

export const removeUnitStatus = (ptsActionID) => {
  const service = getService('cad-search-unit-status');
  return service.remove(ptsActionID);
};

export const editCredential = (ptsCredentialID, data) => {
  const service = getService('cad-search-credentials');
  return service.patch(ptsCredentialID, data);
};

export const addOrEditPerson = (ptsPersonID, data) => {
  const service = getService('cad-search-people');
  return ptsPersonID && ptsPersonID !== true ? service.patch(ptsPersonID, data) : service.create(data);
};

export const getContactMethods = async (id, PartyType = 'Person') => {
  const service = getService('record-person-contact');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsParentID: id,
    PartyType: PartyType,
    Info: {
      $ne: '',
    },
  };

  const result = await service.find({
    query: q,
  });
  // console.log('result', result);
  return result;
};
export const getPeopleCredentials = async (personId) => {
  const service = getService('record-person-credentials');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPersonID: personId,
    Number: {
      $ne: '',
    },
  };

  const result = await service.find({
    query: q,
  });
  // console.log('result', result);
  return result;
};
export const getPeopleEmployments = async (personId) => {
  const service = getService('record-person-employment');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPersonID: personId,
  };

  const result = await service.find({
    query: q,
  });

  return result;
};

export const getPeopleAssociate = async (personId) => {
  const service = getService('record-person-associates');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPersonID: personId,
  };

  const result = await service.find({
    query: q,
  });
  // console.log('emp result', result);
  return result;
};

export const createPeopleAssociate = async (data) => {
  const saveData = addCreatedUpdatedBy(data);
  const service = getService('record-person-associates');
  await service.create(saveData);
};

export const getPeopleFeatures = async (personId) => {
  const service = getService('record-person-feature');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPersonID: personId,
  };

  const result = await service.find({
    query: q,
  });
  // console.log('emp result', result);
  return result;
};

export const getPeopleNames = async (personId) => {
  const service = getService('record-person-name');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPersonID: personId,
  };

  const result = await service.find({
    query: q,
  });
  // console.log('emp result', result);
  return result;
};

export const getPeopleVehicle = async (personId) => {
  const service = getService('record-person-vehicle-registration');
  let query = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsParentID: personId,
  };

  const result = await service.find({ query });
  return result;
};

export const getPeopleAddresses = async (ptsPersonID) => {
  const service = getService('record-person-address');
  const query = {
    ptsPersonID,
  };
  return service.find({ query });
};
// export const getPlaceAddresses = async (ptsPlaceID) => {
//   const service = getService('place-addresses');
//   const query = {
//     ptsPlaceID,
//   };
//   return service.find({ query });
// };

export const savePersonAddress = async (data) => {
  const service = getService('record-person-address');
  return service.create(data);
};

export const getplaceAddress = async (ptsPlaceID) => {
  const service = getService('cad-search-place-address');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPlaceID: ptsPlaceID,
  };

  const result = await service.find({
    query: q,
  });
  return result;
};

export const getPeopleSops = async (personId, ParentType = 'Person') => {
  const service = getService('record-person-sops');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsParentID: personId,
    ParentType: ParentType,
  };

  const result = await service.find({
    query: q,
  });
  //console.log('sop result', result);
  return result;
};

export const savePlaceAddress = async (data) => {
  const service = getService('cad-search-place-address');
  return service.create(data);
};

export const addPersonEmployment = (data) => {
  const service = getService('cad-search-people-employment');
  return service.create(data);
};

export const getUnitsResources = async () => {
  const service = getService('settings-resources');
  const result = await service.get({ type: 'units-resources' });
  return result;
}

export const saveUnitPerson = (data) => {
  const service = getService('settings-resources');
  return service.patch(data.ptsUnitID, { type: 'unit-person', data });
}

export const removeUnitResource = (id, ChildType, ptsParentID) => {
  const data = { id, ChildType, ptsParentID };
  const service = getService('settings-resources');
  return service.patch(id, { type: 'remove-unit-resource', data });
}

export const saveUnitVehicle = (data) => {
  const service = getService('settings-resources');
  return service.patch(data.ptsUnitID, { type: 'unit-vehicle', data });
}

