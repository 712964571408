import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Autocomplete2 from 'components/Autocomplete2';
import { showEditDictionary } from 'reducers/DialogsReducer';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

function Dictionary(props) {
  const { dictionary, options, label, value } = props;
  const dictOptions = dictionary[options];
  const permissions = props.permissions[options];
  const showEdit = permissions?.Create || permissions?.Edit || permissions?.Delete;
  const valueValid = value === null || typeof value === 'object';

  useEffect(() => {
    if (valueValid) return;
    setProperValueObject();
    // eslint-disable-next-line
  }, [valueValid]);

  const setProperValueObject = () => {
    let newVal;
    switch (options) {
      case 'Cities':
        newVal = dictOptions.find((d) => d.ptsCityID === value);
        break;
      case 'ESN':
        newVal = dictOptions.find((d) => d.ESN === value);
        break;
      case 'Agencies': 
        newVal = dictOptions.find((d) => d.AgencyID === value);
        break; 
      default:
        newVal = dictOptions.find((d) => d.Code === value);
    }

    if (newVal) props.onChange(null, newVal);
  };

  if (!dictOptions) {
    return <div style={{ color: 'red' }}>Invalid data</div>;
  }
  let extraOpts = {};
  switch (options) {
    case 'Cities':
      extraOpts = {
        getOptionSelected: (option, value) => option.ptsCityID === value.ptsCityID,
        getOptionLabel: (option) => (option.ptsCityID ? option.Code : ''),
        renderOption: (option) => <span>{option.Code}</span>,
      };
      break;
    case 'Agencies':
      extraOpts = {
        getOptionSelected: (option, value) => option.AgencyID === value.AgencyID,
        getOptionLabel: (option) => (option.AgencyID ? option.AgencyID : ''),
        renderOption: (option) => <span>{option.AgencyID} - {option.Description}</span>,
      };
      break;
    case 'ESN':
      extraOpts = {
        getOptionSelected: (option, value) => option.ESN === value.ESN,
        getOptionLabel: (option) => (option.ESN ? option.ESN : ''),
        renderOption: (option) => (
          <span>
            {option.ESN} - {option.Description}
          </span>
        ),
        filterOptions: createFilterOptions({
          stringify: (option) => option.ESN + option.Description,
        })
      };
      break;
    default:
  }

  const autocompleteOpts = { ...props, ...extraOpts };
  if (!valueValid) autocompleteOpts.value = null;
  return (
    <Autocomplete2
      {...autocompleteOpts}
      options={dictOptions}
      onAdd={showEdit ? () => props.showEditDictionary({ name: options, title: label }) : undefined}
    />
  );
}

const mapStateToProps = (state) => ({
  dictionary: state.dictionary,
  permissions: state.permissions.dictionary,
});

export default connect(mapStateToProps, {
  showEditDictionary,
})(Dictionary);
