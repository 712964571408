import React from 'react';
import Note from './Note';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';

function NotesSearch() {
  return (
    <SearchMenu>
      <FilterConsumer>
        {(AgencyFilter) => <Note filter={AgencyFilter} />}
      </FilterConsumer>
    </SearchMenu>
  );
}

export default NotesSearch;
