import React from 'react';
import { connect } from 'react-redux';
import { Button, Avatar, Box, Menu, List, ListItem, Divider, Badge } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { setMode } from 'reducers/ThemeReducer';
import { setUserAuthenticated } from 'reducers/UserReducer';
import { openSettings, showAboutDialog } from 'reducers/DialogsReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { authorizeExternalApp } from 'reducers/UserReducer';

// function getSettingsUrl() {
//   let url = process.env.REACT_APP_SETTINGS_URL;
//   if (!url) return false;
//   if (url.substr(-1) !== '/') url += '/';
//   url += 'resources';
//   return url;
// }

// const settingsUrl = getSettingsUrl();

const UserBadge = withStyles({
  badge: {
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

function UserMenu(props) {
  const { anchorEl, onClose, network, themeMode, setUserAuthenticated, user } = props;
  const userName = user?.userData?.user?.Username || '';

  const StyledBadge = withStyles({
    badge: {
      backgroundColor: network.online === false ? 'var(--danger)' : 'var(--success)',
      color: network.online === false ? 'var(--danger)' : 'var(--success)',
    },
  })(UserBadge);

  const toggleColorMode = () => {
    props.setMode();
    onClose();
  };

  const showAboutDialog = () => {    
    props.showAboutDialog();
    onClose();
  }

  const handleOpenOptions = () => {
    props.openSettings();
    onClose();
  }

  const handleOpenSettings = () => {
    authorizeExternalApp();
    onClose();
  }

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      getContentAnchorEl={null}
      open={Boolean(anchorEl)}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      transformOrigin={{ vertical: 'center', horizontal: 'center' }}
      onClose={onClose}
      className="ml-2">
      <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
        <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
          <Box>
            <StyledBadge
              overlap="circle"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot">
              <Avatar sizes="44" alt={userName} />
            </StyledBadge>
          </Box>
          <div>
            <div className="font-weight-bold text-center pt-2 line-height-1">{userName}</div>
          </div>
          <Divider className="w-100 mt-2" />
          <ListItem onClick={toggleColorMode} button>
            {themeMode === 'day' ? 'Night mode' : 'Day mode'}
          </ListItem>
          <ListItem onClick={showAboutDialog} button>
            About
          </ListItem>
          <ListItem onClick={handleOpenOptions} button>
            Options
          </ListItem>
          {/* <ListItem onClick={handleOpenSettings} button component="a" href={settingsUrl} target="_settings" disabled={!settingsUrl}>
            Settings
          </ListItem> */}
          <Divider className="w-100" />
          <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
            <Button
              onClick={() => setUserAuthenticated(false)}
              variant="outlined"
              size="medium"
              color="default">
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
              </span>
              <span className="btn-wrapper--label">Logout</span>
            </Button>
          </ListItem>
        </List>
      </div>
    </Menu>
  );
}

const mapStateToProps = (state) => ({
  network: state.offline,
  themeMode: state.theme.mode,
});

export default connect(mapStateToProps, {
  setMode,
  setUserAuthenticated,
  showAboutDialog,
  openSettings,
})(UserMenu);