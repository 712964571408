import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { OverlayView } from '@react-google-maps/api';
import { setUnitInfowindow } from 'reducers/MapReducer';
import UnitMarker from './UnitMarker';
import UnitTooltip from 'components/UnitTooltip';
import { getUnitCoords } from 'utils/mapFunctions';

const positionOverlayView = (width, height) => ({ x: -width / 2, y: -height });

const MarkersUnits = (props) => {
  const { colors, resources, onUnitClick } = props;
  const [units, setUnits] = useState([]);

  useEffect(() => {
    if (!props.units) return;
    setUnits(props.units.filter(unit => unit.UnitLatitudeDegree || unit.UnitLongitudeDegree));
    // eslint-disable-next-line
  }, [props.units]);

  const renderMarker = (unit) => {
    const { ptsUnitID } = unit;
    let Resources = [];
    if (resources && resources[ptsUnitID]) Resources = resources[ptsUnitID];
    if (unit.UnitLatitudeDegree !== null && unit.UnitLongitudeDegree !== null) {
      const position = getUnitCoords(unit);
      return (
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          key={unit.ptsUnitID}
          position={position}
          getPixelPositionOffset={positionOverlayView}
        >
          <UnitTooltip unit={unit} Resources={Resources}>
            <UnitMarker colors={colors} unit={unit} onUnitClick={onUnitClick} />
          </UnitTooltip>
        </OverlayView>
      );
    } else {
      return null;
    }
  };

  if (units.length > 0) {
    return units.map((unit) => renderMarker(unit));
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  units: state.units,
  colors: state.config.colorPalette.Units,
  resources: state.unitResources,
});

export default connect(mapStateToProps, { setUnitInfowindow })(MarkersUnits);
