import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeEditName } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import formStyles, { useError, gridStyle, Row, RowInner, ColCard, formatSaveData } from 'utils/formStyles';
import Dictionary from 'components/Dictionary';
import TextField2 from 'components/TextField2';
import { getName, updateName } from 'reducers/SearchReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(150, 400),
  wrap: {
    padding: theme.spacing(3),
  },
}));

function EditNameDialog(props) {
  const classes = useStyles();
  const { ptsNameID } = props;
  const [FirstName, setFirstName] = useState('');
  const [MiddleName, setMiddleName] = useState('');
  const [LastName, setLastName] = useState('');
  const [NickName, setNickName] = useState('');
  const [Notes, setNotes] = useState('');
  const [Prefix, setPrefix] = useState(null);
  const [Suffix, setSuffix] = useState(null);
  const [NameType, setNameType] = useState(null);
  const [errors, setErrors] = useState();
  const { err, isValid } = useError(errors, setErrors, true);

  useEffect(() => {
    getNameDetails();
  }, [ptsNameID]);

  const getNameDetails = () => {
    props.showSpinner();
    getName(ptsNameID)
      .then(processData)
      .catch(props.handleError)
      .finally(props.hideSpinner);
  }

  const processData = (data) => {
    const { NameType, Prefix, FirstName, MiddleName, LastName, Suffix, NickName, Notes } = data;
    setNameType(NameType);
    setPrefix(Prefix);
    setFirstName(FirstName);
    setMiddleName(MiddleName);
    setLastName(LastName);
    setSuffix(Suffix);
    setNickName(NickName);
    setNotes(Notes);
  }

  const close = () => props.closeEditName();

  const save = () => {
    const data = formatSaveData({
      FirstName, MiddleName, LastName, NickName, Notes, Prefix, Suffix, NameType
    });
    props.showSpinner();
    updateName(ptsNameID, data)
      .then(() => {
        props.notifyDataUpdate({ type: 'Name', data: 'update' });
        close();
      })
      .catch(props.handleError)
      .finally(props.hideSpinner);
  };

  const getFullName = () => {
    let fullName = '';
    if (Prefix?.Code) fullName = Prefix?.Code + ' ';
    if (FirstName) fullName += FirstName + ' ';
    if (MiddleName) fullName += MiddleName + ' ';
    if (LastName) fullName += LastName + ' ';
    if (Suffix?.Code) fullName += Suffix?.Code;
    return fullName;
  }

  const renderNameType = () => {
    const onChange = (ev, val) => setNameType(val);
    const error = err(NameType, 'NameType');
    return (
      <Dictionary
        options="NameTypes"
        className={classes.item}
        onChange={onChange}
        value={NameType}
        label="Name Type"
        error={error}
        compact
      />
    );
  }

  const renderFirstName = () => {
    const onChange = (ev, val) => setFirstName(val);
    return (
      <TextField2
        className={classes.item}
        label="First Name"
        value={FirstName}
        onChange={onChange}
        max={100}
        compact
      />
    );
  }

  const renderMiddleName = () => {
    const onChange = (ev, val) => setMiddleName(val);
    return (
      <TextField2
        className={classes.item}
        label="Middle Name"
        value={MiddleName}
        onChange={onChange}
        max={50}
        compact
      />
    );
  }

  const renderLastName = () => {
    const onChange = (ev, val) => setLastName(val);
    const error = err(LastName, 'LastName');
    return (
      <TextField2
        className={classes.item}
        label="Last Name"
        value={LastName}
        onChange={onChange}
        max={100}
        error={error}
        compact
      />
    );
  }

  const renderPrefix = () => {
    const onChange = (ev, val) => setPrefix(val);
    return (
      <Dictionary
        options="NamePrefixes"
        className={classes.item}
        onChange={onChange}
        value={Prefix}
        label="Prefix"
        compact
      />
    );
  }

  const renderSuffix = () => {
    const onChange = (ev, val) => setSuffix(val);
    return (
      <Dictionary
        options="NameSuffixes"
        className={classes.item}
        onChange={onChange}
        value={Suffix}
        label="Suffix"
        compact
      />
    );
  }

  const renderNickName = () => {
    const onChange = (ev, val) => setNickName(val);
    return (
      <TextField2
        className={classes.item}
        label="NickName"
        value={NickName}
        onChange={onChange}
        max={20}
        compact
      />
    );
  }

  const renderNotes = () => {
    const onChange = (ev, val) => setNotes(val);
    return (
      <TextField2
        className={classes.w100pr}
        label="Notes"
        value={Notes}
        onChange={onChange}
        max={2000}
        multiline
        rows={3}
      />
    );
  }

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!isValid()}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Name" actions={renderActions()}>
      <h5>{getFullName()}</h5>
      <Row>
        <ColCard minWidth={500} maxWidth={960}>
          <Row style={{ display: 'flex' }}>
            <div className={classes.col1}>
              <RowInner>
                {renderNameType()}
                {renderPrefix()}
                {renderFirstName()}
              </RowInner>
              <RowInner>
                {renderMiddleName()}
                {renderLastName()}
                {renderSuffix()}
              </RowInner>
              <RowInner>
                {renderNickName()}
                <div className={classes.item} />
                <div className={classes.item} />
              </RowInner>
              <RowInner>
                {renderNotes()}
              </RowInner>
            </div>
          </Row>
        </ColCard>
      </Row>
    </Dialog>
  );
}

export default connect(null, {
  closeEditName,
  getName,
  handleError,
  showSpinner,
  hideSpinner,
  notifyDataUpdate,
})(EditNameDialog);
