import React from 'react';
import HtmlTooltip from 'components/HtmlTooltip';
import { displayDateTime } from 'reducers/TimeReducer';

const tooltipLabels = {
  Item: 'Item',
  UnitCapability: 'Unit Capability',
  VehicleCapability: 'Vehicle Roles',
  PersonalCapability: 'Personal Skill',
  ItemCapability: 'Item Capability',
}

function UnitTooltip(props) {

  const { Resources, unit, children } = props;
  const { Unit, UnitLatitudeSign, UnitLatitudeDegree, UnitLongitudeSign, UnitLongitudeDegree, CoordsUpdated } = unit;
  const lat = parseFloat(UnitLatitudeSign + UnitLatitudeDegree).toFixed(5);
  const lng = parseFloat(UnitLongitudeSign + UnitLongitudeDegree).toFixed(5);
  const CoordsEnabled = UnitLatitudeDegree || UnitLongitudeDegree;

  const vehicles = Resources.filter(r => r.ChildType === 'Vehicle');
  const personnel = Resources.filter(r => r.ChildType === 'Person');
  const other = Resources.filter(r => r.ChildType !== 'Person' && r.ChildType !== 'Vehicle');
  const tooltip = (
    <div>
      <h4>{Unit}</h4>
      <table>
        <tbody>
          {Boolean(CoordsEnabled) && <tr><td colSpan={2} style={{ textAlign: 'center' }}>{lat}, {lng}</td></tr>}
          {Boolean(CoordsUpdated && CoordsEnabled) && <tr><td colSpan={2} style={{ textAlign: 'center' }}>{displayDateTime(CoordsUpdated)}</td></tr>}
          {vehicles.length > 0 && <tr>
            <th>Vehicles</th>
            <td>{vehicles.map((v, idx) => <span key={v.ptsResourceID}>{idx !== 0 && <br />}{v.ComponentName}</span>)}</td>
          </tr>}
          <tr>
            <th>Personnel</th>
            <td>{personnel.map((v, idx) => <span key={v.ptsResourceID}>{idx !== 0 && <br />}{v.ComponentName}</span>)}</td>
          </tr>
        </tbody>
      </table>
      {other.length > 0 && (
        <>
          <h4>Other Resources</h4>
          <table>
            <tbody>
              {other.map((v, idx) => <tr key={v.ptsResourceID}>
                <th>{tooltipLabels[v.ChildType]}</th>
                <td>{v.ComponentName}</td>
              </tr>)}
            </tbody>
          </table>
        </>
      )}
    </div >
  );

  return (
    <HtmlTooltip title={tooltip}>
      <div>{children}</div>
    </HtmlTooltip>
  );
}

export default UnitTooltip;